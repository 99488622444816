{
  "stages": [
    {
      "name": "new",
      "type": "",
      "hidden": false,
      "sequence": 0,
      "label": "New",
      "id": 1,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "name": "work-order-list",
                  "type": "work-order-list-component",
                  "items": [
                    {
                      "name": "workOrders",
                      "type": "lib-Form",
                      "label": "Work Orders",
                      "items": [
                        {
                          "name": "workOrderNumber",
                          "label": "WO number",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 1
                        },
                        {
                          "name": "createdDate",
                          "label": "Created On",
                          "type": "label",
                          "valueType": "date",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 2
                        },
                        {
                          "name": "bookingNumber",
                          "label": "Booking Number",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 3
                        },
                        {
                          "name": "workOrderStatusCode",
                          "label": "WO Status",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 4
                        },
                        {
                          "name": "workOrderCostUSD",
                          "label": "WO Cost",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 5
                        },
                        {
                          "name": "woMode",
                          "label": "Mode",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 6
                        },
                        {
                          "name": "shopCode",
                          "label": "Shop Code",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 7
                        },
                        {
                          "name": "shopCountryName",
                          "label": "Shop Country",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "hidden": false,
                          "sequence": 8
                        }
                      ]
                    },
                    {
                      "name": "workOrderLineItems",
                      "type": "grid",
                      "label": "Work order line items",
                      "items": [
                        {
                          "name": "isRecoverable",
                          "label": "Recoverable",
                          "valueType": "string",
                          "sequence": 0
                        },
                        {
                          "name": "tpCode",
                          "label": "TPI Code",
                          "valueType": "string",
                          "sequence": 1
                        },
                        {
                          "name": "quantityRepair",
                          "label": "Pieces",
                          "valueType": "string",
                          "sequence": 2
                        },
                        {
                          "name": "repairDescription",
                          "label": "Repair Description",
                          "valueType": "string",
                          "sequence": 3
                        },
                        {
                          "name": "repairLocationCode",
                          "label": "Repair Location",
                          "valueType": "string",
                          "sequence": 4
                        },
                        {
                          "name": "manHourRateUSD",
                          "label": "Labor Rate",
                          "valueType": "string",
                          "sequence": 5
                        },
                        {
                          "name": "actualManHours",
                          "label": "Hrs",
                          "valueType": "string",
                          "sequence": 6
                        },
                        {
                          "name": "repairMaterialCostLocalCurrency",
                          "label": "Material Cost (local)",
                          "valueType": "string",
                          "sequence": 7
                        },
                        {
                          "name": "repairMaterialCostUSD",
                          "label": "Material Cost (USD)",
                          "valueType": "string",
                          "sequence": 8
                        },
                        {
                          "name": "repairPartsCostLocalCurrency",
                          "label": "Part Cost (local)",
                          "valueType": "string",
                          "sequence": 9
                        },
                        {
                          "name": "repairPartsCostUSD",
                          "label": "Part Cost (USD)",
                          "valueType": "string",
                          "sequence": 10
                        },
                        {
                          "name": "repairTotalCostLocalCurrency",
                          "label": "Total Cost (local)",
                          "valueType": "string",
                          "sequence": 11
                        },
                        {
                          "name": "repairTotalCostUSD",
                          "label": "Total Cost (USD)",
                          "valueType": "string",
                          "sequence": 12
                        },
                        {
                          "name": "repairCode",
                          "label": "Repair Code",
                          "valueType": "string",
                          "sequence": 13
                        },
                        {
                          "name": "mercMode",
                          "label": "Merc Mode",
                          "valueType": "string",
                          "sequence": 14
                        }
                      ]
                    }
                  ]
                },
                {
                  "name": "containerMoves",
                  "type": "containerMoves",
                  "items": [
                    {
                      "name": "activityDateTime",
                      "label": "Activity Date",
                      "valueType": "date",
                      "sequence": 1,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "name": "activity",
                      "label": "Activity",
                      "valueType": "string",
                      "sequence": 2,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "name": "bookingNumber",
                      "label": "Booking number",
                      "valueType": "string",
                      "sequence": 3,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "name": "activityReason",
                      "label": "Activity Reason",
                      "valueType": "string",
                      "sequence": 4,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "name": "activityLocation",
                      "label": "Activity Location",
                      "valueType": "string",
                      "sequence": 5,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "name": "placeOfReceipt",
                      "label": "Place of Receipt",
                      "valueType": "string",
                      "sequence": 6,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "name": "placeOfDelivery",
                      "label": "Place of Delivery",
                      "valueType": "string",
                      "sequence": 7,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "name": "emptyStatus",
                      "label": "Empty Status",
                      "valueType": "string",
                      "sequence": 8,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    }
                  ]
                },
                {
                  "name": "attachments",
                  "label": "Attachments",
                  "subLabel": "Images and damage codes for the selected eEIR are shown below",
                  "type": "Attachments",
                  "items": [
                    {
                      "name": "sourceName",
                      "label": "Source",
                      "valueType": "string",
                      "sequence": 1
                    },
                    {
                      "name": "inspectionId",
                      "label": "EIR No",
                      "valueType": "string",
                      "sequence": 1
                    },
                    {
                      "name": "createdDate",
                      "label": "Created On",
                      "valueType": "date",
                      "sequence": 2
                    },
                    {
                      "name": "shopCode",
                      "label": "Shop Code",
                      "valueType": "string",
                      "sequence": 3
                    },
                    {
                      "name": "createdBy",
                      "label": "Created By",
                      "valueType": "string",
                      "sequence": 4
                    },
                    {
                      "name": "moveType",
                      "label": "Move Type",
                      "valueType": "string",
                      "sequence": 5
                    },
                    {
                      "name": "imageCount",
                      "label": "Photos",
                      "valueType": "string",
                      "sequence": 6
                    }
                  ]
                },
                {
                  "label": "Recoverable Cost",
                  "type": "lib-Form",
                  "name": "vendor-recoverable-cost",
                  "items": [
                    {
                      "name": "gridSchema",
                      "label": "Grid Schema",
                      "items": [
                        {
                          "name": "costType",
                          "label": "Recoverable Costs",
                          "valueType": "string",
                          "sequence": 1
                        },
                        {
                          "name": "costLocal",
                          "label": "Cost (Local)",
                          "valueType": "numeric",
                          "sequence": 2
                        },
                        {
                          "name": "costUSD",
                          "label": "Cost (USD)",
                          "valueType": "numeric",
                          "sequence": 3
                        }
                      ]
                    },
                    {
                      "name": "footerSchema",
                      "label": "Footer Schema",
                      "items": [
                        {
                          "name": "costType",
                          "label": "Cost Type",
                          "valueType": "string",
                          "sequence": 1,
                          "colspan": 1
                        },
                        {
                          "name": "costLocal",
                          "label": "Cost (Local)",
                          "valueType": "numeric",
                          "sequence": 2,
                          "colspan": 1
                        },
                        {
                          "name": "costUSD",
                          "label": "Cost (USD)",
                          "valueType": "numeric",
                          "sequence": 3,
                          "colspan": 1
                        }
                      ]
                    },
                    {
                      "name": "woCostUSD",
                      "label": "Work Order Cost (including taxes)",
                      "valueType": "numeric",
                      "sequence": 1
                    },
                    {
                      "name": "recoverableCostUSD",
                      "label": "Recoverable Cost",
                      "valueType": "numeric",
                      "sequence": 2
                    },
                    {
                      "name": "handlingDamageCostUSD",
                      "label": "Handling damage cost",
                      "valueType": "numeric",
                      "sequence": 3
                    },
                    {
                      "name": "aboveCoverageCostUSD",
                      "label": "Total Liability",
                      "valueType": "numeric",
                      "sequence": 4
                    }
                  ]
                },
                {
                  "name": "liabilityDetails",
                  "type": "LiabilityDetails",
                  "items": [
                    {
                      "name": "gridSchema",
                      "label": "Grid Schema",
                      "items": [
                        {
                          "name": "costType",
                          "label": "Recoverable Costs",
                          "valueType": "string",
                          "sequence": 1
                        },
                        {
                          "name": "costLocal",
                          "label": "Cost (Local)",
                          "valueType": "numeric",
                          "sequence": 2
                        },
                        {
                          "name": "costUSD",
                          "label": "Cost (USD)",
                          "valueType": "numeric",
                          "sequence": 3
                        }
                      ]
                    },
                    {
                      "name": "footerSchema",
                      "label": "Footer Schema",
                      "items": [
                        {
                          "name": "costType",
                          "label": "Cost Type",
                          "valueType": "string",
                          "sequence": 1,
                          "colspan": 1
                        },
                        {
                          "name": "costLocal",
                          "label": "Cost (Local)",
                          "valueType": "numeric",
                          "sequence": 2,
                          "colspan": 1
                        },
                        {
                          "name": "costUSD",
                          "label": "Cost (USD)",
                          "valueType": "numeric",
                          "sequence": 3,
                          "colspan": 1
                        }
                      ]
                    },
                    {
                      "name": "woCostUSD",
                      "label": "Work Order Cost (including taxes)",
                      "valueType": "numeric",
                      "sequence": 1
                    },
                    {
                      "name": "withinCoverageCostUSD",
                      "label": "Within Coverage Cost",
                      "valueType": "numeric",
                      "sequence": 2
                    },
                    {
                      "name": "recoverableCostUSD",
                      "label": "Recoverable Cost",
                      "valueType": "numeric",
                      "sequence": 3
                    },
                    {
                      "name": "handlingDamageCostUSD",
                      "label": "Handling damage cost",
                      "valueType": "numeric",
                      "sequence": 3
                    },
                    {
                      "name": "aboveCoverageCostUSD",
                      "label": "Total Liability",
                      "valueType": "numeric",
                      "sequence": 4
                    }
                  ]
                },
                {
                  "label": "Damage Details",
                  "type": "lib-Form",
                  "name": "damage-Details",
                  "items": [
                    {
                      "label": "id",
                      "name": "id",
                      "hidden": true
                    },
                    {
                      "label": "Description",
                      "name": "description",
                      "type": "inputText",
                      "valueType": "string",
                      "width": {
                        "size": 30,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 1,
                      "hideLabel": true,
                      "placeHolder": "Enter Description here",
                      "isMandatory": true
                    },
                    {
                      "label": "Material Code",
                      "name": "materialCode",
                      "type": "select",
                      "valueType": "string",
                      "hidden": false,
                      "width": {
                        "size": 40,
                        "unit": "%"
                      },
                      "sequence": 2,
                      "hideLabel": true,
                      "placeHolder": "Select an option",
                      "isMandatory": true
                    },
                    {
                      "label": "Amount (Local)",
                      "name": "amountInCaseCurrency",
                      "type": "inputText",
                      "valueType": "numeric",
                      "width": {
                        "size": 15,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 3,
                      "hideLabel": true,
                      "placeHolder": "Enter Amount here",
                      "isMandatory": true
                    },
                    {
                      "label": "Amount (USD)",
                      "name": "amount",
                      "type": "label",
                      "valueType": "numeric",
                      "width": {
                        "size": 15,
                        "unit": "%"
                      },
                      "hidden": false,
                      "labelPosition": "above",
                      "sequence": 4,
                      "isMandatory": true
                    }
                  ]
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "liability",
      "type": "",
      "hidden": false,
      "sequence": 1,
      "label": "Liability",
      "id": 2,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "type": "lib-Form",
                  "name": "liability-party-vendor-details",
                  "hidden": false,
                  "items": [
                    {
                      "type": "section-1",
                      "name": "section-1",
                      "items": [
                        {
                          "label": "Shop Location Code",
                          "name": "shopLocationCode",
                          "type": "typeAhead",
                          "valueType": "string",
                          "hidden": false,
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 1
                        },
                        {
                          "label": "Shop Code",
                          "name": "shopCode",
                          "type": "select",
                          "valueType": "string",
                          "hidden": true,
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 2,
                          "isMandatory": true,
                          "validationMessage": "Please select valid value"
                        },
                        {
                          "label": "SCV Code",
                          "name": "scvCode",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "hidden": true,
                          "sequence": 3,
                          "placeHolder": "Type and search SCV code",
                          "isMandatory": true
                        },
                        {
                          "label": "FACT Code",
                          "name": "factCode",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "hidden": true,
                          "placeHolder": "Type and search FACT code",
                          "sequence": 4,
                          "isMandatory": true
                        },
                        {
                          "label": "Contact Email Address",
                          "name": "primaryContactEmail",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "hidden": true,
                          "sequence": 5
                        }
                      ]
                    },
                    {
                      "type": "section-2",
                      "hidden": true,
                      "items": [
                        {
                          "label": "Phone Number",
                          "name": "phoneNumber",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 25,
                            "unit": "%"
                          },
                          "sequence": 1
                        },
                        {
                          "label": "Contact Name",
                          "name": "contactName",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 25,
                            "unit": "%"
                          },
                          "sequence": 2
                        },
                        {
                          "label": "Shop Name",
                          "name": "shopName",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 25,
                            "unit": "%"
                          },
                          "sequence": 3
                        },
                        {
                          "label": "Address",
                          "name": "address",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 25,
                            "unit": "%"
                          },
                          "sequence": 4
                        },
                        {
                          "label": "Country",
                          "name": "countryName",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 25,
                            "unit": "%"
                          },
                          "sequence": 5
                        },
                        {
                          "label": "ZIP Code",
                          "name": "zipCode",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 25,
                            "unit": "%"
                          },
                          "sequence": 6
                        }
                      ]
                    },
                    {
                      "type": "section-3",
                      "name": "section_3",
                      "hidden": true,
                      "items": [
                        {
                          "label": "Payers",
                          "name": "payers",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 1,
                          "isMandatory": true
                        },
                        {
                          "label": "Bill to Party",
                          "name": "billTo",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 2,
                          "isMandatory": true
                        },
                        {
                          "label": "Ship to Party",
                          "name": "shipTo",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 3,
                          "isMandatory": true
                        },
                        {
                          "label": "Sold to Party",
                          "name": "soldTo",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 4,
                          "isMandatory": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "label": "New Letter",
                  "type": "letter-section",
                  "name": "liability-letter",
                  "hidden": false
                },
                {
                  "label": "Previously Issued Letters",
                  "type": "liability-letters-log",
                  "name": "liability-letters-log",
                  "hidden": true
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "debitNote",
      "type": "",
      "hidden": false,
      "sequence": 2,
      "label": "Debit Note",
      "id": 3,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "label": "Invoice Summary",
                  "type": "invoice-summary",
                  "name": "invoice-summary",
                  "items": [
                    {
                      "name": "materialCode",
                      "label": "Material Code",
                      "valueType": "string",
                      "align": "LEFT",
                      "sequence": 1
                    },
                    {
                      "name": "description",
                      "label": "Description",
                      "valueType": "string",
                      "align": "LEFT",
                      "sequence": 2
                    },
                    {
                      "name": "amount",
                      "label": "Amount",
                      "valueType": "numeric",
                      "align": "RIGHT",
                      "sequence": 3
                    }
                  ]
                },
                {
                  "label": "invoice letter",
                  "type": "invoice-letter",
                  "name": "invoice-letter",
                  "hidden": false
                },
                {
                  "label": "Previously Issued Invoices",
                  "type": "invoice-letters-log",
                  "name": "invoice-letters-log",
                  "hidden": false
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "complete",
      "label": "Complete",
      "sequence": 3,
      "id": 4,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "label": "Invoice Summary",
                  "type": "invoice-summary",
                  "name": "invoice-summary",
                  "items": [
                    {
                      "name": "materialCode",
                      "label": "Material Code",
                      "valueType": "string",
                      "align": "LEFT",
                      "sequence": 1
                    },
                    {
                      "name": "description",
                      "label": "Description",
                      "valueType": "string",
                      "align": "LEFT",
                      "sequence": 2
                    },
                    {
                      "name": "amount",
                      "label": "Amount",
                      "valueType": "numeric",
                      "align": "RIGHT",
                      "sequence": 3
                    }
                  ]
                },
                {
                  "label": "invoice letter",
                  "type": "invoice-letter",
                  "name": "invoice-letter",
                  "hidden": false
                },
                {
                  "label": "Previously Issued Invoices",
                  "type": "invoice-letters-log",
                  "name": "invoice-letters-log",
                  "hidden": false
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "stagesHeader": [
    {
      "label": "New",
      "name": "new",
      "StageId": "new_stage",
      "id": 1,
      "sequence": 0
    },
    {
      "label": "Liability",
      "name": "liability",
      "stageId": "liability_stage",
      "id": 2,
      "sequence": 1
    },
    {
      "label": "Debit Note",
      "name": "debitNote",
      "stageId": "debit_note_stage",
      "sequence": 2,
      "id": 3
    },
    {
      "label": "Complete",
      "name": "complete",
      "id": 4,
      "stageId": "complete_stage",
      "sequence": 3
    }
  ]
}