<ng-container *ngIf="customerRecoveryData$ | async as recoveryData">
  <ng-container *ngTemplateOutlet="notification"></ng-container>
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</ng-container>

<ng-template #notification>
  <ng-container *ngIf="caseInvoiceStatus$ | async as caseInvoiceStatus">
    <div
      class="notification"
      *ngIf="
        !caseInvoiceStatus.isReadyToInvoice &&
        caseInvoiceStatus.totalSecondsRemainingToInvoice !== 0
      ">
      <mc-notification
        body="You can issue invoice in {{ caseInvoiceStatus.displayMessage }}."
        heading="Invoice stand by"
        appearance="info"
        fit="large"
        icon="info-circle"></mc-notification>
    </div>
  </ng-container>
</ng-template>

<ng-template #mainContent>
  <ng-container *ngIf="liabilityLetters$ | async as liabilityLetters">
    <lib-panel>
      <header>Previously Issued Letters</header>
      <left-corner-menu>
        <mc-button
          label="Issue new letter"
          variant="outlined"
          appearance="neutral"
          fit="small"
          padding="default"
          [disabled]="!enableIssueNewLetterButton || closedCase"
          (click)="issueNewLetterClicked()"></mc-button>
      </left-corner-menu>
      <content>
        <div class="email-list-wrapper">
          @for (emailEntitiesAsFormItems of liabilityLetters; track $index) {
            <div *ngIf="$index > 0" class="hr"></div>
            <div class="email-notification-list">
              <app-lib-form
                [items]="emailEntitiesAsFormItems.template"></app-lib-form>
              <div class="action-button-section">
                <span
                  *ngIf="!emailEntitiesAsFormItems.letter.liabilityLetterStatus"
                  class="action-button">
                  <mc-button
                    [isAuthorizedFor]="'recovery-update'"
                    label="Capture Response"
                    [disabled]="closedCase"
                    variant="filled"
                    fit="small"
                    padding="default"
                    (click)="
                      onCaptureResponseButtonClicked(
                        emailEntitiesAsFormItems.letter.id ?? 0
                      )
                    "></mc-button>
                </span>
                <div
                  class="label"
                  *ngIf="emailEntitiesAsFormItems.letter.liabilityLetterStatus">
                  {{
                    getLiabilityStatusEnumText(
                      emailEntitiesAsFormItems.letter.liabilityLetterStatus
                    )
                  }}
                  <div class="label-header">Response</div>
                </div>
                <span>
                  <mc-popover position="bottom-left" #popover>
                    <mc-button
                      [isAuthorizedFor]="'recovery-update'"
                      slot="trigger"
                      label="Button"
                      hiddenlabel
                      variant="outlined"
                      [disabled]="closedCase"
                      fit="small"
                      icon="ellipsis-vertical"></mc-button>
                    <mc-option
                      (click)="
                        resendLetterOptionSelected(
                          emailEntitiesAsFormItems,
                          emailEntitiesAsFormItems.letter.id ?? 0
                        );
                        popover.hide()
                      ">
                      Resend Letter
                    </mc-option>
                    <mc-option
                      (click)="
                        viewLetterOptionSelected(emailEntitiesAsFormItems);
                        popover.hide()
                      ">
                      View Letter
                    </mc-option>
                    <mc-option
                      (click)="
                        onDownloadButtonClicked(emailEntitiesAsFormItems);
                        popover.hide()
                      ">
                      Download Letter
                    </mc-option>
                  </mc-popover>
                </span>
              </div>
            </div>
            <ng-container
              *ngTemplateOutlet="
                resendLiabilityEmailPanel;
                context: { letterExtended: emailEntitiesAsFormItems }
              "></ng-container>
            <ng-container
              *ngTemplateOutlet="
                viewLiabilityEmailPanel;
                context: { letterExtended: emailEntitiesAsFormItems }
              "></ng-container>
          }
        </div>
      </content>
    </lib-panel>
  </ng-container>
</ng-template>

<ng-container *ngIf="showLiabilityLetterResponseCapturingModal">
  <mc-modal
    heading="Liability Letter Response"
    [open]="true"
    fit="small"
    (closed)="showLiabilityLetterResponseCapturingModal = false">
    <span class="modal-body">
      <mc-radio-group
        legend="Please select the response received from the liability party."
        orientation="horizontal"
        (change)="responseStatusChanged($event)"
        [value]="selectedResponse">
        <mc-radio name="response" value="ACCEPTED" label="Accepted"></mc-radio>
        <mc-radio name="response" value="REJECTED" label="Rejected"></mc-radio>
        <mc-radio
          name="response"
          value="NO_RESPONSE"
          label="No response"></mc-radio>
      </mc-radio-group>
      <mc-input
        label="Reason (Optional)"
        placeholder="Enter Reason"
        (input)="responseReasonChanged($event)"></mc-input>
    </span>
    <mc-button
      focusstartanchor
      slot="primaryAction"
      appearance="primary"
      onclick="onPrimaryActionClick"
      dialogaction="ok"
      (click)="onSaveResponseClicked()">
      Save
    </mc-button>
    <mc-button
      focusendanchor
      slot="secondaryAction"
      appearance="neutral"
      variant="outlined"
      onclick="onSecondaryActionClick"
      dialogaction="cancel">
      Cancel
    </mc-button>
  </mc-modal>
</ng-container>

<ng-template #resendLiabilityEmailPanel let-letterExtended="letterExtended">
  <lib-panel *ngIf="letterExtended.showResendEmailPanel">
    <header>Resend Letter</header>
    <left-corner-menu>
      <mc-button
        label="Button"
        hiddenlabel
        variant="plain"
        fit="small"
        icon="times"
        (click)="letterExtended.showResendEmailPanel = false"></mc-button>
    </left-corner-menu>
    <content>
      <div [formGroup]="resendLiabilityLetterForm!" class="form-items">
        <div>
          <mc-input
            ngDefaultControl
            label="To"
            fit="medium"
            formControlName="to"
            name="to"
            [invalid]="
              resendLiabilityLetterForm!.get('to')?.touched &&
              resendLiabilityLetterForm!.get('to')?.errors
            "></mc-input>
          <mc-error
            *ngIf="
              resendLiabilityLetterForm!.get('to')?.touched &&
              resendLiabilityLetterForm!.get('to')?.errors?.['emailInvalid']
            "
            invalid
            errormessage="Invalid Email(s), Email(s) must be semi-colon separated valid email(s)."></mc-error>
          <mc-error
            *ngIf="
              resendLiabilityLetterForm!.get('to')?.touched &&
              resendLiabilityLetterForm!.get('to')?.errors?.['required']
            "
            invalid
            errormessage="We need to know who to send this to. Please enter at least one email."></mc-error>
        </div>
        <div>
          <mc-input
            ngDefaultControl
            label="CC (Optional)"
            fit="medium"
            formControlName="cc"
            name="cc"
            placeholder="Enter email"
            [invalid]="
              resendLiabilityLetterForm!.get('cc')?.touched &&
              resendLiabilityLetterForm!.get('cc')?.errors?.['emailInvalid']
            "></mc-input>
          <mc-error
            *ngIf="
              resendLiabilityLetterForm!.get('cc')?.touched &&
              resendLiabilityLetterForm!.get('cc')?.errors?.['emailInvalid']
            "
            invalid
            errormessage="Invalid Email(s), Email(s) must be semi-colon separated valid email(s)."></mc-error>
        </div>
        <div>
          <mc-input
            ngDefaultControl
            label="Subject"
            fit="medium"
            formControlName="subject"
            name="subject"
            [invalid]="
              resendLiabilityLetterForm!.get('subject')?.touched &&
              resendLiabilityLetterForm!.get('subject')?.errors
            "></mc-input>
          <mc-error
            *ngIf="
              resendLiabilityLetterForm!.get('subject')?.touched &&
              resendLiabilityLetterForm!.get('subject')?.errors?.['required']
            "
            invalid
            errormessage="Please enter the subject"></mc-error>
        </div>
        <div class="action-buttons">
          <mc-button
            label="Send"
            variant="filled"
            fit="small"
            padding="default"
            (click)="resendMail(letterExtended.letter)"></mc-button>
          <mc-button
            label="Cancel"
            variant="outlined"
            appearance="neutral"
            fit="small"
            padding="default"
            (click)="closeResendAndViewPanels(letterExtended)"></mc-button>
        </div>
      </div>
    </content>
  </lib-panel>
</ng-template>

<ng-template #viewLiabilityEmailPanel let-letterExtended="letterExtended">
  <lib-panel *ngIf="letterExtended.showViewEmailPanel">
    <header>{{ letterExtended.letter.subject }}</header>
    <left-corner-menu>
      <mc-button
        label="Button"
        hiddenlabel
        variant="plain"
        fit="small"
        icon="times"
        (click)="closeResendAndViewPanels(letterExtended)"></mc-button>
    </left-corner-menu>
    <content>
      <div class="form-items">
        <ng-container *ngIf="images && images.length > 0">
          <lib-image-lister [images]="images!"></lib-image-lister>
        </ng-container>
        <div
          class="mds-text--medium-normal email-template-container"
          [innerHTML]="emailBody"></div>
      </div>
    </content>
  </lib-panel>
</ng-template>
