import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import {
  CellClickEvent,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridFilter,
  GridRowData,
  SizeUnit,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { CustomerRecoveryClaimService } from '../../common/services/customer-recovery/customer-recovery-claim.service';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../common/services/customer-recovery/common.service';
import { ClaimStatusRequest } from '../../common/models/claim-status-request';
import { StatusDetailModel } from '../../common/models/status-detail';
import { UserPreferencesService } from '../../common/services/user-preferences/user-preferences.service';
import { Router } from '@angular/router';
import {
  Observable,
  catchError,
  filter,
  first,
  firstValueFrom,
  tap,
} from 'rxjs';

import { SharedRecoveryCaseService } from '../../shared-recovery-case-service';
import { environment } from '../../../environments/environment';
import { SharedDataService } from '../../shared-data-service';
import { VendorRecoveryDataManager } from './vendor-recovery-data-manager';
import { RecoveryCaseService } from '../../common/services/recovery/recovery.service';
import { SharedVendorRecoveryCaseService } from './shared-vendor-recovery-case.service';
import { VendorCreateCaseComponent } from '../custom-workflow/vendor-create-case/vendor-create-case.component';
import { DcrpAuthorizationService } from '../../common/services/authorization/dcrp-authorization.service';
import { IsAuthorizedForDirective } from '../../common/directives/is-authorized-for.directive';

@Component({
  selector: 'vendor-recovery',
  standalone: true,
  imports: [
    GridComponent,
    CommonModule,
    VendorCreateCaseComponent,
    IsAuthorizedForDirective,
  ],
  templateUrl: './vendor-recovery.component.html',
  styleUrl: './vendor-recovery.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VendorRecoveryComponent implements OnInit {
  /**
   * Height is required to show scrollbars for the grid. If not provided, grid height will be 100% (i.e. it will wrap all records init).
   */
  height: SizeUnit = {
    size: 70,
    unit: 'vh',
  };

  /**
   * Local variables required for the component.
   */
  showFilter: boolean = false;
  statusDetails!: StatusDetailModel;
  IsStatusOrCommentModalVisible: boolean = false;
  claimStatusRequest: ClaimStatusRequest | undefined;
  showRowSelector: boolean = true;
  showLoader: boolean = true;
  selectedRowsOfGrid?: [{ [key: string]: unknown }];
  IsNewCaseModalVisible: boolean = false;
  /**
   * data manager acts as a view-model. We wanted to keep only UI specific logic here in component and all data/model related operations are moved in data manager.
   */
  dataManager: VendorRecoveryDataManager;
  /**
   * grid data that is shown in the grid
   */
  gridData$: Observable<GridRowData[] | null>;
  /**
   * grid schema that is prepared based on user preferences.
   */
  gridSchema$: Observable<GridColumnSchema[] | null>;
  /**
   * Total records observable for the current customer recovery claim records in grid.
   */
  totalRecords$?: Observable<number>;

  gridFilter: GridFilter;

  emailID: string = sessionStorage.getItem('email')?.toString() ?? '';
  feedBackId: string = environment.IN_SIGHT_FEEDBACK_ID;

  constructor(
    _customerRecoveryClaimService: CustomerRecoveryClaimService,
    _recoveryClaimService: RecoveryCaseService,
    _userPreferencesService: UserPreferencesService,
    private _toaster: ToasterService,
    private _commonService: CommonService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService,
    private _router: Router,
    private _sharedVendorCaseService: SharedVendorRecoveryCaseService,
    private _dcrpAuthorizationService: DcrpAuthorizationService
  ) {
    this.dataManager = new VendorRecoveryDataManager(
      _customerRecoveryClaimService,
      _recoveryClaimService,
      _userPreferencesService,
      _commonService,
      _sharedRecoveryCaseService,
      _sharedDataService,
      _dcrpAuthorizationService,
      _sharedVendorCaseService
    );
    this.gridSchema$ = this.dataManager.gridSchema$;
    this.dataManager.onGridColumnClick = this.onGridColumnClicked.bind(this);

    this.gridData$ = this.dataManager.gridData$.pipe(
      catchError((_) => {
        this.showLoader = false;
        this.updateGridWithNewData(true);
        throw 'Error while getting grid data from server.';
      }),
      // This filter makes sure when `gridData$` is empty (i.e. empty array mostly emitted by `gridDataSubject$$`), then table-skeleton is loaded
      // and when `gridData$` is `not empty` or `null` (i.e. API response data) then code flow goes ahead to stop loader and show data in grid.
      filter((value) => value?.length !== 0),
      tap((_) => {
        this.showLoader = false;
      })
    );
    this.totalRecords$ = this.dataManager.totalRecords$;
    this.gridFilter = this.dataManager.gridFilter;
  }

  tabIndex$!: Observable<number>;
  //to invoke this change only while we navigate via link from workflow
  componentInitiated: boolean = false;
  ngOnInit(): void {
    this.tabIndex$ =
      this._sharedVendorCaseService.currentVendorRecoveryTabIndex$.pipe(
        tap((tabIndex) => {
          if (tabIndex && !this.componentInitiated) {
            //only on load.
            this.loadGridBasedOnCurrentTab(tabIndex);
            this.componentInitiated = true;
          }
        })
      );
  }

  /**
   * This is fired when grid filters are changed. We are re-fetching grid data on this event.
   * @param event GridFilter Event
   */
  async onGridFilterChanged(event: GridFilter) {
    const tabIndex = await firstValueFrom(
      this._sharedVendorCaseService.currentVendorRecoveryTabIndex$
    );

    if (!event) return;

    if (event.filters)
      Object.entries(event.filters).forEach(([key, value]) => {
        if (!event.filters) return;
        if (!value || (value as string[]).length === 0)
          delete event.filters[key];
      });

    this.dataManager.gridFilter = { ...event };

    if (this.dataManager.gridFilter.saveFilterConfiguration) {
      await this.dataManager.updateGridFiltersToServer(
        this.dataManager.gridFilter.defaultFilterApplied
      );
      this._toaster.showToast({
        message: 'Filter saved successfully!',
        type: 'success',
      } as Toast);
    }

    if (
      this.dataManager.gridFilter.newFiltersApplied &&
      (!this.dataManager.gridFilter.filters ||
        Object.keys(this.dataManager.gridFilter.filters).length === 0)
    ) {
      await this.dataManager.resetFiltersAsPerCurrentTab(tabIndex);
    } else {
      await this.dataManager.updateVendorRecoveryRequestAsPerCustomFilters(
        tabIndex
      );
    }

    await this.fetchPageData();
  }

  /**
   * This method is triggered when grid column click event is triggered. We are writing various actions on this.
   * @param event Grid Column Cell Clicked Event
   * @returns void
   */
  onGridColumnClicked(cellData: CellClickEvent) {
    if (!cellData?.column) return;

    const { column, rowData } = cellData;

    if (column === 'id') {
      this.handleRecoveryCaseClick(rowData!);
    }
  }

  private handleRecoveryCaseClick(rowData: { [key: string]: GridCellData }) {
    this._router.navigate(['vendor-recovery/workflow/'], {
      queryParams: {
        caseId: rowData['id'].value,
        containerNumber: rowData['equipmentNumber'].value,
      },
    });
  }

  /**
   * This event will be triggered whenever new rows are related in the grid.
   * @param selectedRows currently selected rows
   */
  onGridRowSelected(selectedRows: { [key: string]: unknown }[]) {
    this.selectedRowsOfGrid = selectedRows as [{ [key: string]: unknown }];
  }

  /**
   * This event is triggered from grid and we then store the new schema which has been modified.
   * @param newSchema new schema provided by the grid after column re-ordering.
   */
  async onGridSchemaChanged(newSchema: GridColumnSchema[]) {
    await this.dataManager.updateGridSchemaToServer(newSchema);
  }

  /**
   * This method validates the cases to be assigned and then assigns them to the current user.
   * @returns empty promise
   */
  async onAssignCasesToMeClicked(): Promise<void> {}

  /**
   * This method validates the cases to be assigned and then assigns them to the current user.
   * @returns empty promise
   */
  async onUnassignCasesClicked(): Promise<void> {}

  /**
   * Updates grid data and properties as per selected grid tab
   * @param event - Details of selected tab
   * @returns
   */
  async onCaseTabChange(event: any) {
    this.componentInitiated = true;
    this._sharedVendorCaseService.updateTabSelected(event.detail);
    await this.loadGridBasedOnCurrentTab(event.detail);
  }

  async loadGridBasedOnCurrentTab(tabIndex: number) {
    //Default status column properties for open case tabs
    this.showRowSelector = true;
    //Setting grid properties and status filters as per selected tab
    switch (tabIndex) {
      case 0: //All cases tab
        this.dataManager.loadInitialFiltersForAllCasesGridView();
        break;
      case 1: //My cases tab
        await this.dataManager.resetFiltersAsPerCurrentTab(tabIndex);
        break;
      case 2: //Completed cases tab
        this.showRowSelector = false;
        await this.dataManager.resetFiltersAsPerCurrentTab(tabIndex);
        break;
      default:
        return;
    }

    this.fetchPageData();
  }

  /**
   *
   * @returns If case selection is valid or not.
   */
  private validateCasesForAssignment(): boolean {
    if (!this.selectedRowsOfGrid) return false;

    const alreadyAssignedCases = this.selectedRowsOfGrid.filter(
      (row) =>
        !['SYSTEM', 'SYS'].includes(
          (row['assignedToName'] as GridCellData).value as string
        )
    );

    const alreadDeletedAssignedCases = this.selectedRowsOfGrid.filter((row) =>
      ['Deleted', 'Draft'].includes(
        (row['claimStatusId'] as GridCellData).value as string
      )
    );

    if (alreadDeletedAssignedCases.length > 0) {
      this._toaster.showToast({
        message: `Work orders for some cases are deleted, please select other cases.`,
        type: 'error',
      } as Toast);
      return false;
    }

    if (alreadyAssignedCases.length > 0) {
      this._toaster.showToast({
        message: `Some of the cases are already assigned to different users.`,
        type: 'warning',
      } as Toast);
      return false;
    }

    return true;
  }

  private updateGridWithAssignedUsers(
    caseNumbers: string[],
    username: string,
    markInvalid?: boolean
  ) {
    if (!this.dataManager.gridData || caseNumbers.length == 0 || !username)
      return;
    this.selectedRowsOfGrid = undefined;
    caseNumbers.forEach((caseNumber) => {
      const gridRowGroup = this.dataManager.gridData?.find(
        (row) =>
          row.row['recoveryCaseNumber'].value === caseNumber ||
          row.childRows?.find(
            (item: any) => item['recoveryCaseNumber'].value === caseNumber
          )
      );
      const row =
        gridRowGroup?.row['recoveryCaseNumber'].value === caseNumber
          ? gridRowGroup.row
          : gridRowGroup?.childRows?.find(
              (item: any) => item['recoveryCaseNumber'].value === caseNumber
            );
      if (gridRowGroup && row) {
        gridRowGroup.isRowSelectionInvalid = markInvalid;
        row['assignedToName'].value = username;
      }
    });
    this.updateGridWithNewData();
  }

  /**
   * This is important method where we are calling various APIs to get the consolidated data of the grid.
   * @param customerRecoveryRequest request object of the filter data
   */
  private async fetchPageData() {
    this.gridFilter = {
      ...this.dataManager.gridFilter,
    };
    this.showLoader = true;
    this.selectedRowsOfGrid = undefined;
    try {
      await this.dataManager.fetchDataFromServer();
    } catch (error) {
      this.updateGridWithNewData(true);
    } finally {
      this.showLoader = false;
    }
  }

  /**
   * Grid has `OnPush` as change detection strategy. So we need to update the reference of the input property of grid to reflect changes. Hence using spread operator here.
   * @returns
   */
  private updateGridWithNewData(makeEmpty?: boolean) {
    if (makeEmpty) {
      this.dataManager.gridData = null;
      return;
    }
    if (this.dataManager.gridData)
      this.dataManager.gridData = [...this.dataManager.gridData];
  }
}
