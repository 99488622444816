import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import * as template from '../../../../assets/json/dcrp-customer-workflow.json';
import * as manualCaseTemplate from '../../../../assets/json/dcrp-workflow-manual-case.json';
import { environment } from '../../../../environments/environment';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CaseTypeEnum } from '../../../common/constants/temporary-constant';
import { SharedCustomerRecoveryCaseService } from '../shared-customer-recovery-case.service';

export class CustomerRecoveryOrchestratorWorkflowManager {
  stages: TemplateModel[] = [];
  currentStageId: number = 0;

  localTemplate: any = (template as any).default;
  caseDetails!: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';

  constructor(
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    currentStageId: number,
    caseDetails: CustomerRecoveryCaseDto
  ) {
    this.currentStageId = currentStageId;
    this.caseDetails = caseDetails;
    this.templateBindingOnStageChange();
  }

  templateBindingOnStageChange() {
    if (environment.localTemplate) {
      //local template for development always latest template
      this.methodUsingLocalTemplate();
    }
  }

  methodUsingLocalTemplate() {
    this.localTemplate =
      this._customerRecoverySharedService.caseType ==
      CaseTypeEnum.CaseWithWorkOrder
        ? (template as any).default
        : (manualCaseTemplate as any).default;

    let locTem = JSON.parse(JSON.stringify(this.localTemplate));
    this.stages = locTem.stages;
  }

  setCurrentStageVariableBasedOnTemplate(stages: any) {
    let filterCurrentStage = stages?.templates?.filter(
      (i: any) => i.CurrentStageTemplate.value.id == this.currentStageId
    )[0];
    this.stages = filterCurrentStage.Stageheaders?.value
      ?.stages as TemplateModel[];
  }
}
