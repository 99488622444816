<div class="notification-main">
  <mc-notification icon="info-circle" appearance="info">
    {{ damageEstimationNotificationMsg }}
  </mc-notification>
</div>

<lib-panel>
  <header>{{ item.label }}</header>
  <content>
    <div *ngIf="templateModel$ | async as templateModel; else sectionloading">
      @if (templateModel && templateModel.length > 0) {
        <div class="line-item-main" *ngIf="showControls">
          <div style="display: flex">
            <app-lib-form [items]="templateModel"></app-lib-form>
            <div class="btn-div p-t-20">
              <mc-button
                appearance="primary"
                variant="filled"
                [loading]="showButtonLoader"
                fit="medium"
                (click)="addManualEstimates()"
                [disabled]="disable"
                label="">
                Add
              </mc-button>
            </div>
          </div>
        </div>
        <div class="line-item-main-on-load" *ngIf="!showControls">
          <div-skeleton-loader></div-skeleton-loader>
        </div>
      }
    </div>
    <div class="grid-container" *ngIf="lineItemsGridData$ | async as gridData">
      <ng-container>
        <app-grid
          [schema]="lineItemsGridSchema"
          [data]="gridData"
          [showPanel]="false"
          [showPagination]="false"
          [showLoader]="showGridLoader"
          [enableSorting]="false"></app-grid>
      </ng-container>
      <app-no-data
        *ngIf="gridData.length === 0"
        [height]="{ size: 40, unit: 'px' }">
        No line items found
      </app-no-data>
    </div>
    <ng-template #sectionloading>
      <lib-section-skeleton-loader></lib-section-skeleton-loader>
    </ng-template>
  </content>
</lib-panel>
