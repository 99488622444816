import {
  Component,
  Input,
  CUSTOM_ELEMENTS_SCHEMA,
  Output,
  EventEmitter,
} from '@angular/core';
import { WorkOrderAndLineItemsService } from '../../../common/services/customer-recovery/work-order-and-line-items.service';
import { Observable, combineLatest, firstValueFrom, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CommonModule } from '@angular/common';
import {
  ALIGN,
  GridComponent,
  GridRowData,
  GridCellData,
  GridColumnSchema,
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { WorkOrderLineItemDto } from '../../../common/models/workOrderLineItemDto';
import { workOrderStatusType } from '../../../common/constants/temporary-constant';
import {
  DOLLAR,
  HYPHEN,
  ZERO_STRING,
} from '../../../common/constants/app.constants';
import { WorkOrderDto } from '../../../common/models/WorkOrderDto';
import { SharedDataService } from '../../../shared-data-service';
import { SharedVendorRecoveryCaseService } from '../../vendor-recovery/shared-vendor-recovery-case.service';
import { WorkOrderLineItemDetailService } from '../../../common/services/recovery/workOrderLineItemDetail.service';
import { WorkOrderLineItemDetail } from '../../../common/models/workOrderLineItemDetail';
import { CaseAndWorkOrder } from '../../../common/models/caseAndWorkOrder';
import { CaseAndWorkOrderService } from '../../../common/services/recovery/caseAndWorkOrder.service';
import { RecoveryCaseType } from '../../../common/models/recoveryCaseType';
import { Router } from '@angular/router';

const WORK_ORDER_STATUS_CODE = 'workOrderStatusCode';
const ALL_COST_FIELDS_ARRAY = ['workOrderCostUSD'];

@Component({
  selector: 'work-order-list',
  standalone: true,
  imports: [CommonModule, LibFormComponent, GridComponent, PanelComponent],
  templateUrl: './work-order-list.component.html',
  styleUrl: './work-order-list.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WorkOrderListComponent {
  @Input() item!: TemplateModel;
  @Output() recoverableLineItems = new EventEmitter<
    { [key: string]: unknown }[]
  >();

  items: TemplateModel[] = [];
  apiVersion = '1.0';
  currentWorkOrder$: Observable<WorkOrderDto | null> | undefined;
  workOrderList$: Observable<WorkOrderDto[] | null> | undefined;
  currentLineItemForWorkOrders: WorkOrderLineItemDetail[] | null | undefined;
  lineItemsGridSchema: GridColumnSchema[] | undefined = [];
  lineItemsGridData: GridRowData[][] = [];
  showWorkOrderItems: boolean[] = [];
  workOrdersConverted: TemplateModel[][] = [];
  workOrderCaseIds: (number | undefined)[] = [];
  workOrdersData: WorkOrderDto[] = [];
  containerNumber: string | undefined;
  workOrderDate: Date | undefined;
  selectedFromDate: string | undefined;
  selectedToDate: string | undefined;
  invalidDateSelected: boolean = false;
  selectedIndex = 0;
  currentWorkOrderNumber: number | undefined;
  parsedFromDate: Date | null | undefined;
  parsedToDate: Date | null | undefined;
  disable: boolean = false;
  constructor(
    private _workOrderService: WorkOrderAndLineItemsService,
    private _sharedDataService: SharedDataService,
    private _sharedVendorRecoveryCaseService: SharedVendorRecoveryCaseService,
    private _workOrderLineItemService: WorkOrderLineItemDetailService,
    private _caseAndWorkOrderService: CaseAndWorkOrderService,
    private _router: Router
  ) {}

  /**
   * This method is triggered when the component initializes.
   * It initializes items.
   */

  customerRecoveryData$ = combineLatest([
    this._sharedVendorRecoveryCaseService.vendorRecoveryCaseData$,
    this._sharedVendorRecoveryCaseService.disableForm$,
  ]).pipe(
    tap(([customerData, disable]) => {
      this.disable = disable;
      this.containerNumber = customerData?.equipmentNumber;
      this.workOrderDate = undefined;
      this.currentWorkOrderNumber = customerData?.workOrderNumber;
      this.setInitialDateRangeForWorkOrders();
      this.initializeItems();
      this.fetchWorkOrders();
    })
  );

  /**
   * Initializes items based on the input data.
   */
  private initializeItems() {
    this.loadLineItemsGridSchema();
    this.items =
      this.item?.items?.filter((o) => o.name == 'workOrders')[0]?.items || [];
  }

  /**
   * Loads line item grid schema as per template
   */
  private loadLineItemsGridSchema() {
    this.lineItemsGridSchema = this.item?.items
      ?.filter((o) => o.name == 'workOrderLineItems')[0]
      .items?.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label,
          align: ALIGN.LEFT,
          hidden: false,
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          disableSort: true,
        } as GridColumnSchema;
        return column;
      });
  }

  /**
   * Fetches work orders from the service.
   */
  private fetchWorkOrders() {
    this.fetchCurrentWorkOrder();
    this.fetchWorkOrderHistory();
  }

  /**
   * Fetches current work order details for selected case
   */
  private fetchCurrentWorkOrder() {
    this.currentWorkOrder$ = combineLatest([
      this._workOrderService
        .workOrdersGet(this.currentWorkOrderNumber)
        .pipe(map((res) => (res?.data ? res.data[0] : null))),
      this._workOrderLineItemService
        .workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberGet(
          this.currentWorkOrderNumber ?? 0
        )
        .pipe(
          map((lineItems) => lineItems),
          catchError((_) => {
            return of([]);
          })
        ),
    ]).pipe(
      tap(([currentWorkOrder, lineItems]) => {
        this.currentLineItemForWorkOrders = lineItems;
        this._sharedVendorRecoveryCaseService.updateRecoverableWorkOrderSelectedLineItems(
          lineItems ?? []
        );
        if (!currentWorkOrder) return;
        this._sharedVendorRecoveryCaseService.updateWorkOrderLineItems(
          currentWorkOrder.workOrderLineItemDto ?? []
        );
        this._sharedVendorRecoveryCaseService.updateWorkOrderDate(
          currentWorkOrder.createdDate
        );
        this.mapWorkOrderToAlignWithUI(currentWorkOrder, 0, undefined);
      }),
      map(([currentWorkOrders]) => currentWorkOrders)
    );
  }

  /**
   * Fetches work order history based on container number, from date and to date.
   */
  private fetchWorkOrderHistory() {
    this.validateAndSetSearchDateRange();

    //Fetching work order history for selected duration
    const workOrderHistory$ = this._workOrderService
      .workOrdersGet(
        undefined,
        this.containerNumber,
        this.parsedFromDate ?? undefined,
        this.parsedToDate ?? undefined,
        this.apiVersion
      )
      .pipe(
        map((response) =>
          response?.data
            ? response.data.filter(
                (wo) => wo.workOrderNumber != this.currentWorkOrderNumber
              )
            : null
        )
      );

    //Fetching work order cases for selected duration
    const workOrderCases$ = this._caseAndWorkOrderService
      .casesWorkordersGet(
        this.containerNumber ?? '',
        this.parsedFromDate ?? undefined,
        this.parsedToDate ?? undefined,
        '1.0'
      )
      .pipe(
        map((response) => (response.data as CaseAndWorkOrder[]) ?? []),
        catchError(() => {
          return of([]);
        })
      );

    //Updating work order list by consolidating above fetched data
    this.workOrderList$ = combineLatest([
      workOrderHistory$,
      workOrderCases$,
    ]).pipe(
      tap(([workOrderHistory, workOrderCases]) => {
        workOrderHistory?.forEach((workorder, index) => {
          const caseId = workOrderCases.find(
            (woCase) =>
              woCase.workOrderNumber == workorder.workOrderNumber &&
              woCase.vendorRecoveryCase?.id &&
              woCase.vendorRecoveryCase.recoveryCaseType ==
                RecoveryCaseType.Vendor
          )?.vendorRecoveryCase?.id;
          this.mapWorkOrderToAlignWithUI(workorder, index + 1, caseId);
        });
      }),
      map(([workOrderList]) => workOrderList)
    );
  }

  /**
   * Fetches work order history based on search criteria
   * Hides line items for all previous work orders
   */
  searchWorkOrders() {
    this.fetchWorkOrderHistory();

    this.showWorkOrderItems.forEach((_showItem, index) => {
      if (index != 0) this.showWorkOrderItems[index] = false;
    });
  }

  /**
   * Updates the values of items based on the provided work order data.
   * @param workOrder The work order data containing item values.
   */
  private updateItemValues(workOrder: WorkOrderDto) {
    // Iterate over each item in the items array
    this.items.forEach((item: TemplateModel) => {
      // Cast the workOrder to a generic object for dynamic property access
      const customerRecoveryKeyValue = workOrder as unknown as {
        [key: string]: unknown;
      };

      // Update item value based on the item's name
      if (item.name == WORK_ORDER_STATUS_CODE) {
        // If the item's name is the work order status code, find the corresponding status
        const workOrderStatus = workOrderStatusType.find(
          (o) => o.value === customerRecoveryKeyValue[item.name]
        );

        // Set item value to the status label or '-' if not found
        item.value = workOrderStatus?.label ?? HYPHEN;
      } else {
        // For other items, set item value directly from work order data
        item.value = customerRecoveryKeyValue[item.name || ''];

        // If item value is falsy, set it to '-' or '0' if it's a number
        if (!item.value) {
          item.value = item.value === 0 ? DOLLAR + ZERO_STRING : HYPHEN;
        } else if (ALL_COST_FIELDS_ARRAY.includes(item.name)) {
          // If item name is in cost fields array, prepend '$' to the value
          item.value =
            DOLLAR + this._sharedDataService.formatDecimal(item.value);
        }
      }
    });
  }

  /**
   * This method transforms an array of line items received from the API response into an array of grid row objects.
   * @param lineItems Array of original objects received from the web API response
   * @returns Array of grid row objects created
   */
  private generateGridDataFromLineItems(
    lineItems: WorkOrderLineItemDto[] | undefined
  ): GridRowData[] {
    if (!lineItems || lineItems.length < 1) return [];
    return lineItems.map((lineItem) => {
      const lineItemKeyValue = lineItem as unknown as {
        [key: string]: unknown;
      };
      const gridRowObject: { [key: string]: GridCellData } = {};
      Object.keys(lineItem).map((key) => {
        if (key == 'isRecoverable') {
          gridRowObject[key] = {
            value: lineItemKeyValue[key] ? 'Y' : 'N',
          } as GridCellData;
        } else {
          gridRowObject[key] = {
            value: lineItemKeyValue[key],
          } as GridCellData;
        }
      });
      const lineItemChecked = this.currentLineItemForWorkOrders?.filter(
        (i) => i.workOrderLineItemId == lineItemKeyValue['workOrderLineItemId']
      );
      return {
        row: gridRowObject,
        isRowSelectionDisabled: this.disable,
        isRowSelected: lineItemChecked && lineItemChecked.length > 0,
      } as GridRowData;
    });
  }

  /**
   * Shows/hides line items for selected work order
   * Binds line item data to grid
   * @param index Index of selected work order
   * @param lineItems List of line items for selected work order
   */
  showHideWorkOrderItemsLink(
    index: number,
    lineItems: WorkOrderLineItemDto[] | undefined
  ) {
    this.showWorkOrderItems[index] = !this.showWorkOrderItems[index];

    if (this.showWorkOrderItems[index]) {
      this.lineItemsGridData[index] =
        this.generateGridDataFromLineItems(lineItems);
    }
  }

  /**
   * Maps work order data to Template model
   * @param workOrder Work order data
   * @param index Index of selected work order
   */
  private mapWorkOrderToAlignWithUI(
    workOrder: WorkOrderDto,
    index: number,
    caseId: number | undefined
  ) {
    // Adding data to list
    this.workOrdersData[index] = workOrder;
    // Update item values based on workOrder properties
    this.updateItemValues(workOrder);

    // Deep clone and assign updated items to orders
    this.workOrdersConverted[index] = this._sharedDataService.deepClone(
      this.items
    );

    this.workOrderCaseIds[index] = caseId;
  }

  /**
   * Sets default date range filter for fetching work order history
   */
  setInitialDateRangeForWorkOrders() {
    let dateToFetchWorkOrders = new Date();
    const toDatePlusVal = 120;

    if (
      this.workOrderDate &&
      this.workOrderDate.toString() != '0001-01-01T00:00:00'
    ) {
      dateToFetchWorkOrders = new Date(this.workOrderDate);
    }

    this.parsedToDate = new Date(dateToFetchWorkOrders.getTime());
    this.parsedFromDate = new Date(dateToFetchWorkOrders.getTime());

    this.parsedFromDate.setUTCDate(
      this.parsedFromDate.getUTCDate() - toDatePlusVal
    );

    const formatDateOptions = {
      timeZone: 'UTC',
    };

    this.selectedFromDate = this.parsedFromDate.toLocaleDateString(
      'en-GB',
      formatDateOptions
    );
    this.selectedToDate = this.parsedToDate.toLocaleDateString(
      'en-GB',
      formatDateOptions
    );
  }

  /**
   * Sets from/to date based on user selection
   * @param type Type of date - From/To
   * @param event Selected date value
   */
  dateSelected(type: string, event: any): void {
    if (type == 'from') {
      this.selectedFromDate = event.target.value;
    } else if (type == 'to') {
      this.selectedToDate = event.target.value;
    } else {
      return;
    }

    this.invalidDateSelected =
      !this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedFromDate!) ||
      !this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedToDate!);
  }

  /**
   * Sets from and to date for work order search
   */
  private validateAndSetSearchDateRange() {
    this.parsedFromDate = this.selectedFromDate
      ? this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedFromDate)
      : null;
    this.parsedToDate = this.selectedToDate
      ? this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedToDate)
      : null;

    this.invalidDateSelected =
      !this.parsedFromDate ||
      !this.parsedToDate ||
      this.parsedFromDate > this.parsedToDate;

    if (this.invalidDateSelected || !this.containerNumber) return;

    //Checking that To date should not be greater than current time
    if (this.parsedToDate) {
      this.parsedToDate = this._sharedDataService.changeUtcTime(
        this.parsedToDate,
        23,
        59,
        59
      );
      if (this.parsedToDate > new Date()) {
        this.parsedToDate = new Date();
      }
    }
  }

  /**
   * Sets currently selected work order index and resets other grids.
   * Updates selected work order details in related components.
   * @param event Radio button selection event
   */
  workOrderSelected(event: any) {
    this.selectedIndex = event.target.value;

    //Clearing line item selection for all grids
    this.lineItemsGridData.forEach((_lineItem, lineItemIndex) => {
      this.lineItemsGridData[lineItemIndex].forEach((_row, rowIndex) => {
        this.lineItemsGridData[lineItemIndex][rowIndex].isRowSelected = false;
      });
      this.lineItemsGridData[lineItemIndex] = [
        ...this.lineItemsGridData[lineItemIndex],
      ];
    });

    //Emitting empty line item list
    this.lineItemSelectionChanged([]);

    //Fetching selected work order data
    const selectedWoDetails = this.workOrdersData[this.selectedIndex];

    //Updating line items as per selected work order
    this._sharedVendorRecoveryCaseService.updateWorkOrderLineItems(
      selectedWoDetails.workOrderLineItemDto ?? []
    );

    //Updating work order date as per selected work order
    this._sharedVendorRecoveryCaseService.updateWorkOrderDate(
      selectedWoDetails.createdDate
    );
  }

  saveWorkOrderLineItem() {
    return combineLatest([
      this._sharedVendorRecoveryCaseService.vendorRecoverableWorkOrderLineItem$,
      this._sharedVendorRecoveryCaseService.vendorWorkOrderLineItems$,
    ]).pipe(
      take(1),
      switchMap(([selectedLineItems, workOrders]) => {
        const options = workOrders?.reduce(
          (acc: WorkOrderLineItemDetail[], lineItems) => {
            if (
              selectedLineItems.find(
                (selectedItems) =>
                  selectedItems.workOrderLineItemId ==
                  lineItems.workOrderLineItemId
              )
            )
              // Add unique damageCode to the set
              acc.push({
                workOrderNumber: lineItems.workOrderNumber,
                workOrderLineItemId: lineItems.workOrderLineItemId,
                tpCode: lineItems.tpCode,
                quantityRepair: lineItems.quantityRepair,
                repairDescription: lineItems.repairDescription,
                manHourRateLocalCurrency: lineItems.manHourRateLocalCurrency,
                manHourRateUSD: lineItems.manHourRateUSD,
                actualManHours: lineItems.actualManHours,
                repairMaterialCostLocalCurrency:
                  lineItems.repairMaterialCostUSD,
                repairMaterialCostUSD: lineItems.repairMaterialCostUSD,
                repairPartsCostLocalCurrency:
                  lineItems.repairPartsCostLocalCurrency,
                repairPartsCostUSD: lineItems.repairPartsCostUSD,
                repairTotalCostLocalCurrency:
                  lineItems.repairTotalCostLocalCurrency,
                repairTotalCostUSD: lineItems.repairTotalCostUSD,
                repairCode: lineItems.repairCode,
                mercMode: lineItems.mercMode,
                isRecoverable: lineItems.isRecoverable,
                isActive: lineItems.isActive,
              });
            return acc;
          },
          []
        );
        if (
          !options ||
          !workOrders ||
          options.length === 0 ||
          workOrders.length === 0
        )
          return of(true);
        return this._workOrderLineItemService.workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberPut(
          options,
          workOrders[0].workOrderNumber
        );
      })
    );
  }

  /**
   * Emits event containing list of selected work order line items
   * @param gridRows List of selected work order line items
   */
  async lineItemSelectionChanged(gridRows: { [key: string]: unknown }[]) {
    const workOrders = await firstValueFrom(
      this._sharedVendorRecoveryCaseService.vendorWorkOrderLineItems$
    );
    const selectedWorkOrders = this.getSelectedWorkOrderLineItems(
      workOrders,
      gridRows
    );
    this._sharedVendorRecoveryCaseService.updateRecoverableWorkOrderSelectedLineItems(
      selectedWorkOrders
    );
  }

  getSelectedWorkOrderLineItems(
    workOrders: WorkOrderLineItemDto[],
    gridRows: { [key: string]: unknown }[]
  ) {
    const lineItems = workOrders?.reduce(
      (acc: WorkOrderLineItemDetail[], lineItems) => {
        if (
          gridRows.filter(
            (i) =>
              (i['workOrderLineItemId'] as GridCellData).value ==
              lineItems.workOrderLineItemId
          ).length > 0
        )
          // Add unique damageCode to the set
          acc.push({
            id: lineItems.id,
            workOrderNumber: lineItems.workOrderNumber,
            workOrderLineItemId: lineItems.workOrderLineItemId,
            tpCode: lineItems.tpCode,
            quantityRepair: lineItems.quantityRepair,
            //repairLocationCode:damageTy
            repairDescription: lineItems.repairDescription,
            manHourRateLocalCurrency: lineItems.manHourRateLocalCurrency,
            manHourRateUSD: lineItems.manHourRateUSD,
            actualManHours: lineItems.actualManHours,
            repairMaterialCostLocalCurrency: lineItems.repairMaterialCostUSD,
            repairMaterialCostUSD: lineItems.repairMaterialCostUSD,
            repairPartsCostLocalCurrency:
              lineItems.repairPartsCostLocalCurrency,
            repairPartsCostUSD: lineItems.repairPartsCostUSD,
            repairTotalCostLocalCurrency:
              lineItems.repairTotalCostLocalCurrency,
            repairTotalCostUSD: lineItems.repairTotalCostUSD,
            repairCode: lineItems.repairCode,
            mercMode: lineItems.mercMode,
            isRecoverable: lineItems.isRecoverable,
            isActive: lineItems.isActive,
          });
        return acc;
      },
      []
    );
    return lineItems;
  }

  /**
   * Navigates to case workflow page based on case number and container number
   * @param caseNumber - Case number for which workflow needs to be displayed
   * @param containerNumber - Container number for which workflow needs to be displayed
   */
  navigateToRecoveryCaseWorkFlow(caseId: number | undefined) {
    if (!caseId) return;
    this._router.navigate(['vendor-recovery/workflow/'], {
      queryParams: {
        caseId: caseId,
        containerNumber: this.containerNumber,
      },
    });
  }
}
