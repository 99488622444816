/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BooleanResponse } from '../../models/booleanResponse';
import { LiabilityParty } from '../../models/liabilityParty';
import { LiabilityPartyResponse } from '../../models/liabilityPartyResponse';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { UpdateLiabilityParty } from '../../models/updateLiabilityParty';
import { SaveLiabilityParty } from '../../models/saveLiabilityParty';

@Injectable()
export class LiabilityPartyService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Delete an liabilityParty.
   * Sample request:                    Delete {id}                Returns bool: True for success and False for failure.    This endpoint supports the following HTTP status codes:  - 200 (OK): LiabilityParty detail are deleted successfully.  - 400 (Bad Request): The request is invalid or record not deleted.  - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param id Identity
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityPartiesIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public liabilityPartiesIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public liabilityPartiesIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public liabilityPartiesIdDelete(
    id: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling liabilityPartiesIdDelete.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<BooleanResponse>(
      'delete',
      `${this.basePath}/liability-parties/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get a liabilityParty by Id.
   * Sample request:                    GET {id}                Returns the liabilityParty with the specified id.    This endpoint supports the following HTTP status codes:  - 200 (OK): All liabilityParty are successfully retrieved.  - 404 (Not found): liabilityParty are not found.  - 400 (Bad Request): The request is invalid or missing required parameters.  - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param id Identity
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityPartiesIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LiabilityParty>;
  public liabilityPartiesIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LiabilityParty>>;
  public liabilityPartiesIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LiabilityParty>>;
  public liabilityPartiesIdGet(
    id: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling liabilityPartiesIdGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<LiabilityParty>(
      'get',
      `${this.basePath}/liability-parties/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create a new liabilityParty.
   * Sample request:                    Post liabilityParty                Returns bool: True for success and False for failure.    This endpoint supports the following HTTP status codes:  - 200 (OK): Invoice detail are added successfully.  - 400 (Bad Request): The request is invalid or record not created.  - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param body InvoiceDetail
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityPartiesPost(
    body?: SaveLiabilityParty,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LiabilityPartyResponse>;
  public liabilityPartiesPost(
    body?: SaveLiabilityParty,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LiabilityPartyResponse>>;
  public liabilityPartiesPost(
    body?: SaveLiabilityParty,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LiabilityPartyResponse>>;
  public liabilityPartiesPost(
    body?: SaveLiabilityParty,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<LiabilityPartyResponse>(
      'post',
      `${this.basePath}/liability-parties`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update an existing liabilityParty
   * Sample request:        Put liabilityParty    Returns bool: True for success and False for failure.    This endpoint supports the following HTTP status codes:  - 200 (OK): Invoice detail are updated successfully.  - 400 (Bad Request): The request is invalid or record not updated.  - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param id Unique Identifier
   * @param body liabilityParty
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityPartiesIdPut(
    id: number,
    body?: UpdateLiabilityParty,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LiabilityPartyResponse>;
  public liabilityPartiesIdPut(
    id: number,
    body?: UpdateLiabilityParty,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LiabilityPartyResponse>>;
  public liabilityPartiesIdPut(
    id: number,
    body?: UpdateLiabilityParty,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LiabilityPartyResponse>>;
  public liabilityPartiesIdPut(
    id: number,
    body?: UpdateLiabilityParty,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling liabilityPartiesIdPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<LiabilityPartyResponse>(
      'put',
      `${this.basePath}/liability-parties/${encodeURIComponent(String(id))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get a liabilityParty by caseId.
   *
   * @param caseId Case Id
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityPartiesGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public liabilityPartiesGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public liabilityPartiesGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public liabilityPartiesGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling liabilityPartiesGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (caseId !== undefined && caseId !== null) {
      queryParameters = queryParameters.set('caseId', <any>caseId);
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<LiabilityPartyResponse>(
      'get',
      `${this.basePath}/liability-parties`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
