import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {
  AUTHENTICATION_CONFIG,
  AuthenticationConfig,
  authenticationInterceptor,
  EmailModalService,
  error403Interceptor,
  ERROR_PAGE_CONFIG,
  HEADER_MENU_PROVIDER,
  IErrorPageConfig,
  ModalNotificationService,
  SIDE_NAV_PROVIDER,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { SideNavMenuService } from './common/services/side-nav-menu/side-nav-menu.service';
import { HeaderMenuService } from './common/services/header-menu/header-menu.service';
import { SharedRecoveryCaseService } from './shared-recovery-case-service';
import { CommonService } from './common/services/customer-recovery/common.service';
import { ApplicationConfigService } from './common/services/common/applicationConfig.service';
import { CustomerRecoveryClaimService } from './common/services/customer-recovery/customer-recovery-claim.service';
import { CaseService } from './common/services/case/case.service';
import { MaterialCodeService } from './common/services/common/materialCode.service';
import { HelpAndFaqService } from './common/services/help-and-faq/help-and-faq.service';
import { SharedCustomerRecoveryCaseService } from './components/customer-recovery/shared-customer-recovery-case.service';
import { SharedVendorRecoveryCaseService } from './components/vendor-recovery/shared-vendor-recovery-case.service';
import { WorkOrderAndLineItemsService } from './common/services/customer-recovery/work-order-and-line-items.service';
import { SharedDataService } from './shared-data-service';
import { environment } from '../environments/environment';
import { ApplicationConfig } from '@angular/core';
import { RecoveryCaseService } from './common/services/recovery/recovery.service';
import { CaseDocumentService } from './common/services/case-document/case-document.service';
import { ContainersService } from './common/services/container/containers.service';
import { BlobsService } from './common/services/email/blobs.service';
import { EmailService } from './common/services/email/email.service';
import { ContainerProtectService } from './common/services/container-protect/container-protect.service';
import { EmailTemplateService } from './common/services/email/email-template.service';
import { UserPreferencesService } from './common/services/user-preferences/user-preferences.service';
import { EmailTemplatePlaceholderService } from './email-template-placeholder.service';
import { apiVersionInterceptor } from './common/interceptors/api-version.interceptor';
import { DcrpAuthorizationService } from './common/services/authorization/dcrp-authorization.service';
import { AuthService } from './common/services/authorization/auth.service';
import { UserService } from './common/services/user/user.service';
import { idTokenInterceptor } from './common/interceptors/id-token.interceptor';
import { CaseAndWorkOrderService } from './common/services/recovery/caseAndWorkOrder.service';
import { CaseDamageDetailsService } from './common/services/recovery/caseDamageDetails.service';
import { RecoveryInspectionService } from './common/services/recovery/inspection.service';
import { RecoveryCaseDocumentService } from './common/services/recovery/caseDocument.service';
import { NotificationService } from './common/services/recovery/notification.service';
import { LiabilityLetterService } from './common/services/recovery/liabilityLetter.service';
import { LiabilityPartyService } from './common/services/recovery/liabilityParty.service';
import { InvoiceDetailService } from './common/services/recovery/invoiceDetail.service';
import { BookingService } from './common/services/recovery/booking.service';
import { WorkOrderLineItemDetailService } from './common/services/recovery/workOrderLineItemDetail.service';
import { BlobService } from './common/services/recovery/blob.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([
        error403Interceptor,
        authenticationInterceptor,
        apiVersionInterceptor,
        idTokenInterceptor,
      ])
    ),
    { provide: SIDE_NAV_PROVIDER, useClass: SideNavMenuService },
    { provide: HEADER_MENU_PROVIDER, useClass: HeaderMenuService },
    CookieService,
    ModalNotificationService,
    ToasterService,
    EmailModalService,
    ApplicationConfigService,
    CommonService,
    CustomerRecoveryClaimService,
    CaseService,
    MaterialCodeService,
    SharedCustomerRecoveryCaseService,
    SharedVendorRecoveryCaseService,
    WorkOrderAndLineItemsService,
    SharedDataService,
    SharedRecoveryCaseService,
    HelpAndFaqService,
    RecoveryCaseService,
    CaseDocumentService,
    ContainersService,
    ContainerProtectService,
    BlobsService,
    EmailService,
    EmailTemplateService,
    UserPreferencesService,
    EmailTemplatePlaceholderService,
    DcrpAuthorizationService,
    AuthService,
    UserService,
    CaseAndWorkOrderService,
    CaseDamageDetailsService,
    RecoveryInspectionService,
    RecoveryCaseDocumentService,
    NotificationService,
    LiabilityLetterService,
    LiabilityPartyService,
    InvoiceDetailService,
    BookingService,
    WorkOrderLineItemDetailService,
    BlobService,
    {
      provide: AUTHENTICATION_CONFIG,
      useValue: {
        authority: environment.authority,
        clientId: environment.clientId,
        postLoginRedirectUri: environment.postLoginRedirectUri,
        storage: { type: 'localStorage' },
        scopes: ['openid', 'email', 'profile'],
        consumerKey: environment.consumer_key,
        inactivityLogoutSeconds: environment.inactivityLogoutSeconds,
        refreshTokenBeforeSeconds: environment.refreshTokenBeforeSeconds,
      } as AuthenticationConfig,
    },
    {
      provide: ERROR_PAGE_CONFIG,
      useValue: {
        raiseAnIncidentURL:
          'https://maersk.service-now.com/end_user_portal?id=report_issue&sys_id=3ee96e21fbe95650b643f197beefdcd0',
      } as IErrorPageConfig,
    },
  ],
};
