import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { CommonService } from './common/services/customer-recovery/common.service';
import { CountryDto } from './common/models/countryDto';
import { CarrierCodeDto } from './common/models/carrierCodeDto';
import { ApplicationConfigService } from './common/services/common/applicationConfig.service';
import { VendorImageEmailExclusionCountryDto } from './common/models/vendorImageEmailExclusionCountryDto';
import { ConfigDto } from './common/models/configDto';
import { CollectionOfficeDtoIEnumerableResponse } from './common/models/collectionOfficeDtoIEnumerableResponse';
import { CollectionOfficeDto } from './common/models/collectionOfficeDto';
import { Region } from './common/models/region';
import { RegionIEnumerableResponse } from './common/models/regionIEnumerableResponse';
import { ShopResponse } from './common/models/shopResponse';
import { GeoSiteDtoIEnumerableResponse } from './common/models/geoSiteDtoIEnumerableResponse';
import {
  AuthenticationService,
  DropDownOption,
  TemplateModel,
} from '@maersk-global/angular-shared-library';
import { MaterialCodeService } from './common/services/common/materialCode.service';
import { MaterialCodeDto } from './common/models/materialCodeDto';
import { MaterialCodeDtoIEnumerableResponse } from './common/models/materialCodeDtoIEnumerableResponse';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private apiVersion: string = '1.0';

  constructor(
    private _commonService: CommonService,
    private _applicationConfigService: ApplicationConfigService,
    private _materialCodeService: MaterialCodeService
  ) {}

  countries$: Observable<CountryDto[]> = this._commonService
    .commonCountriesGet(this.apiVersion)
    .pipe(shareReplay(1));

  carrierCodes$: Observable<CarrierCodeDto[]> = this._commonService
    .commonCarrierCodesGet(this.apiVersion)
    .pipe(shareReplay(1));

  countryClusters$: Observable<CountryDto[]> = this._commonService
    .commonCountryClustersGet(this.apiVersion)
    .pipe(shareReplay(1));

  regions$: Observable<Region[] | undefined> = this._commonService
    .commonRegionsGet(this.apiVersion)
    .pipe(
      map((response: RegionIEnumerableResponse) => {
        if (!response || !response.isSuccess || !response.data)
          return undefined;
        return response.data;
      }),
      shareReplay(1)
    );

  currencyAndExchangeRates$: Observable<CountryDto[]> = this._commonService
    .commonCurrencyAndExchangeRatesGet(this.apiVersion)
    .pipe(shareReplay(1));

  commonCollectionOffices$: Observable<CollectionOfficeDto[] | undefined> =
    this._commonService.commonCollectionOfficeGet().pipe(
      map((response: CollectionOfficeDtoIEnumerableResponse) => {
        if (!response || !response.isSuccess || !response.data)
          return undefined;
        return response.data;
      }),
      shareReplay(1)
    );

  materialCodes$: Observable<MaterialCodeDto[]> = this._materialCodeService
    .materialCodesGet(true)
    .pipe(
      map((response: MaterialCodeDtoIEnumerableResponse) => {
        if (!response || !response.isSuccess || !response.data) return [];
        return response.data;
      }),
      shareReplay(1)
    );

  materialCodesAsDropdownOptions$: Observable<DropDownOption[]> =
    this.materialCodes$.pipe(
      map((codes) => {
        return codes.map((code) => ({
          value: code.materialCodeNumber,
          label: `${code.materialCodeNumber}-${code.materialDescription}`,
        }));
      })
    );

  shopCodes$: Observable<Array<ShopResponse> | undefined> = this._commonService
    .commonShopCodesGet(this.apiVersion)
    .pipe(
      map((shops) => {
        return shops.data ?? [];
      }),
      shareReplay(1)
    );

  vendorEmailExclusionCountries$: Observable<
    Array<VendorImageEmailExclusionCountryDto> | undefined
  > = this._applicationConfigService
    .applicationConfigsExcludedVendorEmailsGet(this.apiVersion)
    .pipe(
      map((countriesExcluded) => countriesExcluded.data),
      shareReplay(1)
    );

  appConfig$: Observable<Array<ConfigDto> | undefined> =
    this._applicationConfigService.applicationConfigsGet(this.apiVersion).pipe(
      map((response) => response.data),
      shareReplay(1)
    );

  /**
   * This callback method will be executed when we search the geolocation type ahead.
   * @param value value to be searched
   * @returns
   */
  onGeoLocationTypeAheadSearched(value: string): Observable<DropDownOption[]> {
    return this._commonService
      .commonGeographySitesGeoSiteCodePatternGet(
        value as string,
        this.apiVersion
      )
      .pipe(
        map((response: GeoSiteDtoIEnumerableResponse | undefined) => {
          // Return an empty array if there's no response or no assigned users
          if (!response || !response.data) return [];
          // Map the response to dropdown options
          const geographies = response.data.map((site) => ({
            value: site.siteCode,
            label: site.siteCode,
            sublabel: site.siteName,
          }));

          return geographies;
        }),
        catchError(() => of([]))
      );
  }

  deepClone(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.deepClone(item));
    }

    const clonedObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = this.deepClone(obj[key]);
      }
    }
    return clonedObj;
  }

  getUTCDateAndTime(date: Date | undefined): string {
    if (!date) return '--:--:-- --:--:--';

    const d = new Date(date);
    const year = d.getUTCFullYear().toString();
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
    const day = d.getUTCDate().toString().padStart(2, '0');
    const hours = d.getUTCHours().toString().padStart(2, '0');
    const minutes = d.getUTCMinutes().toString().padStart(2, '0');
    const seconds = d.getUTCSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  convertDateTimeToUTCDateTime(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }

  getUTCDateAfterAddingDays(date: Date | undefined, daysToAdd: number): string {
    if (!date) return '--:--:--';

    const d = new Date(date);
    let daysAdded = 0;

    while (daysAdded < daysToAdd) {
      d.setUTCDate(d.getUTCDate() + 1);
      // Check if the new date is a weekend
      const dayOfWeek = d.getUTCDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // 0 = Sunday, 6 = Saturday
        daysAdded++;
      }
    }

    const year = d.getUTCFullYear().toString();
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
    const day = d.getUTCDate().toString().padStart(2, '0');

    return `${day}-${month}-${year}`;
  }

  convertSecondsToDayHourMinSec(seconds: number) {
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${d > 0 ? `${d}d` : '00d'} : ${h > 0 ? `${h}h` : '00h'} : ${m > 0 ? `${m}m` : '00m'} : ${s > 0 ? `${s}s` : '00s'}`;
  }

  /**
   * Changes the time component as per input parameters
   * @param date Input date
   * @param hours Hours to be set
   * @param minutes Minutes to be set
   * @param seconds Seconds to be set
   * @param milliSeconds Milliseconds to be set
   * @returns Updated date with requested time
   */
  changeUtcTime(
    date: Date,
    hours: number = 0,
    minutes: number = 0,
    seconds: number = 0,
    milliSeconds: number = 0
  ): Date {
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);
    date.setUTCMilliseconds(milliSeconds);
    return date;
  }

  /**
   * Returns UTC date for DD-MM-YYYY format
   * @param date Date string in DD-MM-YYYY format
   * @param maxDate Max date allowed. If converted date is greater than max date, then conversion is marked as failed.
   * @returns Date in UTC timezone
   */
  getUtcDateFromDDMMYYYY(date: string, maxDate = new Date()): Date | undefined {
    try {
      //Validating length
      if (!date || date.length != 10) return;

      //Validating against Regex
      const dateFormat = new RegExp(
        '(^0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[0-2])[-/]([0-9]{4})$'
      );
      if (!dateFormat.test(date)) {
        return;
      }
      const convertedDate = new Date(
        date.substring(6, 10) +
          '-' +
          date.substring(3, 5) +
          '-' +
          date.substring(0, 2)
      );

      return convertedDate;
    } catch (error) {
      return;
    }
  }

  /** Downloads blob content as file by specified filename
   * @param blob - Blob content to be downloaded
   * @param fileName - Name of the file to be downloaded
   * @returns True if download is successful, false otherwise
   */
  downloadFile(blob: Blob, fileName: string): boolean {
    try {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Validates input date and converts to milliseconds
   * @param date - Date which needs to be converted to milliseconds
   * @returns Date in milliseconds, 0 if date is null or undefined
   */
  getTimeInMilliseconds(date: Date | undefined) {
    return date ? new Date(date).getTime() : 0;
  }

  convertDDMMYYYYIntoYYYYMMDD(dt: string) {
    // Split the input date by "-"
    const [day, month, year] = dt.split('-');
    // Return the date in "yyyy-mm-dd" format
    return `${year}-${month}-${day}`;
  }

  compareDatesIgnoringTime(date1: Date | null, date2: Date | null) {
    // Convert both inputs to Date objects (if they aren't already)
    if (!date1 && !date2) return true;
    else if (!date1 && date2) return false;
    else if (date1 && !date2) return false;

    const d1 = new Date(date1!);
    const d2 = new Date(date2!);

    // Compare only the date parts (ignoring time)
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  /**
   * Formats input number to decimal value with two digits after decimal point
   * @param value Number to be formatted
   * @returns Formatted decimal value
   */
  formatDecimal(value: number | undefined): number {
    if (!value) return 0;
    return Math.round(value * 100) / 100;
  }

  validateDamageLineItem(items: TemplateModel[]): boolean {
    let valid = false;
    items?.forEach((i: TemplateModel) => {
      //if data is not entered or if line item is empty
      if (!i.hidden && i.value) {
        valid = true;
      }
    });
    return valid;
  }

  /**
   * Sorts array based on createdTimestamp field
   * @param inputArray Input array
   * @param ascOrder If array needs to be sorted in ascending order or not
   * @returns Sorted array
   */
  sortArrayByCreatedTimestamp(
    inputArray: any[] | undefined,
    ascOrder: boolean = true
  ) {
    return inputArray
      ? inputArray.sort((row1, row2) => {
          const dateA = row1.createdTimestamp
            ? new Date(row1.createdTimestamp).getTime()
            : 0;
          const dateB = row2.createdTimestamp
            ? new Date(row2.createdTimestamp).getTime()
            : 0;
          return ascOrder ? dateA - dateB : dateB - dateA;
        })
      : undefined;
  }
}
