<lib-panel [hideHeader]="true">
  <content>
    <div class="invoice-letter" *ngIf="recoveryData$ | async">
      <div class="invoice" [formGroup]="invoiceForm">
        <span>FACT Code - {{ factCode$ | async }}</span>
        <div style="width: 760px">
          <mc-textarea
            ngDefaultControl
            label="Remarks to be added in invoice (optional)"
            [rows]="5"
            formControlName="remarks"
            name="remarks"></mc-textarea>
        </div>
        <div
          style="width: 360px"
          *ngIf="collectionOfficeDropDown$ | async as offices">
          <mc-typeahead
            [invalid]="
              invoiceForm.get('collectionOffice')?.touched &&
              !collectionOfficeCode
            "
            ngDefaultControl
            showlistonfocus
            optionsheight="350px"
            formControlName="collectionOffice"
            label="Collection Office"
            placeholder="Type and select an option"
            (input)="onCollectionOfficeTextChange($event)"
            (optionselected)="onDrpDwnCollectionOfficeSelected($event)">
            @for (selectedOption of offices; track selectedOption) {
              <mc-option [value]="selectedOption.value">
                {{ selectedOption.label }}
              </mc-option>
            }
          </mc-typeahead>
          <mc-error
            *ngIf="
              invoiceForm.get('collectionOffice')?.touched &&
              !collectionOfficeCode
            "
            invalid
            errormessage="Select valid collection office"></mc-error>
        </div>
      </div>
    </div>
  </content>
</lib-panel>
