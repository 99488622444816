import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
  SectionSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { combineLatest, tap } from 'rxjs';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { ContainerMoveDto } from '../../../common/models/containerMoveDto';
import { SharedDataService } from '../../../shared-data-service';
import { RecoveryCase } from '../../../common/models/recoveryCase';

const WORK_ORDER_NUMBER = 'workOrderNumber';
const CONTAINER_NUMBER = 'containerNumber';
const CONTAINER_SIZE = 'containerSize';
const CONTAINER_MANUFACTURING_YEAR = 'containerManufacturingYear';
const CONTAINER_PROD_YEAR = 'containerProdYear';
const EQUIPMENT_SUB_TYPE = 'equipmentSubType';

@Component({
  selector: 'container-info',
  standalone: true,
  imports: [
    CommonModule,
    LibFormComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './container-info.component.html',
  styleUrl: './container-info.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContainerInfoComponent {
  @Input() item!: TemplateModel;
  items: TemplateModel[] = [];
  caseDetail!: RecoveryCase | undefined;
  containerDetails: ContainerMoveDto | null | undefined;

  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  customerRecoveryData$ = combineLatest([
    this._sharedRecoveryCaseService.containerDetails$,
    this._sharedRecoveryCaseService.recoveryCaseData$,
  ]).pipe(
    tap(([containerDetails, recoveryData]) => {
      this.caseDetail = recoveryData;
      this.containerDetails = containerDetails;
      this.items = this.item.items as TemplateModel[];
      this.bindContainerData();
    })
  );

  bindContainerData() {
    this.items.forEach((item: TemplateModel) => {
      const customerRecoveryKeyValue = this.caseDetail as unknown as {
        [key: string]: unknown;
      };

      if (item.name == CONTAINER_SIZE) {
        item.value =
          customerRecoveryKeyValue[item.name as string] ??
          this.containerDetails?.containerSize ??
          '-';
      } else if (item.name == CONTAINER_MANUFACTURING_YEAR) {
        item.value =
          customerRecoveryKeyValue[item.name as string] ??
          this.containerDetails?.containerManufacturingYear ??
          '-';
      } else if (item.name == CONTAINER_PROD_YEAR) {
        item.value =
          customerRecoveryKeyValue[item.name as string] ??
          this.containerDetails?.containerManufacturingYear ??
          '-';
      } else if (item.name == EQUIPMENT_SUB_TYPE) {
        item.value =
          this.containerDetails?.containerType?.containerTypeCode ??
          customerRecoveryKeyValue[item.name as string] ??
          '-';
      } else if (
        item.name == WORK_ORDER_NUMBER ||
        item.name == CONTAINER_NUMBER
      ) {
        item.value =
          parseInt(customerRecoveryKeyValue[item.name as string] as string) == 0
            ? '-'
            : customerRecoveryKeyValue[item.name as string];
      } else {
        item.value = customerRecoveryKeyValue[item.name as string];
      }

      if (!item.value) item.value = '-';
    });
    this.items = this._sharedDataService.deepClone(this.items);
  }
}
