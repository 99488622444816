import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  forkJoin,
  interval,
  lastValueFrom,
  map,
  of,
  scan,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CaseTypeEnum } from '../../common/constants/temporary-constant';
import { CaseDamageDetailDto } from '../../common/models/caseDamageDetailDto';
import { ContainerMoveDto } from '../../common/models/containerMoveDto';
import { CustomerRecoveryCaseDto } from '../../common/models/customerRecoveryCaseDto';
import { FormValidation } from '../../common/models/formValidation';
import { WorkFlowStage } from '../../common/models/workflowStage';
import { ISharedRecoveryCaseService } from '../../shared-recovery-case-service';
import { TemplateModel } from '@maersk-global/angular-shared-library';
import { RecoveryCaseService } from '../../common/services/recovery/recovery.service';
import { RecoveryRequest } from '../../common/models/recovery-request';
import { RecoveryCaseStatus } from '../../common/models/recoveryCaseStatus';
import { VendorRecoveryCase } from '../../common/models/vendorRecoveryCase';
import { WorkOrderLineItemDto } from '../../common/models/workOrderLineItemDto';
import { CaseDamageDetailsService } from '../../common/services/recovery/caseDamageDetails.service';
import { CaseDamageDetails } from '../../common/models/caseDamageDetails';
import { SharedDataService } from '../../shared-data-service';
import { DcrpAuthorizationService } from '../../common/services/authorization/dcrp-authorization.service';
import { ClaimReferenceDocumentDto } from '../../common/models/claimReferenceDocumentDto';
import { Inspection } from '../../common/models/inspection';
import { InspectionDetailsDTO } from '../../common/models/inspectionDetailsDTO';
import { RecoveryCaseDocumentService } from '../../common/services/recovery/caseDocument.service';
import { RecoveryInspectionService } from '../../common/services/recovery/inspection.service';
import { environment } from '../../../environments/environment';
import { CaseActivityLogsDtoListResponse } from '../../common/models/caseActivityLogsDtoListResponse';
import { RecoveryCaseType } from '../../common/models/recoveryCaseType';
import { LiabilityLetterService } from '../../common/services/recovery/liabilityLetter.service';
import { LiabilityLetter } from '../../common/models/liabilityLetter';
import { LiabilityParty } from '../../common/models/liabilityParty';
import { LiabilityPartyService } from '../../common/services/recovery/liabilityParty.service';
import { LiabilityLetterStatus } from '../../common/models/liabilityLetterStatus';
import { UpdateLiabilityLetterStatusVendor } from '../../common/models/updateLiabilityLetterStatusVendor';
import { NotificationService } from '../../common/services/recovery/notification.service';
import { SendMailRequest } from '../../common/models/sendMailRequest';
import { SendGridEmail } from '../../common/models/email';
import { InvoiceDetailService } from '../../common/services/recovery/invoiceDetail.service';
import { InvoiceDetail } from '../../common/models/invoiceDetail';
import { UpdateInvoice } from '../../common/models/updateInvoice';
import { CreateInvoice } from '../../common/models/createInvoice';
import { InvoicingStatus } from '../../common/models/invoicingStatus';
import { CustomerInfoDto } from '../../common/models/customerInfoDto';
import { WorkOrderLineItemDetail } from '../../common/models/workOrderLineItemDetail';
import { gcssBookingInfo } from '../../common/models/gcssBookingInfo';
import { SaveLiabilityLetter } from '../../common/models/saveLiabilityLetter';
import { UploadEmailRequest } from '../../common/models/uploadEmailRequest';

@Injectable({
  providedIn: 'root',
})
export class SharedVendorRecoveryCaseService
  implements ISharedRecoveryCaseService
{
  private apiVersion: string = '1.0';
  caseType: CaseTypeEnum | undefined;

  constructor(
    private _route: ActivatedRoute,
    private _recoveryClaimService: RecoveryCaseService,
    private _damageService: CaseDamageDetailsService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService,
    private _recoveryCaseDocumentService: RecoveryCaseDocumentService,
    private _recoveryInspectionService: RecoveryInspectionService,
    private _liabilityLetterService: LiabilityLetterService,
    private _liabilityPartyService: LiabilityPartyService,
    private _notificationService: NotificationService,
    private _invoiceDetailService: InvoiceDetailService,
    private _liabilityService: LiabilityLetterService
  ) {}

  private shouldShowLiabilityDetailsSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private tabIndex$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private reloadDamageDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private workOrderDateChange$$: BehaviorSubject<Date | null | undefined> =
    new BehaviorSubject<Date | null | undefined>(undefined);

  private currentStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  private reloadVendorRecoveryData$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private eirImageLastCheckedOn$$: BehaviorSubject<Date | undefined> =
    new BehaviorSubject<Date | undefined>(undefined);

  private formValidity$$: BehaviorSubject<FormValidation | null> =
    new BehaviorSubject<FormValidation | null>(null);

  private containerDetails$$: BehaviorSubject<
    ContainerMoveDto | null | undefined
  > = new BehaviorSubject<ContainerMoveDto | null | undefined>(undefined);

  private recoverableWorkOrderSelectedLineItems$$: BehaviorSubject<
    WorkOrderLineItemDetail[]
  > = new BehaviorSubject<WorkOrderLineItemDetail[]>([]);

  private workOrderLineItems$$: BehaviorSubject<WorkOrderLineItemDto[]> =
    new BehaviorSubject<WorkOrderLineItemDto[]>([]);

  private overviewDetails$$: BehaviorSubject<TemplateModel[]> =
    new BehaviorSubject<TemplateModel[]>([]);

  private reloadLiabilityLetters$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private reloadLiabilityPartyDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private workFlowStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  workFlowStageId$: Observable<number> = this.workFlowStageId$$.asObservable();

  private reloadInvoices$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private shouldShowIssueInvoiceScreenSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private customerDataSubject$$: BehaviorSubject<CustomerInfoDto> =
    new BehaviorSubject<CustomerInfoDto>({});

  vendorRecoveryCustomerData$: Observable<CustomerInfoDto> =
    this.customerDataSubject$$.asObservable().pipe(shareReplay(1));

  vendorRecoveryCaseData$: Observable<VendorRecoveryCase | undefined> =
    combineLatest([
      this._route.queryParamMap,
      this.reloadVendorRecoveryData$$.asObservable(),
    ]).pipe(
      switchMap(([params]) => {
        const caseId = params.get('caseId');
        if (!caseId) return of(undefined);
        const request = {
          caseId: parseInt(caseId ?? ''),
          equipmentNumber: params.get('equipmentNumber') as string,
          recoveryCaseType: 'Vendor',
        } as RecoveryRequest;
        return this._recoveryClaimService
          .recoveryCasesGet(request)
          .pipe(map((response) => response.data?.at(0)));
      }),
      tap((recoveryData) => {
        const workFLowStageId = this.getCaseStatusIDByName(
          recoveryData?.caseStatus ?? 'New'
        );
        this.workFlowStageId$$.next(workFLowStageId); // TODO: use workflowStage_id from db
        this.resetCaseContext();
        if (!recoveryData) return;
      }),
      shareReplay(1)
    );

  currentStageId$: Observable<number> = this.currentStageId$$.asObservable();

  workOrderDate$: Observable<Date | null | undefined> =
    this.workOrderDateChange$$.asObservable();

  vendorRecoveryEirImageLastFetchDate$: Observable<Date | undefined> =
    this.eirImageLastCheckedOn$$.asObservable();

  vendorRecoveryContainerMovesDetails$: Observable<
    ContainerMoveDto | null | undefined
  > = this.containerDetails$$.asObservable();

  currentVendorRecoveryTabIndex$: Observable<number | 0> =
    this.tabIndex$$.asObservable();

  vendorRecoverableWorkOrderLineItem$: Observable<WorkOrderLineItemDetail[]> =
    this.recoverableWorkOrderSelectedLineItems$$.asObservable();

  vendorWorkOrderLineItems$: Observable<WorkOrderLineItemDto[]> =
    this.workOrderLineItems$$.asObservable();

  overviewDetails$: Observable<TemplateModel[]> =
    this.overviewDetails$$.asObservable();

  vendorRecoveryLiabilityLetters$: Observable<
    Array<LiabilityLetter> | undefined
  > = combineLatest([
    this.vendorRecoveryCaseData$,
    this.reloadLiabilityLetters$$.asObservable(),
  ]).pipe(
    switchMap(([vendorRecovery]) => {
      if (!vendorRecovery || !vendorRecovery.id) return of(undefined);
      return this._liabilityLetterService
        .liabilityLetterGetAllLiabilityLettersRecoveryCaseIdGet(
          vendorRecovery.id,
          this.apiVersion
        )
        .pipe(map((response) => response));
    }),
    shareReplay(1)
  );

  vendorRecoveryInvoices$: Observable<Array<InvoiceDetail> | undefined> =
    combineLatest([
      this.vendorRecoveryCaseData$,
      this.reloadInvoices$$.asObservable(),
    ]).pipe(
      switchMap(([vendorRecoveryCase]) => {
        if (!vendorRecoveryCase || !vendorRecoveryCase.id) return of(undefined);
        return this._invoiceDetailService
          .invoiceDetailsSearchGet(this.apiVersion, vendorRecoveryCase.id)
          .pipe(
            map((response) => response.data),
            catchError(() => of(undefined))
          );
      })
      // TODO: need to uncomment it and test properly.
      //shareReplay(1)
    );

  shouldShowVendorRecoveryIssueInvoiceScreen$: Observable<boolean> =
    combineLatest([
      this.shouldShowIssueInvoiceScreenSubject$$.asObservable(),
      this.vendorRecoveryInvoices$,
      this._sharedDataService.countries$,
      this.vendorRecoveryCaseData$,
    ]).pipe(
      map(
        ([
          shouldShowIssueInvoiceScreen,
          invoices,
          countries,
          customerRecoveryCase,
        ]) =>
          (shouldShowIssueInvoiceScreen ||
            !invoices ||
            invoices.length === 0) &&
          !!countries.find(
            (country) =>
              country.code === customerRecoveryCase?.responsibleCountryCode &&
              country.isS4HanaMigrated
          )
      ),
      shareReplay(1)
    );

  private invoiceCreationPermissionInitial$: Observable<InvoicingStatus> =
    combineLatest([
      this.vendorRecoveryCaseData$,
      this.vendorRecoveryLiabilityLetters$,
    ]).pipe(
      switchMap(([vendorRecoveryCase, letters]) => {
        if (
          !letters ||
          letters.length === 0 ||
          !vendorRecoveryCase?.id ||
          !letters.find(
            (letter) =>
              letter.liabilityLetterStatus === 'ACCEPTED' ||
              letter.liabilityLetterStatus === 'NO_RESPONSE'
          )
        )
          return of({
            totalSecondsRemainingToInvoice: 0,
            isReadyToInvoice: false,
          });
        // TODO: hardcoded the time duration for ready to invoice for now.
        // We have to check whether to call API for this same as customer recovery or not.
        return of({
          totalSecondsRemainingToInvoice: 0,
          isReadyToInvoice: true,
        });
      }),
      shareReplay(1)
    );

  vendorRecoveryInvoiceCreationPermission$: Observable<
    InvoicingStatus & { displayMessage: string }
  > = combineLatest([
    this.invoiceCreationPermissionInitial$,
    this.invoiceCreationPermissionInitial$.pipe(
      switchMap((caseInvoiceStatus) => {
        return interval(1000).pipe(
          take((caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1) + 1)
        );
      })
    ),
  ]).pipe(
    map(([caseInvoiceStatus, seconds]) => {
      const displayMessage =
        this._sharedDataService.convertSecondsToDayHourMinSec(
          (caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1) - seconds
        );
      if (
        !caseInvoiceStatus.isReadyToInvoice &&
        seconds < (caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1)
      )
        return {
          totalSecondsRemainingToInvoice:
            caseInvoiceStatus.totalSecondsRemainingToInvoice,
          isReadyToInvoice: caseInvoiceStatus.isReadyToInvoice,
          displayMessage: displayMessage,
        };
      else
        return {
          totalSecondsRemainingToInvoice: 0,
          isReadyToInvoice:
            caseInvoiceStatus.isReadyToInvoice ||
            caseInvoiceStatus.totalSecondsRemainingToInvoice !== 0,
          displayMessage: displayMessage,
        };
    })
  );

  enableCloseBtn$: Observable<boolean> = combineLatest([
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
    this.vendorRecoveryInvoices$,
  ]).pipe(
    map(
      ([recoveryData, stageId, invoices]) =>
        !(
          recoveryData?.caseStatus == RecoveryCaseStatus.Closed ||
          recoveryData?.caseStatus == RecoveryCaseStatus.AutoClosed ||
          stageId == 5 ||
          (invoices && invoices.length > 0)
        )
    )
  );

  reopenCase$: Observable<boolean> = this.vendorRecoveryCaseData$.pipe(
    map((recoveryData) => {
      return (
        recoveryData?.caseStatus == RecoveryCaseStatus.Closed ||
        recoveryData?.caseStatus == RecoveryCaseStatus.AutoClosed
      );
    })
  );

  disableForm$: Observable<boolean> = combineLatest([
    this._dcrpAuthorizationService.isAuthorizedFor('recovery-update'),
    this.vendorRecoveryLiabilityLetters$,
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
  ]).pipe(
    map(([isAuthorizedForRecoveryUpdate, letters, recoveryData, stageId]) => {
      if (
        !isAuthorizedForRecoveryUpdate ||
        recoveryData?.caseStatus == RecoveryCaseStatus.Closed ||
        recoveryData?.caseStatus == RecoveryCaseStatus.AutoClosed
      )
        return true;
      if (stageId == 1 && letters && letters.length > 0) {
        letters?.sort(
          (letter1, letter2) =>
            new Date(letter2.createdTimestamp ?? '').getTime() -
            new Date(letter1.createdTimestamp ?? '').getTime()
        );

        return (
          letters[0].liabilityLetterStatus === LiabilityLetterStatus.REJECTED
        );
      }
      return false;
    })
  );

  /**
   * We are calculating the accumulated state of the form validity. For e.g. If out of 2 forms 1 is valid and 1 is invalid,
   * the accumulated state would be invalid.
   */
  private collectiveFormValidation$ = this.formValidity$$.asObservable().pipe(
    withLatestFrom(this.currentStageId$, this.vendorRecoveryCaseData$),
    map(([formValidation, currentStageId, vendorRecoveryData]) =>
      formValidation
        ? {
            ...formValidation,
            stage: currentStageId,
            caseId: vendorRecoveryData?.id,
          }
        : null
    ),
    scan((formValidations, currentState) => {
      if (!currentState) return [];
      const matchingState = formValidations.find(
        (state) =>
          state.component === currentState.component &&
          state.stage === currentState.stage &&
          state.caseId === currentState.caseId
      );
      if (matchingState) matchingState.state = currentState.state;
      else formValidations.push(currentState);
      return formValidations;
    }, [] as FormValidation[])
  );

  private formValidityForCurrentStage$ = combineLatest([
    this.currentStageId$,
    this.collectiveFormValidation$,
    this.vendorRecoveryCaseData$,
  ]).pipe(
    map(([currentStageId, formValidations, vendorRecoveryData]) =>
      formValidations
        .filter(
          (validations) =>
            validations.stage === currentStageId &&
            validations.caseId === vendorRecoveryData?.id
        )
        .every((validation) => validation.state)
    )
  );

  enableNextBtn$: Observable<boolean> = combineLatest([
    this._dcrpAuthorizationService.isAuthorizedFor('recovery-update'),
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
    this.formValidityForCurrentStage$,
  ]).pipe(
    map(
      ([isAuthorizedForRecoveryUpdate, recoveryData, stageId, formValid]) =>
        formValid &&
        isAuthorizedForRecoveryUpdate &&
        recoveryData?.caseStatus != RecoveryCaseStatus.Closed &&
        recoveryData?.caseStatus != RecoveryCaseStatus.AutoClosed &&
        stageId != 4
    )
  );

  hideNextBtn$: Observable<boolean> = combineLatest([
    this._dcrpAuthorizationService.isAuthorizedFor('recovery-update'),
    this.currentStageId$,
    this.shouldShowVendorRecoveryIssueInvoiceScreen$,
  ]).pipe(
    map(([isAuthorizedForRecoveryUpdate, stageId, showInvoiceScreen]) => {
      let hiddenFlag: boolean = false;
      if (
        (stageId === 3 && !showInvoiceScreen) ||
        !isAuthorizedForRecoveryUpdate
      )
        hiddenFlag = true;
      return hiddenFlag;
    })
  );

  VendorRecoveryBookingCargoDetails$: Observable<gcssBookingInfo | undefined> =
    this.vendorRecoveryCaseData$.pipe(
      map((response) => {
        if (!response || !response.id) return undefined;
        return {
          invoiceNo: response.bolNumber,
          bookingNumber: response.bookingNumber,
        } as gcssBookingInfo;
      })
    );

  async saveDamageDetails(
    damages: TemplateModel[],
    caseId: number
  ): Promise<boolean> {
    const damageDetailRequest: CaseDamageDetails[] = [];
    const currentItems = damages;

    currentItems?.forEach((damage: TemplateModel) => {
      if (
        !damage.hidden &&
        damage.items &&
        this._sharedDataService.validateDamageLineItem(damage.items)
      )
        damageDetailRequest.push({
          damageDescription: damage.items?.filter(
            (i) => i.name == 'description'
          )[0].value,
          damageAmountInUsd: damage.items?.filter((i) => i.name == 'amount')[0]
            .value,
          materialCodeId: damage.items?.filter(
            (i) => i.name == 'materialCode'
          )[0].value,
          id: damage.items?.filter((i) => i.name == 'id')[0].value,
          caseId: caseId,
          damageAmountInCaseCurrency: damage.items?.filter(
            (i) => i.name == 'amountInCaseCurrency'
          )[0].value,
        });
    });

    const saveSuccess = await lastValueFrom(
      this._damageService
        .caseDamageDetailsDeleteAndInsertPut(damageDetailRequest)
        .pipe(map((response) => response.isSuccess ?? false))
    );
    if (saveSuccess) this.reloadDamageDetails();
    return saveSuccess;
  }

  onResendEmailClicked(mail: SendMailRequest): Observable<boolean> {
    if (!mail || !mail.liabilityLetter) return of(false);
    const emailSendGridSendRequest = {
      to: mail.to,
      cc: mail.cc,
      from: mail.from,
      body: mail.body,
      subject: mail.subject,
      emailAttachments: mail.emailAttachment,
    } as SendGridEmail;

    const emailAPISaveRequest = {
      recoveryCaseId: mail.liabilityLetter.recoveryCaseId,
      liablePartyName: mail.liabilityLetter.liabilityPartyName,
      liabilityAmount: mail.liabilityLetter.liabilityAmount,
      liabilityLetterCaseType: 'Vendor',
      liabilityLetterResponseReason: '',
      emailBlobPath: '',
      to: emailSendGridSendRequest.to,
      cc: emailSendGridSendRequest.cc,
      subject: emailSendGridSendRequest.subject,
      body: emailSendGridSendRequest.body,
      emailAttachments: emailSendGridSendRequest.emailAttachments,
    } as SaveLiabilityLetter;

    const mailUploadReq = {
      caseId: mail.liabilityLetter.recoveryCaseId,
      emailType: 'Vendor_Notification_Email',
      fileName: '',
      recoveryCaseType: 'Vendor',
      from: emailSendGridSendRequest.from,
      to: emailSendGridSendRequest.to,
      cc: emailSendGridSendRequest.cc,
      subject: emailSendGridSendRequest.subject,
      body: emailSendGridSendRequest.body,
      emailAttachments: emailSendGridSendRequest.emailAttachments,
    } as UploadEmailRequest;

    return this._notificationService
      .notificationUploadEmailPost(mailUploadReq)
      .pipe(
        switchMap((mailUploadURI) =>
          forkJoin({
            mailSend: this._notificationService
              .notificationSendEmailPost(
                emailSendGridSendRequest,
                this.apiVersion
              )
              .pipe(map((response) => response as number)),
            mailSave: this._liabilityService.liabilityLetterPost(
              { ...emailAPISaveRequest, emailBlobPath: mailUploadURI },
              this.apiVersion
            ),
          })
        ),
        map(() => true),
        tap(() => this.reloadLiabilityLetters()),
        catchError(() => of(false))
      );
  }

  async updateLiabilityLetter(
    selectedLetter: LiabilityLetter,
    liabilityLetterStatus: LiabilityLetterStatus
  ): Promise<boolean> {
    const recoveryDataCaseID =
      (await firstValueFrom(this.vendorRecoveryCaseData$))?.id ?? 0;
    const request = {
      liabilityLetterStatus: liabilityLetterStatus,
      liabilityLetterResponseReason:
        selectedLetter.liabilityLetterResponseReason,
      recoveryCaseId: recoveryDataCaseID,
    } as UpdateLiabilityLetterStatusVendor;

    await lastValueFrom(
      this._liabilityLetterService
        .liabilityLetterIdPut(request, selectedLetter.id ?? 0)
        .pipe(
          catchError((error) => {
            return of(false);
          })
        )
    );
    return true;
  }

  downloadLetter(emailBlobPath: string, caseNumber: string): Observable<Blob> {
    const fileName = emailBlobPath!
      .substring(emailBlobPath!.lastIndexOf('/') + 1)
      .split('?')[0];
    return this._notificationService.notificationDownloadEmailGet(
      emailBlobPath
    );
  }

  damageDetails$: Observable<CaseDamageDetailDto[] | undefined> = combineLatest(
    [this.vendorRecoveryCaseData$, this.reloadDamageDetails$$.asObservable()]
  ).pipe(
    switchMap(([vendorRecoveryData]) => {
      if (!vendorRecoveryData || !vendorRecoveryData.id) return of(undefined);
      return this._damageService
        .caseDamageDetailsByCaseIdCaseIdGet(vendorRecoveryData?.id ?? 0)
        .pipe(
          map((response) => {
            if (!response || !response.data) return [];
            return response.data.map(
              (site) =>
                ({
                  description: site.damageDescription,
                  id: site.id,
                  materialCode: site.materialCodeId,
                  amount: site.damageAmountInUsd,
                  amountInCaseCurrency: site.damageAmountInCaseCurrency,
                  caseId: site.caseId,
                }) as CaseDamageDetailDto
            );
          })
        );
    }),
    shareReplay(1)
  );

  shouldShowCustomerRecoveryLiabilityDetails$: Observable<boolean> =
    combineLatest([
      this.shouldShowLiabilityDetailsSubject$$.asObservable(),
      this.vendorRecoveryLiabilityLetters$,
    ]).pipe(
      map(
        ([shouldShowLiabilityDetails, liabilityLetters]) =>
          shouldShowLiabilityDetails ||
          !liabilityLetters ||
          liabilityLetters.length === 0
      ),
      shareReplay(1)
    );

  vendorRecoveryLiablePartyData$: Observable<LiabilityParty | undefined> =
    combineLatest([
      this.vendorRecoveryCaseData$,
      this.reloadLiabilityPartyDetails$$.asObservable(),
    ]).pipe(
      switchMap(([vendorRecoveryData]) => {
        if (!vendorRecoveryData?.id) return of(undefined);
        return this._liabilityPartyService
          .liabilityPartiesGet(vendorRecoveryData.id ?? 0, this.apiVersion)
          .pipe(
            map((response) => {
              if (!response || !response.data) return;
              return response?.data as LiabilityParty;
            })
          );
      }),
      shareReplay(1)
    );

  resetCaseContext() {
    this.currentStageId$$.next(1);
    this.updateWorkOrderLineItems([]);
    this.updateRecoverableWorkOrderSelectedLineItems([]);
    this.updateContainerDetails(undefined);
    this.updateWorkOrderDate(undefined);
  }

  updateFormValidationState(state: FormValidation) {
    this.formValidity$$.next(state);
  }

  reloadDamageDetails(): void {
    this.reloadDamageDetails$$.next(true);
  }

  updateLiabilityDetailsVisibility(value: boolean) {
    this.shouldShowLiabilityDetailsSubject$$.next(value);
  }

  updateEirImageLastFetchOn(date: Date | undefined) {
    this.eirImageLastCheckedOn$$.next(date);
  }

  reloadRecoveryCaseData(): void {
    this.reloadVendorRecoveryData$$.next(true);
  }

  updateCurrentStageId(stageId: number): void {
    this.currentStageId$$.next(stageId);
  }

  updateTabSelected(tabIndex: number) {
    this.tabIndex$$.next(tabIndex);
  }

  updateContainerDetails(
    containerDetails: ContainerMoveDto | null | undefined
  ) {
    this.containerDetails$$.next(containerDetails);
  }

  updateRecoverableWorkOrderSelectedLineItems(
    lineItems: WorkOrderLineItemDetail[]
  ) {
    this.recoverableWorkOrderSelectedLineItems$$.next(lineItems);
  }

  updateWorkOrderLineItems(lineItems: WorkOrderLineItemDto[]) {
    this.workOrderLineItems$$.next(lineItems);
  }

  updateCaseType(vendorRecoveryCase: CustomerRecoveryCaseDto) {
    if (vendorRecoveryCase.createdBy) {
      this.caseType =
        !vendorRecoveryCase.workOrderNumber &&
        !['sys', 'system'].includes(
          vendorRecoveryCase.createdBy.toLocaleLowerCase()
        )
          ? CaseTypeEnum.CaseWithOutWorkOrder
          : CaseTypeEnum.CaseWithWorkOrder;
    } else {
      this.caseType = CaseTypeEnum.CaseWithWorkOrder;
    }
  }

  getWorkFlowStageByIdForVendor(stageId: number): WorkFlowStage {
    let stageName = WorkFlowStage.New;
    switch (stageId) {
      case 1:
        stageName = WorkFlowStage.New;
        break;
      case 3:
        stageName = WorkFlowStage.Liability;
        break;
      case 6:
        stageName = WorkFlowStage.DebitNote;
        break;
      case 5:
        stageName = WorkFlowStage.Complete;
    }
    return stageName as WorkFlowStage;
  }

  getCaseStatusIDByName(status: string): number {
    let caseStatusId = 1;
    switch (status) {
      case RecoveryCaseStatus.New:
        caseStatusId = 1;
        break;
      case RecoveryCaseStatus.Liability:
        caseStatusId = 2;
        break;
      case RecoveryCaseStatus.Invoice:
        caseStatusId = 3;
        break;
      case RecoveryCaseStatus.Complete:
        caseStatusId = 4;
        break;
    }
    return caseStatusId as number;
  }

  getStageIdName(status: WorkFlowStage): number {
    let caseStatusId = 1;
    switch (status) {
      case WorkFlowStage.New:
        caseStatusId = 1;
        break;
      case WorkFlowStage.Liability:
        caseStatusId = 2;
        break;
      case WorkFlowStage.DebitNote:
        caseStatusId = 3;
        break;
      case WorkFlowStage.Complete:
        caseStatusId = 4;
        break;
    }
    return caseStatusId as number;
  }

  updateWorkOrderDate(date: Date | null | undefined) {
    this.workOrderDateChange$$.next(date);
  }

  caseDocumentGet(
    caseId: number
  ): Observable<ClaimReferenceDocumentDto | null> {
    return this._recoveryClaimService
      .recoveryCasesCaseIdAttachmentsGet(caseId, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.data) return null;
          return response.data;
        })
      );
  }

  caseDocumentPost(
    imagesAssignedToCases: ClaimReferenceDocumentDto
  ): Observable<ClaimReferenceDocumentDto | null> {
    return this._recoveryClaimService
      .recoveryCasesCaseIdAttachmentsPost(
        imagesAssignedToCases.caseId ?? 0,
        this.apiVersion,
        { claimReferenceDocumentDto: imagesAssignedToCases }
      )
      .pipe(
        map((response) => {
          if (!response || !response.data) return null;
          return response.data;
        })
      );
  }

  caseDocumentDelete(documentId: number): Observable<boolean> {
    return this._recoveryCaseDocumentService
      .caseDocumentsIdDelete(documentId, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.data) return false;
          return response.data;
        })
      );
  }

  inspectionsGet(
    _caseNumber: string,
    containerNumber: string | undefined,
    _incidentDate: Date | null,
    workOrderDate: Date | null
  ): Observable<Array<Inspection> | null> {
    let dateToFetchInspections = new Date();
    const toDatePlusVal = environment.searchIntervalForInspections;

    if (workOrderDate && workOrderDate.toString() != '0001-01-01T00:00:00') {
      dateToFetchInspections = new Date(workOrderDate);
    }

    const fromDate = new Date(dateToFetchInspections.getTime());
    fromDate.setUTCDate(fromDate.getUTCDate() - toDatePlusVal);

    const toDate = new Date(dateToFetchInspections.getTime());
    toDate.setUTCDate(toDate.getUTCDate() + toDatePlusVal);

    return this._recoveryInspectionService
      .inspectionsGet(containerNumber, fromDate, toDate, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.data) return null;
          return response.data;
        })
      );
  }

  saveImageAvailability(
    _inspectionInfo: InspectionDetailsDTO,
    _caseNumber: string
  ): Observable<boolean> {
    return of(true);
  }

  updateOverviewDetails(overview: TemplateModel[]) {
    this.overviewDetails$$.next(overview);
  }

  updateWorkFlowStgae(stageId: number) {
    this.workFlowStageId$$.next(stageId);
  }

  getActivityLogs(caseId: number): Observable<CaseActivityLogsDtoListResponse> {
    return this._recoveryClaimService.recoveryCasesCaseIdActivityLogsGet(
      caseId,
      RecoveryCaseType.Vendor,
      this.apiVersion
    );
  }

  reloadLiabilityPartyDetails() {
    this.reloadLiabilityPartyDetails$$.next(true);
  }

  reloadLiabilityLetters() {
    this.reloadLiabilityLetters$$.next(true);
  }

  reloadInvoices() {
    this.reloadInvoices$$.next(true);
  }

  updateIssueInvoiceVisibility(value: boolean) {
    this.shouldShowIssueInvoiceScreenSubject$$.next(value);
  }

  postInvoiceDetails(
    createInvoice: CreateInvoice,
    _userId: string
  ): Observable<boolean> {
    return this._invoiceDetailService
      .invoiceDetailsPost(createInvoice, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.data) return false;
          return response.data;
        })
      );
  }

  putInvoiceDetails(
    invoiceId: number,
    updateInvoice: UpdateInvoice,
    _userId: string
  ): Observable<boolean> {
    return this._invoiceDetailService
      .invoiceDetailsIdPut(invoiceId, updateInvoice, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.data) return false;
          return true;
        })
      );
  }

  //Extra functions added as required by interface
  updateCustomerData(data: CustomerInfoDto): void {
    this.customerDataSubject$$.next(data);
  }
}
