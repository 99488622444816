/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { WorkOrderLineItemDetail } from '../../models/workOrderLineItemDetail';

@Injectable()
export class WorkOrderLineItemDetailService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns IEnumerable of  WorkOrderLineItemDetail, on the basis of workOrderNumber.
   * Sample request:                    Get/by-work-order-number/{workOrderNumber}                Returns IEnumerable of WorkOrderLineItemDetail on the basis of workOrderNumber.    - 200 (OK): Response ot type IEnumerable of CaseStages  - 404 (Not found): Record not found.  - 400 (Bad Request): The request is invalid.  - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param workOrderNumber workOrderNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberGet(
    workOrderNumber: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<WorkOrderLineItemDetail>>;
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberGet(
    workOrderNumber: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<WorkOrderLineItemDetail>>>;
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberGet(
    workOrderNumber: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<WorkOrderLineItemDetail>>>;
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberGet(
    workOrderNumber: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (workOrderNumber === null || workOrderNumber === undefined) {
      throw new Error(
        'Required parameter workOrderNumber was null or undefined when calling workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<WorkOrderLineItemDetail>>(
      'get',
      `${this.basePath}/work-order-line-item-details/by-work-order-number/${encodeURIComponent(String(workOrderNumber))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete and Insert WorkOrderLineItemDetail.
   * Sample request:                    Get/by-work-order-number/{workOrderNumber}                Returns boolean.    - 200 (OK): Response ot type IEnumerable of CaseStages  - 404 (Not found): Record not found.  - 400 (Bad Request): The request is invalid.  - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param body workOrderLineItemDetails
   * @param workOrderNumber workOrderNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberPut(
    body: Array<WorkOrderLineItemDetail>,
    workOrderNumber: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<boolean>;
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberPut(
    body: Array<WorkOrderLineItemDetail>,
    workOrderNumber: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<boolean>>;
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberPut(
    body: Array<WorkOrderLineItemDetail>,
    workOrderNumber: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<boolean>>;
  public workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberPut(
    body: Array<WorkOrderLineItemDetail>,
    workOrderNumber: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberPut.'
      );
    }

    if (workOrderNumber === null || workOrderNumber === undefined) {
      throw new Error(
        'Required parameter workOrderNumber was null or undefined when calling workOrderLineItemDetailsByWorkOrderNumberWorkOrderNumberPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<boolean>(
      'put',
      `${this.basePath}/work-order-line-item-details/by-work-order-number/${encodeURIComponent(String(workOrderNumber))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
