export const APPLICATION_TITLE = 'fleet-dcrp-web';
export const ALL_CASES_NO_DATA_MESSAGE = 'All your cases will be shown here.';
export const MY_CASES_NO_DATA_MESSAGE =
  'All your assigned cases will be shown here.';
export const COMPLETED_CASES_NO_DATA_MESSAGE =
  'All your completed and cancelled cases\nwill be shown here.';
export const STATUS_MODAL_SUB_HEADER = 'Comments (optional)';
export const DCRP_WORKFLOW = 'dcrp_worfklow_bpmn';
export const COMMENTS_MODAL_SUB_HEADER =
  'Use comments for reminders and updates.';
export const WORKFLOW_TYPE = 1;
export const TEMPLATE_TYPE = 2;
export const UPDATEBOOKINGNOMSG =
  'Are you sure you want to change the booking number based on the selected container moves';
export const DELETEDAMAGEMSG =
  'The damage details will be deleted permanently. Are you sure you want to delete damage details?';
export const COUNTRYCODE_MISMATCH_MESSAGE =
  'The fact code entered/selected is not matching with the case country, please select a different liable party as per case country or change the case country appropriately.';
export const [ZERO_STRING, HYPHEN, DOLLAR] = ['0', '-', '$'];
export const [
  IA_EIR_AVAILABLE,
  IA_VENDOR_AVAILABLE,
  IA_BOTH_AVAILABLE,
  IA_NOT_AVAILABLE,
  IA_IN_PROGRESS_VALUE,
] = [1, 2, 3, 4, 5];
export const [
  IA_NOT_AVAILABLE_TEXT,
  IA_IN_PROGRESS_TEXT,
  IA_IMAGE_AVAILABLE_TEXT,
] = ['Image not available', 'In Progress', 'Image available'];
export const [
  ICON_PAPER_CLIP,
  ICON_ARROW_CLOCKWISE,
  ICON_CLOCK_STOPWATCH,
  ICON_ARROW_CLOCKWISE_TIMES,
] = [
  'paperclip',
  'arrow-clockwise',
  'clock-stopwatch',
  'arrow-clockwise-times',
];
export const ATTACHMENT_LOGGER = {
  EIRSLECT:
    'Number of clicks carried out on the list of eEIR to select for Case ',
  IMAGESELECT: 'Number of clicks on selecting the images',
  IMGAEDESELECT: 'Number of clicks on deselecting the images',
  UPLOAD: 'Number of Manual upload',
};
export const Image_Source = {
  EIR_MOBILE_APP: 'EirMobileApp',
  VENDOR_EMAIL: 'VendorEmail',
};

export const FIELD_NAME = {
  SERVICE_RECEIVE_DELIVERY: 'servRecDel',
  LOCATION_ID: 'locationId',
  EQUIPMENT_NUMBER: 'equipmentNumber',
  COVERAGE_AMOUNT: 'cpCoverageAmount',
  CP_PLAN: 'cpPurchased',
  ABOVE_RECOVERABLE_COST: 'aboveCoverageCostUSD',
  RECOVERABLE_COST_USD: 'recoverableCostUSD',
};

export const DUPLICATE_WORK_ORDER_MSG =
  'Duplicate WO not considered for CP recovery';
export const ATTACHMENT_LETTER_ERROR_MSG =
  'Attachments size exceeds the permitted limit of 20Mbs. Please deselect a few files to proceed';
export const LIABILITY_ERROR_MSG =
  'Letter could not be sent due to technical issues. Please try again';

export const DAMAGE_ESTIMATION_USE_NOTIFICATION_MSG =
  'The manual estimate section is used for calculation purposes only. Any data entered here will not be included in the liability letter template';

export const CONTAINER_MOVE_SELECT_CONFIRMATION_MSG =
  'Are you sure you want to change the booking number based on the selected container move?';

// these are the email addresses of the admin users for temporary use
// these will be removed once the user roles are implemented
export const ADMIN_DEV_EMAILS: string[] = [
  'ashwin.ganvir@maersk.com',
  'nishchal.shrimali@maersk.com',
  'sheela.mehta@maersk.com',
];

export const VENDOR_EMAIL_SUBJECT =
  'Maersk Line - Damage Recovery - Bol No. PH_BL_NUM, Case No. PH_CASE_NUMBER, Container No. PH_CONTAINER_NUMBER.';

export const AUTO_CLOSE_REASON = 'Case auto closed due to inaction';
