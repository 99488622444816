import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  LibFormComponent,
  ModalNotificationService,
  PanelComponent,
  SectionSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { DELETEDAMAGEMSG } from '../../../common/constants/app.constants';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  delay,
  map,
  merge,
  shareReplay,
  tap,
} from 'rxjs';
import { CaseDamageDetailDto } from '../../../common/models/caseDamageDetailDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedDataService } from '../../../shared-data-service';
import { RecoveryCase } from '../../../common/models/recoveryCase';
@Component({
  selector: 'damage-details',
  standalone: true,
  imports: [
    CommonModule,
    LibFormComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './damage-details.component.html',
  styleUrl: './damage-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DamageDetailsComponent implements OnDestroy {
  @Input() item!: TemplateModel;
  disable: boolean = false;
  items: TemplateModel[] = [];
  damageItemSchema?: TemplateModel;
  defaultLineItem: TemplateModel[] = [];
  additionalLineItem: TemplateModel[] = [];
  apiVersion: string = '1.0';
  costChangeLocalTimeOut: any;
  costChangeUSDTimeOut: any;

  /**
   * Subject to hold the latest list of assigned damage details.
   */
  damageLineItemToCaseSubject$$: BehaviorSubject<TemplateModel[] | null> =
    new BehaviorSubject<TemplateModel[] | null>([]);
  /**
   * Initial list of assigned case images which we get from the API.
   */
  lineItemInitial$?: Observable<TemplateModel[] | null>;

  /**
   * Main Observable to hold the latest list of damage detail line item.
   */
  damageData$?: Observable<TemplateModel[] | null>;

  deleteItem!: TemplateModel;
  damageIndex: number = -1;
  totalAmount: number = 0;
  totalAmountUSD: number = 0;
  currencyCode: string = 'USD';
  exchangeRate: number = 1.0;
  caseDetail!: RecoveryCase | undefined;
  enableAddNewDamageBtn: boolean = false;

  formIndexBeingUpdated: number = 0;
  /**
   * This variable will tell us that amount local currency is already updated while updating amount USD
   * There is no need to process value change event for amount local currency for now.
   */
  isAmountLocalCurrencyChangedAlready: boolean = false;
  /**
   * This variable will tell us that amount USD is already updated while updating amount local currency
   * There is no need to process value change event for amount USD for now.
   */
  isAmountUsdChangedAlready: boolean = false;

  private formFieldChangeSubject$$: Subject<TemplateModel> =
    new Subject<TemplateModel>();

  formFieldChangeSubscription = this.formFieldChangeSubject$$
    .asObservable()
    .pipe(
      // wait until user has finished typing multi-digit number so that we can process whole number instead of individual digits.
      debounceTime(500),
      tap((formItem: TemplateModel) => {
        if (formItem.name === 'amountInCaseCurrency')
          this.onAmountLocalCaseCurrencyValueChanged(formItem);
        if (formItem.name === 'amount') this.onAmountUSDValueChanged(formItem);
      }),
      // Amount local currency and Amount USD changes each other by calculating each others values.
      // After 1 second let's clear the variables we have used to avoid loop events.
      // We are stopping this loop when user is updating 1 value at a time.
      delay(1000),
      tap(() => {
        this.isAmountLocalCurrencyChangedAlready = false;
        this.isAmountUsdChangedAlready = false;
      })
    )
    .subscribe();

  constructor(
    private _modalService: ModalNotificationService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnDestroy(): void {
    this.formFieldChangeSubscription.unsubscribe();
  }

  customerRecoveryData$ = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._sharedDataService.materialCodesAsDropdownOptions$,
    this._sharedRecoveryCaseService.disableForm$,
  ]).pipe(
    tap(([recoveryData, materialCodes, disable]) => {
      this.caseDetail = recoveryData;
      this.disable = disable;
      this.initDamageDetails(materialCodes);
      this.loadDamageDetails();
    })
  );

  initDamageDetails(materialCodes: DropDownOption[]) {
    this.item.items?.forEach((element) => {
      if (element.type == 'select') {
        element.options = materialCodes;
      }
    });
    if (
      this.caseDetail?.caseCurrency &&
      this.caseDetail?.exchangeRateUSDCaseCurrency
    ) {
      this.currencyCode = this.caseDetail?.caseCurrency;
      this.exchangeRate = this.caseDetail?.exchangeRateUSDCaseCurrency;
    }
  }

  /**
   * We are fetching already assigned damages details to the current case (if any) and then showing the damages details in the lists.
   * @param changes changes
   */
  loadDamageDetails(): void {
    this.lineItemInitial$ = this._sharedRecoveryCaseService.damageDetails$.pipe(
      map((response) => {
        if (!response || response.length === 0) {
          const damageItem = this.generateDamageDetailsItem(undefined, 0);
          return [damageItem];
        }
        return response.map((damage, index) =>
          this.generateDamageDetailsItem(damage, index)
        );
      }),
      shareReplay(1),
      tap((lineItems) => this.damageLineItemToCaseSubject$$.next(lineItems))
    );

    this.damageData$ = merge(
      this.lineItemInitial$,
      this.damageLineItemToCaseSubject$$.asObservable()
    ).pipe(
      tap((items) => {
        if (items) {
          this.totalAmount = this.getTotalAmount(items);
          this.totalAmountUSD =
            Math.round(this.totalAmount * 100 * this.exchangeRate) / 100;
        }
      })
    );
  }

  generateDamageDetailsItem(
    damage?: CaseDamageDetailDto,
    index?: number
  ): TemplateModel {
    const damageKeyValue = damage as {
      [key: string]: unknown;
    };
    return {
      label: this.item?.label,
      disabled: this.disable,
      type: this.item?.type,
      name: this.item?.name,
      id: damageKeyValue ? damageKeyValue['id'] : null,
      items: this.item?.items?.map((formItem) =>
        this.getFormTemplateItemFromLineItem(formItem, damageKeyValue, index)
      ),
    } as TemplateModel;
  }

  getFormTemplateItemFromLineItem(
    formItem: TemplateModel,
    dataValue: {
      [key: string]: unknown;
    },
    index: number = -1
  ) {
    return {
      label: formItem.label?.replace('Local', this.currencyCode),
      name: formItem.name,
      type: formItem.type,
      disabled: this.disable,
      value: dataValue ? dataValue[formItem.name] : null,
      valueType: formItem.valueType,
      width:
        this.currencyCode === 'USD' ? { size: 33, unit: '%' } : formItem.width,
      hidden:
        formItem.name === 'amount' && this.currencyCode === 'USD'
          ? true
          : formItem.hidden,
      sequence: formItem.sequence,
      hideLabel: index == 0 ? false : true,
      labelPosition: formItem.labelPosition,
      placeHolder: formItem.placeHolder,
      options: formItem.options,
      onValueChanged: (value) =>
        this.formFieldChangeSubject$$.next({
          ...formItem,
          value: value,
        }),
      isMandatory:
        formItem.name === 'amount' && this.currencyCode === 'USD'
          ? false
          : formItem.isMandatory,
    } as TemplateModel;
  }

  getTotalAmount(items: TemplateModel[]): number {
    let amount = 0;
    items.forEach((i) => {
      const amountField = i.items?.filter(
        (x) => x.name == 'amountInCaseCurrency'
      )[0];
      amount += amountField?.value ? parseFloat(amountField?.value) : 0;
    });
    return Math.round(amount * 100) / 100;
  }

  getTotalAmountUSD(items: TemplateModel[]): number {
    let amount = 0;
    items.forEach((i) => {
      const amountField = i.items?.filter((x) => x.name == 'amount')[0];
      amount += amountField?.value ? parseFloat(amountField?.value) : 0;
    });
    return Math.round(amount * 100) / 100;
  }

  addDamageItem() {
    const newDamageItem = this.generateDamageDetailsItem();
    const existingDamageItem = this.damageLineItemToCaseSubject$$.value;
    existingDamageItem?.push(newDamageItem);
    if (existingDamageItem) {
      this.damageLineItemToCaseSubject$$.next(existingDamageItem);
      this.reassignDamageItems(existingDamageItem);
    }
  }

  deleteDamageItem(item: TemplateModel, index: number) {
    this.deleteItem = item;
    this.damageIndex = index;
    let valid: boolean = false;
    item.items?.forEach((i) => {
      //if data is not entered or if line item is empty
      if (!i.hidden && i.value) {
        valid = true;
      }
    });
    if (valid) {
      this._modalService.openModal({
        primaryButtonMessage: 'Delete',
        secondaryButtonMessage: 'Cancel',
        message: DELETEDAMAGEMSG,
        header: 'Delete Damage',
        dimension: 'small',
        onPrimaryBtnClick: this.confirmDelete.bind(this),
      });
    } else {
      this.confirmDelete();
    }
  }

  confirmDelete() {
    let latestItem = null;
    const existingDamageItem = this.damageLineItemToCaseSubject$$.value;

    if (this.deleteItem.id != null) {
      //if lineItem has id or if line item exist already
      latestItem = existingDamageItem?.filter(
        (i) => i.id != this.deleteItem.id
      );
    } else if (this.deleteItem.id == null) {
      // newly created line item
      latestItem = existingDamageItem?.filter(
        (i, index) => index != this.damageIndex
      );
    }
    if (latestItem) {
      this.damageLineItemToCaseSubject$$.next(latestItem);
      this._sharedRecoveryCaseService.updateFormValidationState({
        component: Components.DamageDetailsComponent,
        state: true,
      });
      this.reassignDamageItems(latestItem!);
    }
  }

  reassignDamageItems(items: TemplateModel[] | null) {
    items?.[0].items?.forEach((i) => {
      i.hideLabel = false;
    });
    let isValidItems = true;
    // check if all the items are valid
    items?.forEach((i) => {
      if (i.items && !this._sharedDataService.validateDamageLineItem(i.items)) {
        isValidItems = false;
        return;
      }
    });
    this.enableAddNewDamageBtn = this.totalAmount > 0 && isValidItems!;
  }

  onAmountLocalCaseCurrencyValueChanged(item: TemplateModel) {
    if (
      this.isAmountLocalCurrencyChangedAlready ||
      !this.damageLineItemToCaseSubject$$.value
    )
      return;

    this.totalAmount = this.getTotalAmount(
      this.damageLineItemToCaseSubject$$.value
    );
    this.totalAmountUSD =
      Math.round(this.totalAmount * 100 * this.exchangeRate) / 100;

    const amountUSD = this.damageLineItemToCaseSubject$$.value?.[
      this.formIndexBeingUpdated
    ]?.items?.filter((x: any) => x.name == 'amount')[0];
    if (!amountUSD) return;
    this.isAmountUsdChangedAlready = true;
    if (item.value) {
      amountUSD.value = Math.round(item.value * 100 * this.exchangeRate) / 100;
    } else {
      amountUSD.value = null;
    }
  }

  onAmountUSDValueChanged(item: TemplateModel) {
    if (
      this.isAmountUsdChangedAlready ||
      !this.damageLineItemToCaseSubject$$.value
    )
      return;

    this.totalAmountUSD = this.getTotalAmountUSD(
      this.damageLineItemToCaseSubject$$.value
    );
    this.totalAmount = this.roundToDecimals(
      this.totalAmountUSD / this.exchangeRate,
      2
    );

    const amountInCaseCurrency = this.damageLineItemToCaseSubject$$.value?.[
      this.formIndexBeingUpdated
    ]?.items?.filter((x: any) => x.name == 'amountInCaseCurrency')[0];
    if (!amountInCaseCurrency) return;
    this.isAmountLocalCurrencyChangedAlready = true;
    if (item.value) {
      amountInCaseCurrency.value = this.roundToDecimals(
        item.value / this.exchangeRate,
        2
      );
    } else {
      amountInCaseCurrency.value = null;
    }
  }

  /**
   * Validates if the total cost is above 0 or not. Based on that, next button will be enabled/disabled.
   */
  formValidityChanged(isValid: boolean, updatingIndex: number) {
    this.formIndexBeingUpdated = updatingIndex;
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.DamageDetailsComponent,
      state: isValid,
    });
    this.enableAddNewDamageBtn = this.totalAmount > 0 && isValid;
  }

  async saveDamageDetailsToServer() {
    const saveDamage = await this._sharedRecoveryCaseService.saveDamageDetails(
      this.damageLineItemToCaseSubject$$.value ?? [],
      this.caseDetail?.caseId ?? 0
    );

    if (!saveDamage) return;
    // this._sharedRecoveryCaseService.reloadDamageDetails();
  }

  private roundToDecimals(
    value: number,
    decimals: number,
    trim: boolean = false
  ): number {
    const factor = Math.pow(10, decimals);

    if (trim) {
      // Truncate by removing decimals after the specified precision
      return Math.trunc(value * factor) / factor;
    } else {
      // Round to the specified number of decimals
      return Math.round(value * factor) / factor;
    }
  }
}
