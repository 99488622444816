<ng-container *ngIf="customerRecoveryData$ | async">
  <div class="container-moves">
    <header class="header">Container Moves - {{ containerNumber }}</header>
    <div class="container-moves-filter">
      <mc-input-date
        ngDefaultControl
        formControlName="checkin-date"
        label=""
        startofweek="1"
        format="DD/MM/YYYY"
        locale="en-IN"
        nextlabel="Next month"
        previouslabel="Previous month"
        hiddenlabel
        placeholder="DD/MM/YYYY"
        [disabled]="disable"
        min="{{ minFromDate }}"
        max="{{ maxFromDate }}"
        fit="small"
        width="232px"
        [value]="selectedFromDate"
        (input)="dateSelected('from', $event)"></mc-input-date>

      <mc-input-date
        ngDefaultControl
        formControlName="checkin-date"
        label=""
        startofweek="1"
        format="DD/MM/YYYY"
        locale="en-IN"
        nextlabel="Next month"
        previouslabel="Previous month"
        hiddenlabel
        placeholder="DD/MM/YYYY"
        [disabled]="disable"
        fit="small"
        width="232px"
        min="{{ minToDate }}"
        max="{{ maxToDate }}"
        [value]="selectedToDate"
        (input)="dateSelected('to', $event)"></mc-input-date>

      <mc-button
        label="Get moves"
        fit="small"
        [disabled]="disable"
        (click)="updateMoves()"></mc-button>
      <mc-button
        label="Cancel"
        fit="small"
        appearance="neutral"
        [disabled]="disable"
        (click)="setInitialDateRangeForContainerMoves()"></mc-button>

      <span
        class="errorMessage"
        [style.display]="invalidDateSelected ? 'block' : 'none'"
        style="color: red">
        Invalid date selected. Journey duration should be less than
        {{ this.containerJourneyDurationInDays }} days
      </span>
    </div>
    <div>
      <container-moves
        [item]="containerMovesItem"
        [fromDate]="containerMovesFromDate!"
        [toDate]="containerMovesToDate!"
        [showSelection]="showSelection"
        [disable]="disable"
        [containerNumber]="containerNumber"
        (onDataLoad)="onContainerMovesDataFetched($event)"
        (onRowSelected)="OnMovesRowSelectionChanged($event)"
        [loadData]="showContainerMoves"></container-moves>
    </div>
  </div>
</ng-container>
