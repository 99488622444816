import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ALIGN,
  GridColumnSchema,
  GridComponent,
  GridRowData,
} from '@maersk-global/angular-shared-library';
import { FooterRowData } from '@maersk-global/angular-shared-library/lib/models/footer-row-data';
import { FooterSchema } from '@maersk-global/angular-shared-library/lib/models/footer-schema';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { switchMap, tap } from 'rxjs';
import { RepairLineItem } from '../../../common/models/repairLineItem';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';
import { RecoveryCase } from '../../../common/models/recoveryCase';

@Component({
  selector: 'liability-details-manual-estimates',
  standalone: true,
  imports: [CommonModule, GridComponent],
  templateUrl: './liability-details-manual-estimates.component.html',
  styleUrl: './liability-details-manual-estimates.component.scss',
})
export class LiabilityDetailsManualEstimatesComponent {
  @Input({ required: true }) item!: TemplateModel;
  items: TemplateModel[] = [];

  costData: GridRowData[] = [];
  gridSchema: GridColumnSchema[] = [];
  costFooter: FooterRowData | null = null;
  footerSchema: FooterSchema[] = [];
  currencyCode = 'USD';
  recoveryData?: RecoveryCase;
  exchangeRate = 1.0;
  manualEstimates: RepairLineItem[] | undefined;
  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService
  ) {}

  customerRecoveryData$ =
    this._customerRecoverySharedService.manualEstimatesLineItems$.pipe(
      switchMap((manualEstimates) => {
        this.manualEstimates = manualEstimates;

        return this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
          tap((recoveryData) => {
            this.recoveryData = recoveryData;
            //Fetching currency and exchange rate
            if (
              recoveryData?.caseCurrency &&
              recoveryData?.exchangeRateUSDCaseCurrency
            ) {
              this.currencyCode = recoveryData.caseCurrency as string;
              this.exchangeRate =
                recoveryData.exchangeRateUSDCaseCurrency as number;
            }
            //Binding schema
            this.bindSchema();
            this.bindGridData();
          })
        );
      })
    );

  /**
   * Binds schema for liability grid and footer
   */
  bindSchema() {
    //Binding grid schema
    if (this.item && this.item.items && this.item.items[0].items) {
      this.gridSchema = this.item.items[0].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label?.replace('Local', this.currencyCode),
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          hidden: y.name === 'costLocal' && this.currencyCode === 'USD',
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
        } as GridColumnSchema;
        return column;
      });
    }

    //Binding footer schema
    if (this.item && this.item.items && this.item.items[1].items) {
      this.footerSchema = this.item.items[1].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          hidden: y.name === 'costLocal' && this.currencyCode === 'USD',
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          colSpan: y.colspan,
        } as FooterSchema;
        return column;
      });
    }
  }

  /**
   * Binds data for liability grid and footer
   */
  bindGridData() {
    if (!(this.manualEstimates && this.manualEstimates.length > 0)) return;
    // Calculate costs for all line items
    const cost = this.calculateCost(this.manualEstimates, this.recoveryData!);

    // Filter items which are required for grid data and footer data
    this.items = this.item.items?.filter((o) => o.sequence) as TemplateModel[];

    // Iterate through items and update values
    this.items.forEach((item: TemplateModel) => {
      switch (item.name) {
        case 'damageCost':
          item.value = this.formatDecimal(cost.damageCost);
          break;
        case 'withInCoverageCost':
          item.value = this.formatDecimal(cost.withInCoverageCost);
          break;
        case 'recoverableCostUSD':
          item.value = this.formatDecimal(cost.recoverableCost);
          break;
        case 'recoverableCost':
          item.value = this.formatDecimal(cost.recoverableCost);
          break;
        case 'totalLiability':
          {
            item.value = this.formatDecimal(cost.totalLiability);
            item.label =
              this.recoveryData?.caseCurrency?.toUpperCase() == 'USD'
                ? item.label + ' (USD)'
                : item.label;
          }
          break;
        default:
          break;
      }
    });

    // Populate grid data excluding aboveCoverageCostUSD
    this.costData = this.items
      .filter((o) => o.name !== 'totalLiability')
      .map((item: TemplateModel) => ({
        row: {
          costType: { value: item.label },
          costLocal: {
            value: this.formatDecimal(item.value / this.exchangeRate),
          },
          costUSD: { value: item.value },
        },
        isMasterRow: false,
        showChildRowData: false,
      })) as GridRowData[];

    // Populate footer data for aboveCoverageCostUSD
    const aboveCoverageItem = this.items.find(
      (o) => o.name === 'totalLiability'
    );
    if (aboveCoverageItem) {
      this.costFooter = {
        row: {
          costType: { value: aboveCoverageItem.label },
          costLocal: {
            value: this.formatDecimal(
              aboveCoverageItem.value / this.exchangeRate
            ),
          },
          costUSD: { value: aboveCoverageItem.value },
        },
      } as FooterRowData;
    }
  }

  calculateCost(repairLineItems: RepairLineItem[], recoveryData: RecoveryCase) {
    const cost = {
      damageCost: 0,
      withInCoverageCost: 0,
      recoverableCost: 0,
      totalLiability: 0,
    };

    const { totalCostLocal, totalCostUsd } = repairLineItems.reduce(
      (acc, lineItem) => {
        acc.totalCostLocal += lineItem.lineTotalLocal ?? 0;
        acc.totalCostUsd += lineItem.lineTotalUSD ?? 0;
        return acc;
      },
      { totalCostLocal: 0, totalCostUsd: 0 }
    );

    cost.damageCost = this.formatDecimal(totalCostUsd);
    cost.recoverableCost = this.formatDecimal(totalCostUsd);
    cost.withInCoverageCost =
      recoveryData.cpCoverageAmount! > totalCostUsd
        ? totalCostUsd
        : this.formatDecimal(recoveryData.cpCoverageAmount ?? 0);
    cost.totalLiability = cost.recoverableCost - cost.withInCoverageCost;

    return cost;
  }

  private formatDecimal(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
