import { Injectable } from '@angular/core';
import { Observable, filter, map, merge, tap } from 'rxjs';
import { LiabilityLetterDto } from './common/models/liabilityLetterDto';
import { InvoicingStatus } from './common/models/invoicingStatus';
import { CustomerInfoDto } from './common/models/customerInfoDto';
import { LiablePartyDto } from './common/models/liabilePartyDto';
import { gcssBookingInfo } from './common/models/gcssBookingInfo';
import { CaseDamageDetailDto } from './common/models/caseDamageDetailDto';
import { ContainerMoveDto } from './common/models/containerMoveDto';
import { SharedCustomerRecoveryCaseService } from './components/customer-recovery/shared-customer-recovery-case.service';
import { CustomerRecoveryCaseDto } from './common/models/customerRecoveryCaseDto';
import { FormValidation } from './common/models/formValidation';
import { SharedVendorRecoveryCaseService } from './components/vendor-recovery/shared-vendor-recovery-case.service';
import { RecoveryCase } from './common/models/recoveryCase';
import { VendorRecoveryCase } from './common/models/vendorRecoveryCase';
import { ClaimReferenceDocumentDto } from './common/models/claimReferenceDocumentDto';
import { Inspection } from './common/models/inspection';
import { TemplateModel } from '@maersk-global/angular-shared-library';
import { InspectionDetailsDTO } from './common/models/inspectionDetailsDTO';
import { CaseActivityLogsDtoListResponse } from './common/models/caseActivityLogsDtoListResponse';
import { LiabilityLetter } from './common/models/liabilityLetter';
import { LiabilityLetterStatus } from './common/models/liabilityLetterStatus';
import { SendMailRequest } from './common/models/sendMailRequest';
import { InvoiceDetail } from './common/models/invoiceDetail';
import { UpdateInvoice } from './common/models/updateInvoice';
import { CreateInvoice } from './common/models/createInvoice';
import { EmailDetailDto } from './common/models/emailDetailDto';
import { RecoveryCaseStatus } from './common/models/recoveryCaseStatus';
import { WorkFlowStage } from './common/models/workflowStage';

export interface ISharedRecoveryCaseService {
  reloadRecoveryCaseData(): void;
  reloadDamageDetails(): void;
  reloadLiabilityPartyDetails(): void;
  reloadLiabilityLetters(): void;
  reloadInvoices(): void;
  updateLiabilityDetailsVisibility(value: boolean): void;
  updateIssueInvoiceVisibility(value: boolean): void;
  updateEirImageLastFetchOn(date: Date | undefined): void;
  updateCustomerData(data: CustomerInfoDto): void;
  updateContainerDetails(
    containerDetails: ContainerMoveDto | null | undefined
  ): void;
  updateCurrentStageId(stageId: number): void;
  updateFormValidationState(state: FormValidation): void;
  saveDamageDetails(damages: TemplateModel[], caseId: number): Promise<boolean>;
  caseDocumentGet(caseId: number): Observable<ClaimReferenceDocumentDto | null>;
  caseDocumentPost(
    imagesAssignedToCases: ClaimReferenceDocumentDto
  ): Observable<ClaimReferenceDocumentDto | null>;
  caseDocumentDelete(documentId: number): Observable<boolean>;
  inspectionsGet(
    caseNumber: string,
    containerNumber: string | undefined,
    incidentDate: Date | null,
    workOrderDate: Date | null
  ): Observable<Array<Inspection> | null>;
  saveImageAvailability(
    inspectionInfo: InspectionDetailsDTO,
    caseNumber: string
  ): Observable<boolean>;
  updateOverviewDetails(overview: TemplateModel[]): void;
  getActivityLogs(caseId: number): Observable<CaseActivityLogsDtoListResponse>;
  updateLiabilityLetter(
    letter: LiabilityLetter,
    liabilityLetterStatus: LiabilityLetterStatus
  ): Promise<boolean>;
  onResendEmailClicked(letter: SendMailRequest): Observable<boolean>;
  downloadLetter(blobPath: string, caseNumber: string): Observable<Blob>;
  postInvoiceDetails(
    createInvoice: CreateInvoice,
    userId: string
  ): Observable<boolean>;
  putInvoiceDetails(
    invoiceId: number,
    updateInvoice: UpdateInvoice,
    userId: string
  ): Observable<boolean>;
  saveComment(comment: string): Promise<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class SharedRecoveryCaseService {
  caseContext!: ISharedRecoveryCaseService;

  constructor(
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    private _vendorRecoverySharedService: SharedVendorRecoveryCaseService
  ) {}

  allowCustomerContext = () =>
    this.caseContext instanceof SharedCustomerRecoveryCaseService;

  allowVendorContext = () =>
    this.caseContext instanceof SharedVendorRecoveryCaseService;

  recoveryCaseData$: Observable<RecoveryCase> = merge(
    this._customerRecoverySharedService.customerRecoveryCaseData$.pipe(
      filter((customerRecovery) => !!customerRecovery),
      tap(() => {
        this.caseContext = this._customerRecoverySharedService;
      }),
      map((customerRecovery) => {
        return this.buildRecoveryCaseModelForCustomer(
          customerRecovery as CustomerRecoveryCaseDto
        );
      })
    ),
    this._vendorRecoverySharedService.vendorRecoveryCaseData$.pipe(
      filter((vendorRecovery) => !!vendorRecovery),
      tap(() => {
        this.caseContext = this._vendorRecoverySharedService;
      }),
      map((vendorRecovery) => {
        return this.buildRecoveryCaseModelForVendor(
          vendorRecovery as VendorRecoveryCase
        );
      })
    )
  );

  workflowStageId$: Observable<number> = merge(
    this._customerRecoverySharedService.workflowStageId$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.workFlowStageId$.pipe(
      filter(this.allowVendorContext)
    )
  );

  disableForm$: Observable<boolean> = merge(
    this._customerRecoverySharedService.disableForm$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.disableForm$.pipe(
      filter(this.allowVendorContext)
    )
  );

  buildRecoveryCaseModelForCustomer(
    customer: CustomerRecoveryCaseDto
  ): RecoveryCase {
    return {
      caseId: customer.caseId,
      recoveryCaseNumber: customer.recoveryCaseNumber,
      workOrderNumber: customer.workOrderNumber,
      containerNumber: customer.containerNumber,
      containerProdYear: customer.containerProdYear,
      gateInDate: customer.gateInDate,
      bookingNumber: customer.bookingNumber,
      bolNumber: customer.bolNumber,
      shopCode: customer.shopCode,
      shopCountryId: customer.shopCountryId,
      shopCountryCode: customer.shopCountryCode,
      shopCountryName: customer.shopCountryName,
      podCountryId: customer.podCountryId,
      podCountryCode: customer.podCountryCode,
      podCountryName: customer.podCountryName,
      podCountryClusterCode: customer.podCountryClusterCode,
      podCountryClusterName: customer.podCountryClusterName,
      workOrderCostUSD: customer.workOrderCostUSD,
      recoverableCostUSD: customer.recoverableCostUSD,
      withinCoverageCostUSD: customer.withinCoverageCostUSD,
      aboveCoverageCostUSD: customer.aboveCoverageCostUSD,
      recoveryStatusId: customer.recoveryStatusId,
      workorderStatusCode: customer.workorderStatusCode,
      operatorCode: customer.operatorCode,
      placeOfIncident: customer.placeOfIncident,
      dateOfIncident: customer.dateOfIncident,
      aboveCoverageBand: customer.aboveCoverageBand,
      importReturn: customer.importReturn,
      assignedToUID: customer.assignedToUID,
      cpPurchased: customer.cpPurchased,
      priority: customer.priority,
      isCostChanged: customer.isCostChanged,
      groupId: customer.groupId,
      comment: customer.comments,
      cancellationReason: customer.cancellationReason,
      hasLinkedCases: customer.hasLinkedCases,
      gateInLapseInDays: customer.gateInLapseInDays,
      workOrderDate: customer.workOrderDate,
      invoiceNumber: customer.invoiceNumber,
      workFlowVersion: customer.workFlowVersion,
      templateVersion: customer.templateVersion,
      stopUpgrade: customer.stopUpgrade,
      woMode: customer.woMode,
      podLocalCurrency: customer.podLocalCurrency,
      equipmentSubType: customer.equipmentSubType,
      containerSize: customer.containerSize,
      cause: customer.cause,
      equipmentOwnershipType: customer.equipmentOwnershipType,
      completionDate: customer.completionDate,
      vendorReference: customer.vendorReference,
      createdBy: customer.createdBy,
      createdDate: customer.createdDate,
      updatedBy: customer.updatedBy,
      updatedDate: customer.updatedDate,
      locationId: customer.locationId,
      containerEmptyFull: customer.containerEmptyFull,
      siteName: customer.siteName,
      containerCurrentLocation: customer.containerCurrentLocation,
      eirImageLastCheckedOn: customer.eirImageLastCheckedOn,
      caseCurrency: customer.caseCurrency,
      exchangeRateUSDCaseCurrency: customer.exchangeRateUSDCaseCurrency,
      workOrderCostCaseCurrency: customer.workOrderCostCaseCurrency,
      withinCoverageCostCaseCurrency: customer.withinCoverageCostCaseCurrency,
      recoverableCostCaseCurrency: customer.recoverableCostCaseCurrency,
      aboveCoverageCostCaseCurrency: customer.aboveCoverageCostCaseCurrency,
      handlingDamageCostUSD: customer.handlingDamageCostUSD,
      workflowStageId: this._customerRecoverySharedService.getStageId(
        customer.workFlowStage ?? 'New'
      ),
      cpCoverageAmount: customer.cpCoverageAmount,
      cpCoverageCurrency: customer.cpCoverageCurrency,
      cpMasterContainerSize: customer.cpMasterContainerSize,
      isCpActiveCountry: customer.isCpActiveCountry,
      podRegionCode: customer.podRegionCode,
      podRegionName: customer.podRegionName,
      responsibleCountryCode: customer.podCountryCode,
      responsibleCountryClusterCode: customer.podCountryClusterCode,
      responsibleRegionCode: customer.podRegionCode,
      responsibleCountryName: customer.podCountryName,
      responsibleCountryClusterName: customer.podCountryClusterName,
      responsibleRegionName: customer.podRegionName,
      recoveryCaseType: 'Customer',
    } as RecoveryCase;
  }

  buildRecoveryCaseModelForVendor(vendor: VendorRecoveryCase): RecoveryCase {
    return {
      caseId: vendor.id,
      groupId: vendor.groupId,
      equipmentNumber: vendor.equipmentNumber,
      equipmentType: vendor.equipmentType,
      equipmentSize: vendor.equipmentSize,
      equipmentManufacturingYear: vendor.equipmentManufacturingYear,
      equipmentOwnershipType: vendor.equipmentOwnershipType,
      recoveryCaseType: vendor.recoveryCaseType,
      responsibleCountryCode: vendor.responsibleCountryCode,
      responsibleCountryClusterCode: vendor.responsibleCountryClusterCode,
      responsibleRegionCode: vendor.responsibleRegionCode,
      responsibleCountryName: vendor.responsibleCountryName,
      responsibleCountryClusterName: vendor.responsibleCountryClusterName,
      responsibleRegionName: vendor.responsibleRegionName,
      recoverableCostInUsd: vendor.recoverableCostInUsd,
      aboveCoverageCostInUsd: vendor.aboveCoverageCostInUsd,
      caseStatus: vendor.caseStatus,
      bolNumber: vendor.bolNumber,
      bookingNumber: vendor.bookingNumber,
      invoiceNumber: vendor.invoiceNumber,
      createSource: vendor.createSource,
      assignedTo: vendor.assignedTo,
      createTimestamp: vendor.createdTimestamp,
      createUserId: vendor.createdByUserId,
      workOrderNumber: vendor.workOrderNumber,
      shopCode: vendor.shopCode,
      shopCountryCode: vendor.shopCountryCode,
      containerNumber: vendor.equipmentNumber,
      operatorCode: vendor.operatorCode,
      containerSize: vendor.equipmentSize,
      shopCountryName: vendor.shopCountryName,
      recoverableCostCaseCurrency: vendor.recoverableCostInCaseCurrency,
      recoverableCostUSD: vendor.recoverableCostInUsd,
      workOrderCostUSD: vendor.totalCostInUsd,
      workOrderCostCaseCurrency: vendor.totalCostInCaseCurrency,
      caseCurrency: 'USD', //Setting currency as USD by default
      recoveryStatusId: Object.keys(RecoveryCaseStatus).indexOf(
        vendor.caseStatus ?? 'New'
      ),
      workflowStageId: vendor.workflowStageId,
      comment: vendor.comment,
      cancellationReason: vendor.cancellationReason,
    } as RecoveryCase;
  }

  customerData$: Observable<CustomerInfoDto> = merge(
    this._customerRecoverySharedService.customerRecoveryCustomerData$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.vendorRecoveryCustomerData$.pipe(
      filter(this.allowVendorContext)
    )
  );

  currentStageId$: Observable<number> = merge(
    this._customerRecoverySharedService.currentStageId$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.currentStageId$.pipe(
      filter(this.allowVendorContext)
    )
  );

  workOrderDate$: Observable<Date | null | undefined> = merge(
    this._vendorRecoverySharedService.workOrderDate$
  );

  incidentDate$: Observable<Date | null | undefined> = merge(
    this._customerRecoverySharedService.incidentDate$
  );

  eirImageLastFetchDate$: Observable<Date | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryEirImageLastFetchDate$,
    this._vendorRecoverySharedService.vendorRecoveryEirImageLastFetchDate$
  );

  containerDetails$: Observable<ContainerMoveDto | null | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryContainerMovesDetails$
  );

  LiablePartyData$: Observable<LiablePartyDto | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryLiablePartyData$,
    this._vendorRecoverySharedService.vendorRecoveryLiablePartyData$
  );

  liabilityLetters$: Observable<Array<LiabilityLetter> | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryLiabilityLetters$.pipe(
      filter(this.allowCustomerContext),
      map((letters) => {
        return letters?.map(
          (letter) =>
            this.buildLetterModelForCustomer(letter) as LiabilityLetter
        );
      })
    ),
    this._vendorRecoverySharedService.vendorRecoveryLiabilityLetters$.pipe(
      filter(this.allowVendorContext)
    )
  );

  buildLetterModelForCustomer(letter: LiabilityLetterDto): LiabilityLetter {
    return {
      id: letter.id,
      recoveryCaseId: letter.recoveryCaseId,
      liablePartyName: letter.liabilityPartyName,
      liabilityAmount: letter.liabilityAmount,
      liabilityLetterStatus: letter.liabilityLetterStatus,
      liabilityLetterCaseType: 'Customer',
      liabilityLetterResponseReason: letter.liabilityLetterResponseReason,
      emailBlobPath: letter.emailBlobPath,
      to: letter.to,
      cc: letter.cc,
      subject: letter.subject,
      body: letter.body,
      emailAttachments: letter.emailAttachment,
      isManuallyUploaded: false,
      createdByUserId: letter.createdBy,
      updatedByUserId: letter.updatedBy,
      createdTimestamp: letter.createdDate,
      updatedTimestamp: letter.updatedDate,
    } as LiabilityLetter;
  }

  shouldShowLiabilityDetails$: Observable<boolean> = merge(
    this._customerRecoverySharedService.shouldShowCustomerRecoveryLiabilityDetails$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.shouldShowCustomerRecoveryLiabilityDetails$.pipe(
      filter(this.allowVendorContext)
    )
  );

  invoices$: Observable<Array<InvoiceDetail> | undefined> = merge(
    this._vendorRecoverySharedService.vendorRecoveryInvoices$.pipe(
      filter(this.allowVendorContext)
    ),
    this._customerRecoverySharedService.customerRecoveryInvoices$.pipe(
      filter(this.allowCustomerContext)
    )
  );

  shouldShowIssueInvoiceScreen$: Observable<boolean> = merge(
    this._customerRecoverySharedService.shouldShowCustomerRecoveryIssueInvoiceScreen$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.shouldShowVendorRecoveryIssueInvoiceScreen$.pipe(
      filter(this.allowVendorContext)
    )
  );

  invoiceCreationPermission$: Observable<
    InvoicingStatus & { displayMessage: string }
  > = merge(
    this._customerRecoverySharedService.customerRecoveryInvoiceCreationPermission$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.vendorRecoveryInvoiceCreationPermission$.pipe(
      filter(this.allowVendorContext)
    )
  );

  bookingCargoDetails$: Observable<gcssBookingInfo | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryBookingCargoDetails$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.VendorRecoveryBookingCargoDetails$.pipe(
      filter(this.allowVendorContext)
    )
  );

  damageDetails$: Observable<CaseDamageDetailDto[] | undefined> = merge(
    this._customerRecoverySharedService.damageDetails$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.damageDetails$.pipe(
      filter(this.allowVendorContext)
    )
  );

  overviewDetails$: Observable<TemplateModel[] | undefined> = merge(
    this._customerRecoverySharedService.overviewDetails$.pipe(
      filter(this.allowCustomerContext)
    ),
    this._vendorRecoverySharedService.overviewDetails$.pipe(
      filter(this.allowVendorContext)
    )
  );

  vendorNotificationEmailAttributes$: Observable<EmailDetailDto> =
    this.recoveryCaseData$.pipe(
      map((caseData) => ({
        from: 'noreply-vendor-image-request@maersk.com',
        to: 'dummy_vendor@email.com',
        cc: 'dummy_vendor_carbon_copy@email.com',
        bcc: 'dummy_vendor_blind_carbon_copy@email.com',
        type: 'vendor',
        caseNumber: caseData.recoveryCaseNumber ?? caseData.caseId?.toString(),
        workOrderNumber: caseData.workOrderNumber,
        shopCode: caseData.shopCode,
      }))
    );

  saveDamageDetails = (damages: TemplateModel[], caseId: number) =>
    this.caseContext?.saveDamageDetails(damages, caseId);

  updateLiabilityLetter = (
    letter: LiabilityLetter,
    liabilityLetterStatus: LiabilityLetterStatus
  ) => this.caseContext.updateLiabilityLetter(letter, liabilityLetterStatus);

  onResendEmailClicked = (letter: SendMailRequest) =>
    this.caseContext.onResendEmailClicked(letter);

  downloadLetter = (blobPath: string, caseNumber: string) =>
    this.caseContext.downloadLetter(blobPath, caseNumber);

  reloadRecoveryCaseData = () => this.caseContext?.reloadRecoveryCaseData();

  reloadDamageDetails = () => this.caseContext?.reloadDamageDetails();

  reloadLiabilityPartyDetails = () =>
    this.caseContext?.reloadLiabilityPartyDetails();

  reloadLiabilityLetters = () => this.caseContext?.reloadLiabilityLetters();

  reloadInvoices = () => this.caseContext?.reloadInvoices();

  updateLiabilityDetailsVisibility = (value: boolean) =>
    this.caseContext?.updateLiabilityDetailsVisibility(value);

  updateIssueInvoiceVisibility = (value: boolean) =>
    this.caseContext?.updateIssueInvoiceVisibility(value);

  updateEirImageLastFetchOn = (date: Date | undefined) =>
    this.caseContext?.updateEirImageLastFetchOn(date);

  updateCustomerData = (data: CustomerInfoDto) =>
    this.caseContext?.updateCustomerData(data);

  updateContainerDetails = (
    containerDetails: ContainerMoveDto | null | undefined
  ) => this.caseContext?.updateContainerDetails(containerDetails);

  updateCurrentStageId = (stageId: number) =>
    this.caseContext?.updateCurrentStageId(stageId);

  updateFormValidationState = (state: FormValidation) =>
    this.caseContext?.updateFormValidationState(state);

  caseDocumentGet = (caseId: number) =>
    this.caseContext?.caseDocumentGet(caseId);

  caseDocumentPost = (imagesAssignedToCases: ClaimReferenceDocumentDto) =>
    this.caseContext?.caseDocumentPost(imagesAssignedToCases);

  caseDocumentDelete = (documentId: number) =>
    this.caseContext?.caseDocumentDelete(documentId);

  inspectionsGet = (
    caseNumber: string,
    containerNumber: string | undefined,
    incidentDate: Date | null,
    workOrderDate: Date | null
  ) =>
    this.caseContext?.inspectionsGet(
      caseNumber,
      containerNumber,
      incidentDate,
      workOrderDate
    );

  saveImageAvailability = (
    inspectionInfo: InspectionDetailsDTO,
    caseNumber: string
  ) => this.caseContext?.saveImageAvailability(inspectionInfo, caseNumber);

  updateOverviewDetails = (overview: TemplateModel[]) =>
    this.caseContext.updateOverviewDetails(overview);

  getActivityLogs = (caseId: number) =>
    this.caseContext?.getActivityLogs(caseId);

  postInvoiceDetails = (createInvoice: CreateInvoice, userId: string) =>
    this.caseContext.postInvoiceDetails(createInvoice, userId);

  putInvoiceDetails = (
    invoiceId: number,
    updateInvoice: UpdateInvoice,
    userId: string
  ) => this.caseContext.putInvoiceDetails(invoiceId, updateInvoice, userId);

  saveComment = (comment: string) => this.caseContext.saveComment(comment);
}
