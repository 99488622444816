import {
  Component,
  Input,
  CUSTOM_ELEMENTS_SCHEMA,
  Output,
  EventEmitter,
} from '@angular/core';
import { WorkOrderAndLineItemsService } from '../../../common/services/customer-recovery/work-order-and-line-items.service';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CommonModule } from '@angular/common';
import {
  ALIGN,
  GridComponent,
  GridRowData,
  GridCellData,
  GridColumnSchema,
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { WorkOrderLineItemDto } from '../../../common/models/workOrderLineItemDto';
import { workOrderStatusType } from '../../../common/constants/temporary-constant';
import {
  DOLLAR,
  HYPHEN,
  ZERO_STRING,
} from '../../../common/constants/app.constants';
import { WorkOrderDto } from '../../../common/models/WorkOrderDto';
import { SharedDataService } from '../../../shared-data-service';
import { SharedVendorRecoveryCaseService } from '../../vendor-recovery/shared-vendor-recovery-case.service';
import { ContainerMovesComponent } from '../container-moves/container-moves.component';
import { ContainerMoveDto } from '../../../common/models/containerMoveDto';
import { environment } from '../../../../environments/environment';

const WORK_ORDER_STATUS_CODE = 'workOrderStatusCode';
const ALL_COST_FIELDS_ARRAY = ['workOrderCostUSD'];

@Component({
  selector: 'container-moves-search',
  standalone: true,
  imports: [
    CommonModule,
    ContainerMovesComponent,
    LibFormComponent,
    GridComponent,
    PanelComponent,
  ],
  templateUrl: './container-moves-search.component.html',
  styleUrl: './container-moves-search.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContainerMovesSearchComponent {
  @Input() item!: TemplateModel;

  items: TemplateModel[] = [];
  apiVersion = '1.0';
  currentWorkOrder$: Observable<WorkOrderDto | null> | undefined;
  workOrderList$: Observable<WorkOrderDto[] | null> | undefined;
  lineItemsGridSchema: GridColumnSchema[] | undefined = [];
  lineItemsGridData: GridRowData[][] = [];
  showWorkOrderItems: boolean[] = [];
  workOrdersConverted: TemplateModel[][] = [];
  workOrdersData: WorkOrderDto[] = [];
  containerNumber: string | undefined;
  workOrderDate: Date | undefined;
  selectedFromDate: string | undefined;
  selectedToDate: string | undefined;
  minFromDate: string | undefined;
  maxFromDate: string | undefined;
  minToDate: string | undefined;
  maxToDate: string | undefined;
  containerMovesFromDate: string | undefined;
  containerMovesToDate: string | undefined;
  invalidDateFormat: boolean = false;
  invalidDateSelected: boolean = false;
  selectedIndex = 0;
  currentWorkOrderNumber: number | undefined;
  parsedFromDate: Date | null | undefined;
  parsedToDate: Date | null | undefined;
  containerMovesItem!: TemplateModel;
  disable: boolean = false;
  showSelection: boolean = false;
  showContainerMoves: boolean = true;
  containerJourneyDurationInDays: number = 50;

  formatDateOptions = {
    timeZone: 'UTC',
  };

  constructor(
    private _workOrderService: WorkOrderAndLineItemsService,
    private _sharedDataService: SharedDataService,
    private _sharedVendorRecoveryCaseService: SharedVendorRecoveryCaseService
  ) {}

  /**
   * This method is triggered when the component initializes.
   * It initializes items.
   */

  customerRecoveryData$ = combineLatest([
    this._sharedVendorRecoveryCaseService.vendorRecoveryCaseData$,
    this._sharedVendorRecoveryCaseService.disableForm$,
  ]).pipe(
    tap(([customerData, disable]) => {
      this.disable = disable;
      this.containerNumber = customerData?.equipmentNumber;
      this.workOrderDate = undefined;
      this.currentWorkOrderNumber = customerData?.workOrderNumber;
      this.setInitialDateRangeForContainerMoves();
      this.initializeItems();
    })
  );

  /**
   * Initializes items based on the input data.
   */
  private initializeItems() {
    if (this.item) {
      this.containerMovesItem = this.item;
      this.containerJourneyDurationInDays =
        environment.containerJourneyDurationInDays;
    }
  }

  updateMoves() {
    if (this.invalidDateSelected) return;

    this.containerMovesFromDate = this.selectedFromDate!;
    this.containerMovesToDate = this.selectedToDate!;
  }

  /**
   * Sets default date range filter for fetching work order history
   */
  setInitialDateRangeForContainerMoves() {
    let dateToFetchWorkOrders = new Date();
    const toDatePlusVal = 30;

    if (
      this.workOrderDate &&
      this.workOrderDate.toString() != '0001-01-01T00:00:00'
    ) {
      dateToFetchWorkOrders = new Date(this.workOrderDate);
    }

    this.parsedToDate = new Date(dateToFetchWorkOrders.getTime());
    this.parsedFromDate = new Date(dateToFetchWorkOrders.getTime());

    this.parsedFromDate.setUTCDate(
      this.parsedFromDate.getUTCDate() - toDatePlusVal
    );

    this.selectedFromDate = this.parsedFromDate.toLocaleDateString(
      'en-GB',
      this.formatDateOptions
    );
    this.selectedToDate = this.parsedToDate.toLocaleDateString(
      'en-GB',
      this.formatDateOptions
    );

    this.containerMovesFromDate = this.selectedFromDate;
    this.containerMovesToDate = this.selectedToDate;

    this.setFromDateRangeForContainerMoves(this.containerMovesToDate!);
    this.maxToDate = this.parsedToDate.toISOString().substring(0, 10);
  }

  /**
   * Sets from/to date based on user selection
   * @param type Type of date - From/To
   * @param event Selected date value
   */
  dateSelected(type: string, event: any): void {
    if (
      !this.validateAndSetSearchDateRange(type, event) &&
      this.invalidDateFormat
    ) {
      return;
    }
    if (type == 'from') {
      this.selectedFromDate = event.target.value;
      this.setToDateRangeForContainerMoves(this.selectedFromDate);
    } else if (type == 'to') {
      let currentToDate = this._sharedDataService.getUtcDateFromDDMMYYYY(
        event.target.value
      );

      if (!currentToDate) return;
      this.selectedToDate = event.target.value;
      this.setFromDateRangeForContainerMoves(this.selectedToDate);
    } else {
      return;
    }
  }

  private setToDateRangeForContainerMoves(
    selectedFromDate: string | undefined
  ) {
    if (!selectedFromDate && selectedFromDate === undefined) return;

    let currentFromDate =
      this._sharedDataService.getUtcDateFromDDMMYYYY(selectedFromDate);

    if (!currentFromDate && currentFromDate === undefined) return;

    this.minToDate = currentFromDate.toISOString().substring(0, 10);
    let maxToDate = this.addBufferDaysForContainerMoves(
      currentFromDate,
      this.containerJourneyDurationInDays
    );
    maxToDate = maxToDate > new Date() ? new Date() : maxToDate;
    this.maxToDate = maxToDate.toISOString().substring(0, 10);

    this.selectedToDate =
      currentFromDate! > maxToDate!
        ? maxToDate.toLocaleDateString('en-GB', this.formatDateOptions)
        : this.selectedToDate;
  }

  private setFromDateRangeForContainerMoves(
    selectedToDate: string | undefined
  ) {
    if (!selectedToDate && selectedToDate === undefined) return;

    let currentToDate =
      this._sharedDataService.getUtcDateFromDDMMYYYY(selectedToDate);

    if (!currentToDate && currentToDate === undefined) return;

    this.maxFromDate = currentToDate.toISOString().substring(0, 10);
    this.maxToDate = this.maxFromDate;
    let minFromDate = this.addBufferDaysForContainerMoves(
      currentToDate,
      -this.containerJourneyDurationInDays
    );
    this.minFromDate = minFromDate.toISOString().substring(0, 10);

    this.selectedFromDate =
      currentToDate! < minFromDate!
        ? minFromDate.toLocaleDateString('en-GB', this.formatDateOptions)
        : this.selectedFromDate;
  }

  private addBufferDaysForContainerMoves(
    selectedInputDate: Date,
    bufferDays: number
  ): Date {
    selectedInputDate.setUTCDate(selectedInputDate.getUTCDate() + bufferDays);
    return selectedInputDate;
  }

  onContainerMovesDataFetched($event: ContainerMoveDto) {}

  OnMovesRowSelectionChanged(
    event: [
      {
        [key: string]: GridCellData;
      },
    ]
  ) {}

  private dateDifferenceInDays(date1: Date, date2: Date): number {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in one day
    const diffInTime = date2.getTime() - date1.getTime(); // difference in milliseconds
    return Math.round(diffInTime / oneDay); // convert to days and round
  }
  /**
   * Sets from and to date for work order search
   */
  public validateAndSetSearchDateRange(type: string, event: any): boolean {
    let parsedDate = event.target.value
      ? this._sharedDataService.getUtcDateFromDDMMYYYY(event.target.value)
      : null;

    let parsedFromDate: Date | null | undefined = null;
    let parsedToDate: Date | null | undefined = null;

    if (type === 'from') {
      parsedFromDate = parsedDate;
      parsedToDate = this._sharedDataService.getUtcDateFromDDMMYYYY(
        this.selectedToDate!
      );
    } else if (type === 'to') {
      parsedToDate = parsedDate;
      parsedFromDate = this._sharedDataService.getUtcDateFromDDMMYYYY(
        this.selectedFromDate!
      );
    }

    this.invalidDateSelected =
      !parsedFromDate ||
      !parsedToDate ||
      parsedFromDate > parsedToDate ||
      this.dateDifferenceInDays(parsedFromDate, parsedToDate) > 50;

    this.invalidDateFormat = !parsedFromDate || !parsedToDate;

    if (this.invalidDateSelected || !this.containerNumber) return false;

    return true;
  }
}
