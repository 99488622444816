/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BooleanResponse } from '../../models/booleanResponse';
import { SendGridEmail } from '../../models/email';
import { UploadEmailRequest } from '../../models/uploadEmailRequest';

@Injectable()
export class NotificationService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Downloads the blob content.
   *
   * @param uri uri path of the blob
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public notificationDownloadEmailGet(
    uri: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public notificationDownloadEmailGet(
    uri: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public notificationDownloadEmailGet(
    uri: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public notificationDownloadEmailGet(
    uri: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (uri === null || uri === undefined) {
      throw new Error(
        'Required parameter uri was null or undefined when calling notificationDownloadEmailGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (uri !== undefined && uri !== null) {
      queryParameters = queryParameters.set('uri', <any>uri);
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request(
      'get',
      `${this.basePath}/notification/download-email`,
      {
        params: queryParameters,
        responseType: 'blob',
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Sends an email with sendgrid
   *
   * @param body email attributes
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public notificationSendEmailPost(
    body?: SendGridEmail,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public notificationSendEmailPost(
    body?: SendGridEmail,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public notificationSendEmailPost(
    body?: SendGridEmail,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public notificationSendEmailPost(
    body?: SendGridEmail,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'post',
      `${this.basePath}/notification/send-email`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * uploads the blob content.
   *
   * @param body type details of email to be uploaded
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public notificationUploadEmailPost(
    body?: UploadEmailRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public notificationUploadEmailPost(
    body?: UploadEmailRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public notificationUploadEmailPost(
    body?: UploadEmailRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public notificationUploadEmailPost(
    body?: UploadEmailRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request(
      'post',
      `${this.basePath}/notification/upload-email`,
      {
        body: body,
        responseType: 'text',
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
