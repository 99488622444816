import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DropDownOption,
  PanelComponent,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CollectionOfficeDto } from '../../../common/models/collectionOfficeDto';
import * as toastMessagesTemplate from '../../../common/toast-service-messages';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedDataService } from '../../../shared-data-service';
import { DcrpAuthorizationService } from '../../../common/services/authorization/dcrp-authorization.service';
import { InvoiceStatusEnum } from '../../../common/models/invoiceStatusEnum';
import { CreateInvoice } from '../../../common/models/createInvoice';

@Component({
  selector: 'invoice-letter',
  standalone: true,
  imports: [CommonModule, PanelComponent, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './invoice-letter.component.html',
  styleUrl: './invoice-letter.component.scss',
})
export class InvoiceLetterComponent implements OnInit {
  apiVersion: string = '1.0';
  collectionOfficeCode: string = '';
  closeCase: boolean = false;
  invoiceForm!: FormGroup;
  toastMessages = toastMessagesTemplate.default;
  ngOnInit() {
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.InvoiceLetterComponent,
      state: false,
    });
  }

  recoveryData$ = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._sharedRecoveryCaseService.disableForm$,
  ]).pipe(
    tap(([recoveryData, disable]) => {
      this.invoiceForm = new FormGroup({
        remarks: new FormControl({ value: '', disabled: disable }),
        collectionOffice: new FormControl({ value: '', disabled: disable }, [
          Validators.required,
        ]),
      });
    })
  );

  /**
   *Main observable Collection Office
   */
  collectionOffices$?: Observable<CollectionOfficeDto[] | undefined> =
    this._sharedDataService.commonCollectionOffices$;

  /**
   * observable holding dropdown value of templates
   */
  collectionOfficeDropDown$?: Observable<DropDownOption[] | null> =
    combineLatest([
      this._sharedDataService.commonCollectionOffices$,
      this._sharedDataService.carrierCodes$,
      this._sharedRecoveryCaseService.recoveryCaseData$,
    ]).pipe(
      switchMap(async ([collectionOffices, carrierCodes, recoveryData]) => {
        if (!collectionOffices) return null;

        const offices = collectionOffices.filter((collectionOffice) => {
          return (
            collectionOffice.coCountryCode ==
              recoveryData?.responsibleCountryCode &&
            collectionOffice.coOperator == recoveryData?.operatorCode
          );
        });
        const carrierCode = carrierCodes.find(
          (carrierCode) =>
            carrierCode.gcssCarrierCode == recoveryData?.operatorCode
        );

        if (carrierCode) {
          offices.push(
            ...collectionOffices.filter((collectionOffice) => {
              if (!collectionOffice) return [];
              return (
                collectionOffice.coCountryCode ==
                  recoveryData?.responsibleCountryCode &&
                collectionOffice.coOperator == carrierCode.operatorCode
              );
            })
          );
        }

        return offices?.map((office) => {
          return {
            label: office.coName,
            value: office.coNumber,
          } as DropDownOption;
        });
      })
    );

  factCode$: Observable<string | null> =
    this._sharedRecoveryCaseService.LiablePartyData$.pipe(
      map((liabilityParty) => {
        if (!liabilityParty) return '-';
        return liabilityParty.customBookingParty?.factCustomerCode ?? '-';
      })
    );

  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _toasterService: ToasterService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService
  ) {}

  onDrpDwnCollectionOfficeSelected(event: any) {
    this.collectionOfficeCode = event.detail.value;
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.InvoiceLetterComponent,
      state: true,
    });
  }

  onCollectionOfficeTextChange(event: Event) {
    if (!((event as InputEvent).target as HTMLInputElement).value)
      this._sharedRecoveryCaseService.updateFormValidationState({
        component: Components.InvoiceLetterComponent,
        state: false,
      });
  }

  async createInvoice() {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const factCode = await firstValueFrom(this.factCode$);
    const recoveryData = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );

    const invoiceCreationRequest = {
      caseId: recoveryData.caseId,
      factCode: factCode,
      factReferenceNumber: '',
      externalReferenceRemarks: this.invoiceForm?.get('remarks')?.value,
      collectionOfficeCode: this.collectionOfficeCode,
      version: await this.getNewInvoiceVersion(),
      invoiceStatusCode: InvoiceStatusEnum.InvoiceSubmitted,
      invoiceStatus: undefined,
    } as CreateInvoice;

    const invoiceDetailsPostResponse = await lastValueFrom(
      this._sharedRecoveryCaseService
        .postInvoiceDetails(invoiceCreationRequest, userId)
        .pipe(
          catchError((error) => {
            this._toasterService.showToast({
              message: this.toastMessages.invoicing.createNewInvoice,
              type: 'error',
            });
            throw error;
          })
        )
    );

    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.InvoiceLetterComponent,
      state: false,
    });

    this._toasterService.showToast({
      message: this.toastMessages.invoicing.createNewInvoice,
      type: 'success',
    });

    this._sharedRecoveryCaseService.reloadInvoices();
    this._sharedRecoveryCaseService.updateIssueInvoiceVisibility(false);
    return invoiceDetailsPostResponse;
  }

  async getNewInvoiceVersion() {
    let previousInvoices = await firstValueFrom(
      this._sharedRecoveryCaseService.invoices$
    );

    previousInvoices = this._sharedDataService.sortArrayByCreatedTimestamp(
      previousInvoices,
      false
    );

    if (!previousInvoices || previousInvoices.length === 0) return '001';

    const lastInvoice = previousInvoices[0];
    const newInvoice = Number(lastInvoice.version) + 1;
    return ('00' + newInvoice).slice(-3);
  }
}
