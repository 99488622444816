/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { BooleanResponse } from '../../models/booleanResponse';
import { InvoiceDetail } from '../../models/invoiceDetail';
import { InvoiceDetailIEnumerableResponse } from '../../models/invoiceDetailIEnumerableResponse';
import { InvoiceDetailResponse } from '../../models/invoiceDetailResponse';
import { environment } from '../../../../environments/environment';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { UpdateInvoice } from '../../models/updateInvoice';
import { CreateInvoice } from '../../models/createInvoice';

@Injectable()
export class InvoiceDetailService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * Get invoiceDetails, by caseId.
   * Sample request: Get/by-case/{caseId}
   * Returns bool: True for success and False for failure.
   * This endpoint supports the following HTTP status codes:
   * - 200 (OK): Invoice detail are deleted successfully.
   * - 400 (Bad Request): The request is invalid or record not deleted.
   * - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param caseId CaseId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public invoiceDetailsByCaseCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceDetailIEnumerableResponse>;
  public invoiceDetailsByCaseCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceDetailIEnumerableResponse>>;
  public invoiceDetailsByCaseCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceDetailIEnumerableResponse>>;
  public invoiceDetailsByCaseCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling invoiceDetailsByCaseCaseIdGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<InvoiceDetailIEnumerableResponse>(
      'get',
      `${this.basePath}/invoice-details/by-case/${encodeURIComponent(String(caseId))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete an invoiceDetail.
   * Sample request: Delete {id}
   * Returns bool: True for success and False for failure.
   * This endpoint supports the following HTTP status codes:
   * - 200 (OK): Invoice detail are deleted successfully.
   * - 400 (Bad Request): The request is invalid or record not deleted.
   * - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param id Identity
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public invoiceDetailsIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public invoiceDetailsIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public invoiceDetailsIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public invoiceDetailsIdDelete(
    id: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling invoiceDetailsIdDelete.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<BooleanResponse>(
      'delete',
      `${this.basePath}/invoice-details/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get an InvoiceDetail by Id.
   * Sample request: GET {id}
   * Returns the InvoiceDetail with the specified id.
   * This endpoint supports the following HTTP status codes:
   * - 200 (OK): Invoice detail are successfully retrieved.
   * - 404 (Not found): Invoice detail are not available for the id provided.
   * - 400 (Bad Request): The request is invalid or missing required parameters.
   * - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param id Identity
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public invoiceDetailsIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceDetailResponse>;
  public invoiceDetailsIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceDetailResponse>>;
  public invoiceDetailsIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceDetailResponse>>;
  public invoiceDetailsIdGet(
    id: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling invoiceDetailsIdGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<InvoiceDetailResponse>(
      'get',
      `${this.basePath}/invoice-details/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update an existing invoice
   * Sample request: Put updateInvoice
   * Returns bool: True for success and False for failure.
   * This endpoint supports the following HTTP status codes:
   * - 200 (OK): Invoice detail are updated successfully.
   * - 400 (Bad Request): The request is invalid or record not updated.
   * - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param id Invoice Id
   * @param body Update invoice request
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public invoiceDetailsIdPut(
    id: number,
    body?: UpdateInvoice,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceDetailResponse>;
  public invoiceDetailsIdPut(
    id: number,
    body?: UpdateInvoice,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceDetailResponse>>;
  public invoiceDetailsIdPut(
    id: number,
    body?: UpdateInvoice,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceDetailResponse>>;
  public invoiceDetailsIdPut(
    id: number,
    body?: UpdateInvoice,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling invoiceDetailsIdPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<InvoiceDetailResponse>(
      'put',
      `${this.basePath}/invoice-details/${encodeURIComponent(String(id))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create a new invoice.
   * Sample request: Post createInvoice
   * Returns bool: True for success and False for failure.
   * This endpoint supports the following HTTP status codes:
   * - 200 (OK): Invoice detail are added successfully.
   * - 400 (Bad Request): The request is invalid or record not created.
   * - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param body InvoiceDetail
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public invoiceDetailsPost(
    body?: CreateInvoice,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public invoiceDetailsPost(
    body?: CreateInvoice,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public invoiceDetailsPost(
    body?: CreateInvoice,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public invoiceDetailsPost(
    body?: CreateInvoice,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'post',
      `${this.basePath}/invoice-details`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Search invoiceDetails.
   * Sample request: Get/InvoiceSearchRequest
   * Returns bool: True for success and False for failure.
   * This endpoint supports the following HTTP status codes:
   * - 200 (OK): Invoice detail are deleted successfully.
   * - 400 (Bad Request): The request is invalid or record not deleted.
   * - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param caseId
   * @param version
   * @param invoiceNumber
   * @param invoiceStatusCode
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public invoiceDetailsSearchGet(
    aPIVersion?: string,
    caseId?: number,
    version?: string,
    invoiceNumber?: string,
    invoiceStatusCode?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceDetailIEnumerableResponse>;
  public invoiceDetailsSearchGet(
    aPIVersion?: string,
    caseId?: number,
    version?: string,
    invoiceNumber?: string,
    invoiceStatusCode?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceDetailIEnumerableResponse>>;
  public invoiceDetailsSearchGet(
    aPIVersion?: string,
    caseId?: number,
    version?: string,
    invoiceNumber?: string,
    invoiceStatusCode?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceDetailIEnumerableResponse>>;
  public invoiceDetailsSearchGet(
    aPIVersion?: string,
    caseId?: number,
    version?: string,
    invoiceNumber?: string,
    invoiceStatusCode?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (caseId !== undefined && caseId !== null) {
      queryParameters = queryParameters.set('CaseId', <any>caseId);
    }
    if (version !== undefined && version !== null) {
      queryParameters = queryParameters.set('Version', <any>version);
    }
    if (invoiceNumber !== undefined && invoiceNumber !== null) {
      queryParameters = queryParameters.set(
        'InvoiceNumber',
        <any>invoiceNumber
      );
    }
    if (invoiceStatusCode !== undefined && invoiceStatusCode !== null) {
      queryParameters = queryParameters.set(
        'InvoiceStatusCode',
        <any>invoiceStatusCode
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<InvoiceDetailIEnumerableResponse>(
      'get',
      `${this.basePath}/invoice-details/search`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
