import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  first,
  firstValueFrom,
  interval,
  lastValueFrom,
  map,
  of,
  scan,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CustomerRecoveryCaseDto } from '../../common/models/customerRecoveryCaseDto';
import { CustomerRecoveryClaimService } from '../../common/services/customer-recovery/customer-recovery-claim.service';
import { LiabilityLetterDto } from '../../common/models/liabilityLetterDto';
import { InvoicingStatus } from '../../common/models/invoicingStatus';
import { CustomerInfoDto } from '../../common/models/customerInfoDto';
import { LiablePartyDto } from '../../common/models/liabilePartyDto';
import { gcssBookingInfo } from '../../common/models/gcssBookingInfo';
import { CaseDamageDetailDto } from '../../common/models/caseDamageDetailDto';
import { CaseInvoiceDetailDto } from '../../common/models/caseInvoiceDetailDto';
import { ActivatedRoute } from '@angular/router';
import { CaseTypeEnum } from '../../common/constants/temporary-constant';
import { RepairLineItem } from '../../common/models/repairLineItem';
import { LiabilityLetterStatus } from '../../common/models/liabilityLetterStatus';
import { caseCpInformation } from '../../common/models/caseCpInformation';
import { WorkFlowStage } from '../../common/models/workflowStage';
import { FormValidation } from '../../common/models/formValidation';
import { ContainerMoveDto } from '../../common/models/containerMoveDto';
import { SharedDataService } from '../../shared-data-service';
import { ISharedRecoveryCaseService } from '../../shared-recovery-case-service';
import { DcrpAuthorizationService } from '../../common/services/authorization/dcrp-authorization.service';
import { ClaimReferenceDocumentDto } from '../../common/models/claimReferenceDocumentDto';
import { CaseDocumentService } from '../../common/services/case-document/case-document.service';
import { Inspection } from '../../common/models/inspection';
import { TemplateModel } from '@maersk-global/angular-shared-library';
import { InspectionDetailsDTO } from '../../common/models/inspectionDetailsDTO';
import { CaseActivityLogsDtoListResponse } from '../../common/models/caseActivityLogsDtoListResponse';
import { LiabilityLetter } from '../../common/models/liabilityLetter';
import { EmailService } from '../../common/services/email/email.service';
import { SendMailRequest } from '../../common/models/sendMailRequest';
import { ItemType } from '../../common/models/itemType';
import { InvoiceDetail } from '../../common/models/invoiceDetail';
import { CaseInvoiceDetailPostDto } from '../../common/models/caseInvoiceDetailPostDto';
import { CreateInvoice } from '../../common/models/createInvoice';
import { UpdateInvoice } from '../../common/models/updateInvoice';
import { CaseDetailsDto } from '../../common/models/caseDetailsDto';

@Injectable({
  providedIn: 'root',
})
export class SharedCustomerRecoveryCaseService
  implements ISharedRecoveryCaseService
{
  private apiVersion: string = '1.0';
  caseType: CaseTypeEnum | undefined;

  constructor(
    private _route: ActivatedRoute,
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService,
    private _caseDocumentService: CaseDocumentService,
    private __emailService: EmailService
  ) {}

  private manualEstimatesLineItems$$: BehaviorSubject<
    RepairLineItem[] | undefined
  > = new BehaviorSubject<RepairLineItem[] | undefined>([]);

  private incidentDateChange$$: BehaviorSubject<Date | null | undefined> =
    new BehaviorSubject<Date | null | undefined>(undefined);

  private customerDataSubject$$: BehaviorSubject<CustomerInfoDto> =
    new BehaviorSubject<CustomerInfoDto>({});

  private shouldShowLiabilityDetailsSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private reloadLiabilityLetters$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private shouldShowIssueInvoiceScreenSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private reloadInvoices$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private tabIndex$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private reloadDamageDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private reloadLiabilityPartyDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private currentStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  private reloadCustomerRecoveryData$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private workflowStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  private eirImageLastCheckedOn$$: BehaviorSubject<Date | undefined> =
    new BehaviorSubject<Date | undefined>(undefined);

  private formValidity$$: BehaviorSubject<FormValidation | null> =
    new BehaviorSubject<FormValidation | null>(null);

  private containerDetails$$: BehaviorSubject<
    ContainerMoveDto | null | undefined
  > = new BehaviorSubject<ContainerMoveDto | null | undefined>(undefined);

  private overviewDetails$$: BehaviorSubject<TemplateModel[]> =
    new BehaviorSubject<TemplateModel[]>([]);

  customerRecoveryCaseData$: Observable<CustomerRecoveryCaseDto | undefined> =
    combineLatest([
      this._route.queryParamMap,
      this.reloadCustomerRecoveryData$$.asObservable(),
    ]).pipe(
      switchMap(([params, reload]) => {
        const caseNumber = params.get('caseNumber') as string;
        const containerNumber = params.get('containerNumber') as string;
        if (!caseNumber) return of(undefined);
        if (!reload) this.resetCaseContext();
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseNumberGet(caseNumber, containerNumber)
          .pipe(map((response) => response.data?.customerRecoveryCaseDto));
      }),
      tap((recoveryData) => {
        if (!recoveryData) return;
        //set case type as case with WO or with out WO |Used for manual
        // case workflow
        this.workflowStageId$$.next(
          this.getStageId(recoveryData?.workFlowStage ?? 'New')
        );
        this.updateCaseType(recoveryData);
      }),
      shareReplay(1)
    );

  customerRecoveryCustomerData$: Observable<CustomerInfoDto> =
    this.customerDataSubject$$.asObservable().pipe(shareReplay(1));

  workflowStageId$: Observable<number> = this.workflowStageId$$.asObservable();

  currentStageId$: Observable<number> = this.currentStageId$$.asObservable();

  incidentDate$: Observable<Date | null | undefined> =
    this.incidentDateChange$$.asObservable();

  customerRecoveryEirImageLastFetchDate$: Observable<Date | undefined> =
    this.eirImageLastCheckedOn$$.asObservable();

  manualEstimatesLineItems$: Observable<RepairLineItem[] | undefined> =
    this.manualEstimatesLineItems$$.asObservable();

  customerRecoveryContainerMovesDetails$: Observable<
    ContainerMoveDto | null | undefined
  > = this.containerDetails$$.asObservable();

  currentCustomerRecoveryTabIndex$: Observable<number | 0> =
    this.tabIndex$$.asObservable();

  overviewDetails$: Observable<TemplateModel[]> =
    this.overviewDetails$$.asObservable();

  customerRecoveryLiablePartyData$: Observable<LiablePartyDto | undefined> =
    combineLatest([
      this.customerRecoveryCaseData$,
      this.reloadLiabilityPartyDetails$$.asObservable(),
    ]).pipe(
      switchMap(([customerRecoveryData]) => {
        if (!customerRecoveryData?.bookingNumber) return of(undefined);
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseNumberLiablePartyGet(
            customerRecoveryData.recoveryCaseNumber ?? '',
            this.apiVersion
          )
          .pipe(map((response) => response as LiablePartyDto));
      }),
      shareReplay(1)
    );

  cpInformation$ = combineLatest([
    this.customerRecoveryCaseData$,
    this.manualEstimatesLineItems$$.asObservable(),
  ]).pipe(
    map(([recoveryData, manualEstimates]) => {
      return this.fetchCpInformation(manualEstimates, recoveryData);
    }),
    shareReplay(1)
  );

  customerRecoveryLiabilityLetters$: Observable<
    Array<LiabilityLetterDto> | undefined
  > = combineLatest([
    this.customerRecoveryCaseData$,
    this.reloadLiabilityLetters$$.asObservable(),
  ]).pipe(
    switchMap(([customerRecoveryCase]) => {
      if (!customerRecoveryCase || !customerRecoveryCase.caseId)
        return of(undefined);
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsCaseIdLiabilityLettersGet(
          customerRecoveryCase?.caseId ?? 0,
          this.apiVersion
        )
        .pipe(map((response) => response.data));
    }),
    shareReplay(1)
  );

  shouldShowCustomerRecoveryLiabilityDetails$: Observable<boolean> =
    combineLatest([
      this.shouldShowLiabilityDetailsSubject$$.asObservable(),
      this.customerRecoveryLiabilityLetters$,
    ]).pipe(
      map(
        ([shouldShowLiabilityDetails, liabilityLetters]) =>
          shouldShowLiabilityDetails ||
          !liabilityLetters ||
          liabilityLetters.length === 0
      ),
      shareReplay(1)
    );

  customerRecoveryInvoices$: Observable<Array<InvoiceDetail> | undefined> =
    combineLatest([
      this.customerRecoveryCaseData$,
      this.reloadInvoices$$.asObservable(),
    ]).pipe(
      switchMap(([customerRecoveryCase]) => {
        if (!customerRecoveryCase || !customerRecoveryCase.caseId)
          return of(undefined);
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseIdInvoiceDetailsGet(
            customerRecoveryCase.caseId,
            this.apiVersion
          )
          .pipe(
            map((response) =>
              response.data?.map((invoice) =>
                this.convertCaseInvoiceDetailDtoToInvoiceDetail(invoice)
              )
            )
          );
      })
      //shareReplay(1)
    );

  shouldShowCustomerRecoveryIssueInvoiceScreen$: Observable<boolean> =
    combineLatest([
      this.shouldShowIssueInvoiceScreenSubject$$.asObservable(),
      this.customerRecoveryInvoices$,
      this._sharedDataService.countries$,
      this.customerRecoveryCaseData$,
    ]).pipe(
      map(
        ([
          shouldShowIssueInvoiceScreen,
          invoices,
          countries,
          customerRecoveryCase,
        ]) =>
          (shouldShowIssueInvoiceScreen ||
            !invoices ||
            invoices.length === 0) &&
          !!countries.find(
            (country) =>
              country.code === customerRecoveryCase?.podCountryCode &&
              country.isS4HanaMigrated
          )
      ),
      shareReplay(1)
    );

  private invoiceCreationPermissionInitial$: Observable<InvoicingStatus> =
    combineLatest([
      this.customerRecoveryCaseData$,
      this.customerRecoveryLiabilityLetters$,
    ]).pipe(
      switchMap(([customerRecoveryCase, letters]) => {
        if (
          !letters ||
          letters.length === 0 ||
          !customerRecoveryCase?.caseId ||
          !letters.find(
            (letter) =>
              letter.liabilityLetterStatus === 'ACCEPTED' ||
              letter.liabilityLetterStatus === 'NO_RESPONSE'
          )
        )
          return of({
            totalSecondsRemainingToInvoice: 0,
            isReadyToInvoice: false,
          });
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseIdIsReadyToInvoiceGet(
            customerRecoveryCase.caseId
          )
          .pipe(map((response) => response.data!));
      }),
      shareReplay(1)
    );

  customerRecoveryInvoiceCreationPermission$: Observable<
    InvoicingStatus & { displayMessage: string }
  > = combineLatest([
    this.invoiceCreationPermissionInitial$,
    this.invoiceCreationPermissionInitial$.pipe(
      switchMap((caseInvoiceStatus) => {
        return interval(1000).pipe(
          take((caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1) + 1)
        );
      })
    ),
  ]).pipe(
    map(([caseInvoiceStatus, seconds]) => {
      const displayMessage =
        this._sharedDataService.convertSecondsToDayHourMinSec(
          (caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1) - seconds
        );
      if (
        !caseInvoiceStatus.isReadyToInvoice &&
        seconds < (caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1)
      )
        return {
          totalSecondsRemainingToInvoice:
            caseInvoiceStatus.totalSecondsRemainingToInvoice,
          isReadyToInvoice: caseInvoiceStatus.isReadyToInvoice,
          displayMessage: displayMessage,
        };
      else
        return {
          totalSecondsRemainingToInvoice: 0,
          isReadyToInvoice:
            caseInvoiceStatus.isReadyToInvoice ||
            caseInvoiceStatus.totalSecondsRemainingToInvoice !== 0,
          displayMessage: displayMessage,
        };
    })
  );

  enableCloseBtn$: Observable<boolean> = combineLatest([
    this.customerRecoveryCaseData$,
    this.currentStageId$,
    this.customerRecoveryInvoices$,
  ]).pipe(
    map(
      ([recoveryData, stageId, invoices]) =>
        !(
          recoveryData?.recoveryStatusId == 6 ||
          recoveryData?.recoveryStatusId == 7 ||
          stageId == 5 ||
          (invoices && invoices.length > 0)
        )
    )
  );

  reopenCase$: Observable<boolean> = this.customerRecoveryCaseData$.pipe(
    map((recoveryData) => {
      if (
        recoveryData?.recoveryStatusId == 6 ||
        recoveryData?.recoveryStatusId == 7
      )
        return true;
      return false;
    })
  );

  disableForm$: Observable<boolean> = combineLatest([
    this._dcrpAuthorizationService.isAuthorizedFor('recovery-update'),
    this.customerRecoveryLiabilityLetters$,
    this.customerRecoveryCaseData$,
    this.currentStageId$,
  ]).pipe(
    map(([isAuthorizedForRecoveryUpdate, letters, recoveryData, stageId]) => {
      if (
        !isAuthorizedForRecoveryUpdate ||
        recoveryData?.recoveryStatusId == 6 ||
        recoveryData?.recoveryStatusId == 7
      ) {
        return true;
      }
      if ((stageId == 1 || stageId == 2) && letters && letters.length > 0) {
        letters?.sort(
          (letter1, letter2) =>
            new Date(letter2.createdDate ?? '').getTime() -
            new Date(letter1.createdDate ?? '').getTime()
        );

        return letters[0].liabilityLetterStatus ===
          LiabilityLetterStatus.REJECTED
          ? false
          : true;
      }
      return false;
    })
  );

  /**
   * We are calculating the accumulated state of the form validity. For e.g. If out of 2 forms 1 is valid and 1 is invalid,
   * the accumulated state would be invalid.
   */
  private collectiveFormValidation$ = this.formValidity$$.asObservable().pipe(
    withLatestFrom(this.currentStageId$, this.customerRecoveryCaseData$),
    map(([formValidation, currentStageId, customerRecoveryData]) =>
      formValidation
        ? {
            ...formValidation,
            stage: currentStageId,
            caseId: customerRecoveryData?.caseId,
          }
        : null
    ),
    scan((formValidations, currentState) => {
      if (!currentState) return [];
      const matchingState = formValidations.find(
        (state) =>
          state.component === currentState.component &&
          state.stage === currentState.stage &&
          state.caseId === currentState.caseId
      );
      if (matchingState) matchingState.state = currentState.state;
      else formValidations.push(currentState);
      return formValidations;
    }, [] as FormValidation[])
  );

  private formValidityForCurrentStage$ = combineLatest([
    this.currentStageId$,
    this.collectiveFormValidation$,
    this.customerRecoveryCaseData$,
  ]).pipe(
    map(([currentStageId, formValidations, customerRecoveryData]) =>
      formValidations
        .filter(
          (validations) =>
            validations.stage === currentStageId &&
            validations.caseId === customerRecoveryData?.caseId
        )
        .every((validation) => validation.state)
    )
  );

  enableNextBtn$: Observable<boolean> = combineLatest([
    this._dcrpAuthorizationService.isAuthorizedFor('recovery-update'),
    this.customerRecoveryCaseData$,
    this.currentStageId$,
    this.formValidityForCurrentStage$,
    this.workflowStageId$,
  ]).pipe(
    map(
      ([
        isAuthorizedForRecoveryUpdate,
        recoveryData,
        stageId,
        formValid,
        workflowStageId,
      ]) => {
        let enableFlag: boolean = true;
        if (
          recoveryData?.recoveryStatusId === 6 ||
          recoveryData?.recoveryStatusId === 7
        )
          enableFlag = enableFlag && stageId === workflowStageId ? false : true;
        if (stageId === 5) enableFlag = enableFlag && false;
        return enableFlag && formValid && isAuthorizedForRecoveryUpdate;
      }
    )
  );

  hideNextBtn$: Observable<boolean> = combineLatest([
    this._dcrpAuthorizationService.isAuthorizedFor('recovery-update'),
    this.currentStageId$,
    this.shouldShowCustomerRecoveryIssueInvoiceScreen$,
  ]).pipe(
    map(([isAuthorizedForRecoveryUpdate, stageId, showInvoiceScreen]) => {
      let hiddenFlag: boolean = false;
      if (
        (stageId === 4 && !showInvoiceScreen) ||
        !isAuthorizedForRecoveryUpdate
      )
        hiddenFlag = true;
      return hiddenFlag;
    })
  );

  customerRecoveryBookingCargoDetails$: Observable<
    gcssBookingInfo | undefined
  > = this.customerRecoveryCaseData$.pipe(
    switchMap((customerRecoveryDto) => {
      if (!customerRecoveryDto?.bookingNumber) return of(undefined);
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsCaseNumberBookingDetailsGet(
          customerRecoveryDto.recoveryCaseNumber ?? '',
          this.apiVersion
        )
        .pipe(
          map((response) => response.gcssBookingInfo!),
          catchError(() => {
            return of({
              bookingNumber: customerRecoveryDto.bookingNumber,
            } as gcssBookingInfo);
          })
        );
    }),
    shareReplay(1)
  );

  damageDetails$: Observable<CaseDamageDetailDto[] | undefined> = combineLatest(
    [this.customerRecoveryCaseData$, this.reloadDamageDetails$$.asObservable()]
  ).pipe(
    switchMap(([customerRecoveryData]) => {
      if (!customerRecoveryData || !customerRecoveryData.caseId)
        return of(undefined);
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsDamageDetailCaseIdGet(
          customerRecoveryData?.caseId ?? 0,
          this.apiVersion
        )
        .pipe(map((response) => response.data));
    }),
    shareReplay(1)
  );

  resetCaseContext() {
    this.workflowStageId$$.next(1);
    this.currentStageId$$.next(1);
    this.updateIncidentDate(undefined);
    this.updateContainerDetails(undefined);
  }

  updateFormValidationState(state: FormValidation) {
    this.formValidity$$.next(state);
  }

  reloadDamageDetails(): void {
    this.reloadDamageDetails$$.next(true);
  }

  reloadLiabilityPartyDetails(): void {
    this.reloadLiabilityPartyDetails$$.next(true);
  }

  reloadLiabilityLetters() {
    this.reloadLiabilityLetters$$.next(true);
  }

  reloadInvoices() {
    this.reloadInvoices$$.next(true);
  }

  updateLiabilityDetailsVisibility(value: boolean) {
    this.shouldShowLiabilityDetailsSubject$$.next(value);
  }

  updateIssueInvoiceVisibility(value: boolean) {
    this.shouldShowIssueInvoiceScreenSubject$$.next(value);
  }

  updateEirImageLastFetchOn(date: Date | undefined) {
    this.eirImageLastCheckedOn$$.next(date);
  }

  reloadRecoveryCaseData(): void {
    this.reloadCustomerRecoveryData$$.next(true);
  }

  updateCurrentStageId(stageId: number): void {
    this.currentStageId$$.next(stageId);
  }

  updateCustomerData(data: CustomerInfoDto): void {
    this.customerDataSubject$$.next(data);
  }

  updateTabSelected(tabIndex: number) {
    this.tabIndex$$.next(tabIndex);
  }

  updateIncidentDate(date: Date | null | undefined) {
    this.incidentDateChange$$.next(date);
  }

  updateManualEstimatesLineItems(lineItems: RepairLineItem[] | undefined) {
    this.manualEstimatesLineItems$$.next(lineItems);
  }

  updateContainerDetails(
    containerDetails: ContainerMoveDto | null | undefined
  ) {
    this.containerDetails$$.next(containerDetails);
  }

  async updateLiabilityLetter(
    selectedLetter: LiabilityLetter,
    liabilityLetterStatus: LiabilityLetterStatus
  ): Promise<boolean> {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const recoveryDataCaseID =
      (await firstValueFrom(this.customerRecoveryCaseData$))?.caseId ?? 0;
    await lastValueFrom(
      this._customerRecoveryClaimService
        .customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut(
          recoveryDataCaseID,
          selectedLetter.id ?? 0,
          {
            reason: selectedLetter.liabilityLetterResponseReason,
            status: liabilityLetterStatus,
            updatedBy: userId,
            updatedDate: new Date(),
          }
        )
        .pipe(
          catchError((error) => {
            return of(false);
          })
        )
    );
    return true;
  }

  async saveComment(comment: string): Promise<boolean> {
    let recoveryData = await firstValueFrom(this.customerRecoveryCaseData$);
    if (!recoveryData) return false;
    if (recoveryData.recoveryStatusId === 1) recoveryData.workFlowVersion = 1;
    recoveryData.updatedBy = sessionStorage.getItem('username') ?? '';
    recoveryData.comments = comment;
    const caseRequest = {
      customerRecoveryCaseDto: recoveryData,
    } as CaseDetailsDto;
    const response = await firstValueFrom(
      this._customerRecoveryClaimService
        .customerRecoveryClaimsUpdatePost(caseRequest, this.apiVersion)
        .pipe(
          map((res) => {
            if (!res) return false;
            return true;
          }),
          catchError((_) => {
            return of(false);
          })
        )
    );
    return response;
  }

  onResendEmailClicked(request: SendMailRequest): Observable<boolean> {
    return this.__emailService.emailPost(request).pipe(
      map(() => true),
      tap(() => this.reloadLiabilityLetters()),
      catchError((_) => {
        return of(false);
      })
    );
  }

  downloadLetter(emailBlobPath: string, caseNumber: string): Observable<Blob> {
    const fileName = emailBlobPath!
      .substring(emailBlobPath!.lastIndexOf('/') + 1)
      .split('?')[0];
    return this.__emailService
      .emailDownloadCaseNumberStorageTypesTypeFilesNameGet(
        caseNumber,
        ItemType.LiabilityLetter,
        fileName
      )
      .pipe(map((res) => res as Blob));
  }

  async saveDamageDetails(
    damages: TemplateModel[],
    caseId: number
  ): Promise<boolean> {
    const damageDetailRequest: CaseDamageDetailDto[] = [];
    const currentItems = damages;

    currentItems?.forEach((damage: TemplateModel) => {
      if (
        !damage.hidden &&
        damage.items &&
        this._sharedDataService.validateDamageLineItem(damage.items)
      )
        damageDetailRequest.push({
          description: damage.items?.filter((i) => i.name == 'description')[0]
            .value,
          amount: damage.items?.filter((i) => i.name == 'amount')[0].value,
          materialCode: damage.items?.filter((i) => i.name == 'materialCode')[0]
            .value,
          id: damage.items?.filter((i) => i.name == 'id')[0].value,
          caseId: caseId,
          amountInCaseCurrency: damage.items?.filter(
            (i) => i.name == 'amountInCaseCurrency'
          )[0].value,
        });
    });

    const saveSuccess = await lastValueFrom(
      this._customerRecoveryClaimService
        .customerRecoveryClaimsDamageDetailsSavePost(damageDetailRequest)
        .pipe(map((response) => response.isSuccess ?? false))
    );
    return saveSuccess;
  }

  updateCaseType(recoveryCase: CustomerRecoveryCaseDto) {
    if (recoveryCase.createdBy) {
      this.caseType =
        !recoveryCase.workOrderNumber &&
        !['sys', 'system'].includes(recoveryCase.createdBy.toLocaleLowerCase())
          ? CaseTypeEnum.CaseWithOutWorkOrder
          : CaseTypeEnum.CaseWithWorkOrder;
    } else {
      this.caseType = CaseTypeEnum.CaseWithWorkOrder;
    }
  }

  private fetchCpInformation(
    data: RepairLineItem[] | undefined,
    recoveryData: CustomerRecoveryCaseDto | undefined
  ): caseCpInformation {
    // Calculate total item cost in USD
    const totalItemCostUSD =
      data?.reduce(
        (total, lineItem) => total + (lineItem.lineTotalUSD ?? 0),
        0
      ) ?? 0;

    // Initialize CP information object
    const cpInformation: caseCpInformation = {};
    cpInformation.cpCoverageCurrency = recoveryData?.cpCoverageCurrency;

    // Handle non-USD coverage case
    if (
      !recoveryData ||
      recoveryData.cpCoverageCurrency?.toUpperCase() !== 'USD'
    ) {
      cpInformation.aboveCoverageCostUSD = 0;
      cpInformation.recoverableCostUSD = 0;
      cpInformation.cpCoverageAmountUSD = 0;
      cpInformation.cpCoverageAmount = recoveryData?.cpCoverageAmount;

      return cpInformation; // Early return if coverage is non-USD
    }

    // Populate CP information
    cpInformation.cpCoverageAmountUSD = recoveryData.cpCoverageAmount ?? 0;
    const aboveCoverageCost = Math.max(
      totalItemCostUSD - cpInformation.cpCoverageAmountUSD,
      0
    );

    // Set calculated values
    cpInformation.cpPlan = recoveryData.cpPurchased;
    cpInformation.aboveCoverageCostUSD = aboveCoverageCost;
    cpInformation.recoverableCostUSD = totalItemCostUSD;
    cpInformation.withinCoverageCostUSD = Math.min(
      cpInformation.cpCoverageAmountUSD,
      totalItemCostUSD
    );

    return cpInformation;
  }

  getWorkFlowStageById(claimId: number): WorkFlowStage {
    let stageName = WorkFlowStage.New;
    switch (claimId) {
      case 1:
        stageName = WorkFlowStage.New;
        break;
      case 2:
        stageName = WorkFlowStage.DamageEstimation;
        break;
      case 3:
        stageName = WorkFlowStage.Liability;
        break;
      case 4:
        stageName = WorkFlowStage.Invoice;
        break;
      case 5:
        stageName = WorkFlowStage.Complete;
    }
    return stageName as WorkFlowStage;
  }

  getStageId(status: WorkFlowStage): number {
    let caseStatusId = 1;
    switch (status) {
      case WorkFlowStage.New:
        caseStatusId = 1;
        break;
      case WorkFlowStage.DamageEstimation:
        caseStatusId = 2;
        break;
      case WorkFlowStage.Liability:
        caseStatusId = 3;
        break;
      case WorkFlowStage.Invoice:
        caseStatusId = 4;
        break;
      case WorkFlowStage.Complete:
        caseStatusId = 5;
        break;
    }
    return caseStatusId as number;
  }

  caseDocumentGet(
    caseId: number
  ): Observable<ClaimReferenceDocumentDto | null> {
    return this._caseDocumentService
      .customerRecoveryClaimsCaseIdCaseDocumentGet(caseId, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.claimReferenceDocuments) return null;
          return response.claimReferenceDocuments;
        })
      );
  }

  caseDocumentPost(
    imagesAssignedToCases: ClaimReferenceDocumentDto
  ): Observable<ClaimReferenceDocumentDto | null> {
    return this._caseDocumentService
      .customerRecoveryClaimsCaseDocumentPost(
        { claimReferenceDocumentDto: imagesAssignedToCases },
        this.apiVersion
      )
      .pipe(
        map((response) => {
          if (!response || !response.claimReferenceDocuments) return null;
          return response.claimReferenceDocuments;
        })
      );
  }

  caseDocumentDelete(documentId: number): Observable<boolean> {
    return this._caseDocumentService
      .customerRecoveryClaimsCaseDocumentDelete(
        {
          documentIds: [documentId],
        },
        this.apiVersion
      )
      .pipe(
        map((response) => {
          return response?.status?.statusCode === 200;
        })
      );
  }

  inspectionsGet(
    caseNumber: string,
    _containerNumber: string | undefined,
    incidentDate: Date | null,
    _workOrderDate: Date | null
  ): Observable<Array<Inspection> | null> {
    return this._customerRecoveryClaimService
      .customerRecoveryClaimsCaseNumberInspectionsGet(
        caseNumber,
        incidentDate,
        this.apiVersion
      )
      .pipe(
        map((response) => {
          if (!response || !response.inspections) return null;
          return response.inspections;
        })
      );
  }

  saveImageAvailability(
    inspectionInfo: InspectionDetailsDTO,
    caseNumber: string
  ): Observable<boolean> {
    return this._customerRecoveryClaimService
      .customerRecoveryClaimsCaseNumberImageAvailabilityPut(
        inspectionInfo,
        caseNumber,
        this.apiVersion
      )
      .pipe(
        map((response) => {
          if (!response || !response.data) return false;
          return response.data;
        })
      );
  }

  updateOverviewDetails(overview: TemplateModel[]) {
    this.overviewDetails$$.next(overview);
  }

  getActivityLogs(caseId: number): Observable<CaseActivityLogsDtoListResponse> {
    return this._customerRecoveryClaimService.customerRecoveryClaimsActivityLogsCaseIdGet(
      caseId,
      this.apiVersion
    );
  }

  postInvoiceDetails(
    createInvoice: CreateInvoice,
    userId: string
  ): Observable<boolean> {
    return this._customerRecoveryClaimService
      .customerRecoveryClaimsCaseIdInvoiceDetailsPost(
        createInvoice.caseId,
        this.convertInvoiceDetailToCaseInvoiceDetailPostDto(
          createInvoice,
          userId
        ),
        this.apiVersion
      )
      .pipe(
        map((response) => {
          if (!response || !response.data) return false;
          return response.data;
        })
      );
  }

  putInvoiceDetails(
    invoiceId: number,
    updateInvoice: UpdateInvoice,
    userId: string
  ): Observable<boolean> {
    return this._customerRecoveryClaimService
      .customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut(
        {
          reason: updateInvoice.cancellationReason,
          user: userId,
        },
        updateInvoice.caseId ?? 0,
        invoiceId ?? 0,
        this.apiVersion
      )
      .pipe(
        map((response) => {
          if (!response || !response.data) return false;
          return response.data;
        })
      );
  }

  /**
   * Converts CaseInvoiceDetailDto object to InvoiceDetail object
   * @param invoice Invoice entity of CaseInvoiceDetailDto type
   * @returns Invoice entity of InvoiceDetail type
   */
  convertCaseInvoiceDetailDtoToInvoiceDetail(invoice: CaseInvoiceDetailDto) {
    return {
      caseId: invoice.caseId,
      collectionOfficeCode: invoice.collectionOfficeCode,
      factCode: invoice.factCode,
      cancellationReason: invoice.cancellationReason,
      createdByUserId: invoice.createdBy,
      createdTimestamp: invoice.createdDate,
      dueDate: invoice.dueDate,
      factReferenceNumber: invoice.factReferenceNumber,
      id: invoice.caseInvoiceDetailId,
      invoiceNumber: invoice.invoiceNumber,
      invoiceStatusCode: invoice.invoiceStatusCode,
      paymentDate: invoice.paymentDate,
      remarks: invoice.remarks,
      soOrderNumber: invoice.soOrderNumber,
      soOrderStatus: invoice.soOrderStatus,
      updatedByUserId: invoice.updatedBy,
      updatedTimestamp: invoice.updatedDate,
      version: invoice.version,
      paidAmount: invoice.paidAmount,
      paymentCurrencyCode: invoice.paymentCurrencyCode,
      totalInvoiceAmountInCaseCurrency: invoice.invoicedAmountInCaseCurrency,
      totalInvoiceAmountInUSD: invoice.invoicedAmountInUSD,
    } as InvoiceDetail;
  }

  /**
   * Converts InvoiceDetail object to CaseInvoiceDetailPostDto object
   * @param invoice Object of InvoiceDetail type
   * @returns Object of CaseInvoiceDetailPostDto type
   */
  convertInvoiceDetailToCaseInvoiceDetailPostDto(
    invoice: CreateInvoice,
    userId: string
  ) {
    return {
      caseId: invoice.caseId,
      factCode: invoice.factCode,
      factReferenceNumber: invoice.factReferenceNumber,
      remarks: invoice.externalReferenceRemarks,
      collectionOfficeCode: invoice.collectionOfficeCode,
      version: invoice.version,
      userId: userId,
    } as CaseInvoiceDetailPostDto;
  }
}
