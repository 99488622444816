import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IMenuProvider, Menu } from '@maersk-global/angular-shared-library';
import { filter, map, Observable } from 'rxjs';

@Injectable()
export class SideNavMenuService implements IMenuProvider {
  constructor(private _router: Router) {}

  getMenuAsync(): Observable<Menu[]> {
    return this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: any) => {
        return [
          {
            displayName: 'Dashboard',
            icon: 'layout-2x2',
            showLabel: true,
            disabled: true,
          },
          {
            displayName: 'Customer',
            icon: 'user',
            showLabel: true,
            isSelected: event.urlAfterRedirects.includes('customer-recovery'),
            onClick: () => {
              this._router.navigate(['customer-recovery']);
            },
          },
          {
            displayName: 'Vendor',
            icon: 'wrench',
            showLabel: true,
            isSelected: event.urlAfterRedirects.includes('vendor-recovery'),
            onClick: () => {
              this._router.navigate(['vendor-recovery']);
            },
          },
          {
            displayName: 'Insurance',
            icon: 'shield-check',
            showLabel: true,
            disabled: true,
          },
        ];
      })
    );
  }
}
