/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { BookingTransportPlan } from '../../models/bookingTransportPlan';

@Injectable()
export class BookingService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Retrieves transport plan for a booking
   *   This endpoint supports the following HTTP status codes    - 200 (OK): Transport plan is successfully retrieved.    - 400 (Bad Request): Request parameters are invalid or missing.    - 404 (Not Found): Transport plan is not available for requested search parameters.    - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param bookingNumber Booking number for which transport plan needs to be fetched
   * @param carrierCode Carrier code for which transport plan needs to be fetched
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public bookingTransportPlanGet(
    bookingNumber: string,
    carrierCode: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BookingTransportPlan>;
  public bookingTransportPlanGet(
    bookingNumber: string,
    carrierCode: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BookingTransportPlan>>;
  public bookingTransportPlanGet(
    bookingNumber: string,
    carrierCode: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BookingTransportPlan>>;
  public bookingTransportPlanGet(
    bookingNumber: string,
    carrierCode: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bookingNumber === null || bookingNumber === undefined) {
      throw new Error(
        'Required parameter bookingNumber was null or undefined when calling bookingTransportPlanGet.'
      );
    }

    if (carrierCode === null || carrierCode === undefined) {
      throw new Error(
        'Required parameter carrierCode was null or undefined when calling bookingTransportPlanGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (bookingNumber !== undefined && bookingNumber !== null) {
      queryParameters = queryParameters.set(
        'bookingNumber',
        <any>bookingNumber
      );
    }
    if (carrierCode !== undefined && carrierCode !== null) {
      queryParameters = queryParameters.set('carrierCode', <any>carrierCode);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<BookingTransportPlan>(
      'get',
      `${this.basePath}/booking/transport-plan`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
