<ng-container *ngIf="templateModelForm$ | async as templates">
  <lib-panel [hideHeader]="true">
    <content>
      @for (liableItem of templates; track liableItem; let index = $index) {
        <mc-notification
          *ngIf="index == 0 && !validFactCode"
          body="The fact code entered/selected is not matching with the case country, please select a different liable party as per case country or change the case country appropriately."
          appearance="error">
          <span slot="icon">
            <mc-icon icon="exclamation-triangle"></mc-icon>
          </span>
        </mc-notification>
        @if (liableItem.items && !liableItem.hidden) {
          <div class="section" *ngIf="index != 0"></div>
          <app-lib-form
            [items]="liableItem.items"
            (isValidChange)="formValidityChanged($event)"></app-lib-form>
        }
      }
    </content>
  </lib-panel>
</ng-container>
