/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LiabilityLetter } from '../../models/liabilityLetter';
import { SaveLiabilityLetter } from '../../models/saveLiabilityLetter';
import { UpdateLiabilityLetterStatusVendor } from '../../models/updateLiabilityLetterStatusVendor';

@Injectable()
export class LiabilityLetterService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }
  /**
   * returns list of all liability letters
   *
   * @param recoveryCaseId Recovery case id
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityLetterGetAllLiabilityLettersRecoveryCaseIdGet(
    recoveryCaseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<LiabilityLetter>>;
  public liabilityLetterGetAllLiabilityLettersRecoveryCaseIdGet(
    recoveryCaseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<LiabilityLetter>>>;
  public liabilityLetterGetAllLiabilityLettersRecoveryCaseIdGet(
    recoveryCaseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<LiabilityLetter>>>;
  public liabilityLetterGetAllLiabilityLettersRecoveryCaseIdGet(
    recoveryCaseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (recoveryCaseId === null || recoveryCaseId === undefined) {
      throw new Error(
        'Required parameter recoveryCaseId was null or undefined when calling liabilityLetterGetAllLiabilityLettersRecoveryCaseIdGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<LiabilityLetter>>(
      'get',
      `${this.basePath}/liability-letter/get-all-liability-letters/${encodeURIComponent(String(recoveryCaseId))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Save new liability letter
   *
   * @param body new liability letter details
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityLetterPost(
    body: SaveLiabilityLetter,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<LiabilityLetter>>;
  public liabilityLetterPost(
    body: SaveLiabilityLetter,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<LiabilityLetter>>>;
  public liabilityLetterPost(
    body: SaveLiabilityLetter,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<LiabilityLetter>>>;
  public liabilityLetterPost(
    body: SaveLiabilityLetter,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling liabilityLetterSaveLiabilityLetterPost.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<Array<LiabilityLetter>>(
      'post',
      `${this.basePath}/liability-letter`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   * Updates status of liability letter
   *
   * @param body updated details of liability letter status
   * @param id Unique Identifier
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public liabilityLetterIdPut(
    body: UpdateLiabilityLetterStatusVendor,
    id: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<LiabilityLetter>>;
  public liabilityLetterIdPut(
    body: UpdateLiabilityLetterStatusVendor,
    id: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<LiabilityLetter>>>;
  public liabilityLetterIdPut(
    body: UpdateLiabilityLetterStatusVendor,
    id: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<LiabilityLetter>>>;
  public liabilityLetterIdPut(
    body: UpdateLiabilityLetterStatusVendor,
    id: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling liabilityLetterIdPut.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling liabilityLetterIdPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<Array<LiabilityLetter>>(
      'put',
      `${this.basePath}/liability-letter/${encodeURIComponent(String(id))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
