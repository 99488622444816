import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ALIGN,
  GridComponent,
  GridRowData,
  GridCellData,
  GridColumnSchema,
  LibFormComponent,
  SizeUnit,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  merge,
  of,
  tap,
} from 'rxjs';
import { ContainerMove } from '../../../common/models/containerMove';
import { ContainersService } from '../../../common/services/container/containers.service';
import { ContainerMoveDto } from '../../../common/models/containerMoveDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { SharedDataService } from '../../../shared-data-service';
@Component({
  selector: 'container-moves',
  standalone: true,
  imports: [LibFormComponent, CommonModule, GridComponent],
  templateUrl: './container-moves.component.html',
  styleUrl: './container-moves.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContainerMovesComponent {
  @Input() loadData: boolean | undefined;
  @Input({ required: true }) item!: TemplateModel;
  @Input() fromDate!: string;
  @Input() toDate!: string;
  @Input() containerNumber: string | undefined;
  @Input() disable?: boolean | false;
  @Input() showSelection!: boolean | true;
  @Output() onRowSelected = new EventEmitter<
    [{ [key: string]: GridCellData }]
  >();
  @Output() onDataLoad = new EventEmitter<ContainerMoveDto>();

  gridData: GridRowData[] | null = null;
  gridSchema: GridColumnSchema[] = [];
  Schema: GridColumnSchema[] = [];
  containerMovesSchema: GridColumnSchema[] = [];
  containerMovesGridData$?: Observable<GridRowData[] | null>;
  showLoader: boolean = true;
  showRowSelector: boolean = true;

  /**
   * Height is required to show scrollbars for the grid. If not provided, grid height will be 100% (i.e. it will wrap all records init).
   */
  height: SizeUnit = {
    size: 18,
    unit: 'vh',
  };

  /**
   * Subject to hold the latest list of moves.
   */
  movesSubject$$: BehaviorSubject<GridRowData[] | null> = new BehaviorSubject<
    GridRowData[] | null
  >([]);
  /**
   * Initial list of moves.
   */
  movesInitial$?: Observable<GridRowData[] | null>;
  constructor(
    private _containersService: ContainersService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.loadContainerMoves();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showLoader = true;
    this.movesSubject$$.next(null);
    if (changes['item'] && changes['item'].currentValue) {
      this.loadSchema();
    }
    this.loadContainerMoves();
  }

  loadSchema() {
    if (this.item && this.item.items) {
      this.gridSchema = this.item.items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label,
          align: ALIGN.LEFT,
          hidden: y.hidden ?? false,
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          disableSort: true,
          filterGroupId: 0,
        } as GridColumnSchema;
        return column;
      });
    }
  }

  loadContainerMoves() {
    this.showRowSelector = this.showSelection;
    if (this.showRowSelector === undefined) {
      this.showRowSelector = true;
    }

    const { fromDate, toDate, containerNumber } = this;
    const parsedFromDate = fromDate
      ? this._sharedDataService.getUtcDateFromDDMMYYYY(fromDate)
      : null;
    let parsedToDate = toDate
      ? this._sharedDataService.getUtcDateFromDDMMYYYY(toDate)
      : null;

    if (!parsedFromDate || !parsedToDate || !containerNumber) return;

    //Checking that To date should not be greater than current time
    parsedToDate = this._sharedDataService.changeUtcTime(
      parsedToDate,
      23,
      59,
      59
    );
    if (parsedToDate > new Date()) {
      parsedToDate = new Date();
    }

    this.movesInitial$ = this._containersService
      .containersContainerNumberMovesGet(
        containerNumber,
        parsedFromDate,
        parsedToDate
      )
      .pipe(
        map((response) => {
          const { data } = response;
          this.onDataLoad.emit(data);
          this._sharedRecoveryCaseService.updateContainerDetails(data);

          if (
            !data ||
            !data.containerMoves ||
            data.containerMoves.length == 0
          ) {
            this.height.size = 18;
            return null;
          }
          this.height.size = 12;
          return data.containerMoves.map((move) =>
            this.generateGridDataFromContainerMoves(move)
          );
        }),
        tap((moves) => {
          this.showLoader = false;
          this.movesSubject$$.next(moves);
        }),
        catchError(() => {
          this.showLoader = false;
          return of(null);
        })
      );

    this.containerMovesGridData$ = merge(
      this.movesInitial$,
      this.movesSubject$$.asObservable()
    );
  }

  private generateGridDataFromContainerMoves(
    containerMove: ContainerMove
  ): GridRowData {
    const customerRecoveryKeyValue = containerMove as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(containerMove).map((key) => {
      gridRowObject[key] = {
        disabled: this.disable,
        value: customerRecoveryKeyValue[key],
      } as GridCellData;
    });
    return {
      row: gridRowObject,
      hideRowSelector:
        !containerMove.bookingNumber ||
        !containerMove.placeOfDelivery ||
        !containerMove.activityLocation, //For hiding radio button
    } as GridRowData;
  }

  rowSelectionChanged(moves: { [key: string]: unknown }[]) {
    if (!moves || moves.length == 0) return;
    this.onRowSelected.emit(moves as [{ [key: string]: GridCellData }]);
  }
}
