import { Injectable } from '@angular/core';
import { AuthenticationService } from '@maersk-global/angular-shared-library';
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { User } from '../../models/user';
import * as extraPolicies from './extra-authorization-policies';
import { AuthService } from './auth.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class DcrpAuthorizationService {
  constructor(
    private _authenticationService: AuthenticationService,
    private _authService: AuthService,
    private _userService: UserService
  ) {}

  /**
   * ForgeRock logged in user.
   */
  loggedInUser$ = this._authenticationService.user$.pipe(
    tap((user) => {
      // TODO: this is temporarily added via sessionStorage, We have to directly use `_authenticationService.user$` in all the components instead.
      window.sessionStorage.setItem('userId', user.uniqueId);
      window.sessionStorage.setItem('username', user.uniqueId);
      window.sessionStorage.setItem('email', user.email);
    }),
    shareReplay(1)
  );

  /**
   * DCRP user from database against the ForgeRock user.
   */
  dcrpUser$: Observable<User | undefined> = this.loggedInUser$.pipe(
    switchMap((user) =>
      this._userService
        .userGet(user.firstName, user.lastName, user.email, user.uniqueId)
        .pipe(tap((re) => console.log(re)))
    ),
    map((dcrpUsers) => dcrpUsers.data?.filter((user) => user.isActive)[0]),
    shareReplay(1)
  );

  /**
   * Auth policies and inclusive roles.
   */
  policies$ = this._authService.authPoliciesGet().pipe(
    map((response) => [
      ...(response.data ?? []),
      ...extraPolicies.default.extraPolicies,
    ]),
    shareReplay(1)
  );

  /**
   * This method checks if the provided context is available for the user's role or not.
   * @param context policy context
   * @returns boolean observable
   */
  isAuthorizedFor(context: string | undefined) {
    return new Observable<boolean>((subscriber) => {
      const localSubscription = combineLatest([
        this.dcrpUser$,
        this.policies$,
      ]).subscribe(([user, policies]) => {
        const userRole = user?.userRoles?.find((role) => role.role.isActive)
          ?.role.roleName;
        if (!userRole || !context) subscriber.next(false);
        else
          subscriber.next(
            policies
              ?.find((policy) => policy.policyName == context)
              ?.roles?.includes(userRole)
          );
      });

      return () => {
        localSubscription.unsubscribe();
      };
    });
  }
}
