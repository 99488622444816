<app-grid
  [schema]="gridSchema"
  [data]="containerMovesGridData$ | async"
  [showPanel]="false"
  [showRowSelector]="showRowSelector"
  [showLoader]="showLoader"
  [showPagination]="false"
  [rowSelectorType]="'radio'"
  [height]="height"
  (rowSelectionChange)="rowSelectionChanged($event)"></app-grid>
