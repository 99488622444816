import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WorkOrderListComponent } from '../../custom-workflow/work-order-list/work-order-list.component';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { AttachmentComponent } from '../../custom-workflow/attachment/attachment.component';
import { OverviewComponent } from '../../custom-workflow/overview/overview.component';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { ContainerMoveDto } from '../../../common/models/containerMoveDto';
import {
  Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  forkJoin,
  lastValueFrom,
  map,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { LiabilityDetailsComponent } from '../../custom-workflow/liability-details/liability-details.component';
import { DamageDetailsComponent } from '../../custom-workflow/damage-details/damage-details.component';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { Router } from '@angular/router';
import { ActivityLogComponent } from '../../custom-workflow/activity-log/activity-log.component';
import {
  GridCellData,
  Loader,
  LoaderService,
  PageSkeletonLoaderComponent,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { WorkOrderAndLineItemsDto } from '../../../common/models/workOrderAndLineItemsDto';
import { ClaimStatusRequest } from '../../../common/models/claim-status-request';
import { CompletedComponent } from '../../custom-workflow/completed/completed.component';
import { CaseAssignmentStatusResponse } from '../../../common/models/caseAssignmentStatusResponse';
import { CancelReasonsList } from '../../../common/constants/temporary-constant';
import { ClaimStatusResponse } from '../../../common/models/claim-status-response';
import { StepperComponent } from '../../custom-workflow/stepper/stepper.component';
import { recoveryTab } from '../../../common/enum/recovery-tab';
import { SharedVendorRecoveryCaseService } from '../shared-vendor-recovery-case.service';
import { SharedDataService } from '../../../shared-data-service';
import * as template from '../../../../assets/json/dcrp-vendor-workflow.json';
import { VendorRecoverableCostComponent } from '../../custom-workflow/vendor-recoverable-cost/vendor-recoverable-cost.component';
import { RecoveryCase } from '../../../common/models/recoveryCase';
import { DcrpAuthorizationService } from '../../../common/services/authorization/dcrp-authorization.service';
import { ContainerMovesSearchComponent } from '../../custom-workflow/container-moves-search/container-moves-search.component';
import { LiabilityPartyVendorDetailsComponent } from '../../custom-workflow/liability-party-vendor-details/liability-party-vendor-details.component';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { LiabilityPartyVendorLetterComponent } from '../../custom-workflow/liability-party-vendor-letter/liability-party-vendor-letter.component';
import { LiabilityLettersLogComponent } from '../../custom-workflow/liability-letters-log/liability-letters-log.component';
import { RecoveryCaseStatus } from '../../../common/models/recoveryCaseStatus';
import { RecoveryCaseService } from '../../../common/services/recovery/recovery.service';
import { VendorRecoveryCase } from '../../../common/models/vendorRecoveryCase';
import { InvoiceSummaryComponent } from '../../custom-workflow/invoice-summary/invoice-summary.component';
import { InvoiceLetterComponent } from '../../custom-workflow/invoice-letter/invoice-letter.component';
import { InvoiceLettersLogComponent } from '../../custom-workflow/invoice-letters-log/invoice-letters-log.component';
import { InvoiceStatusEnum } from '../../../common/models/invoiceStatusEnum';
import { IsAuthorizedForDirective } from '../../../common/directives/is-authorized-for.directive';
@Component({
  selector: 'vendor-recovery-orchestrator',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WorkOrderListComponent,
    StepperComponent,
    AttachmentComponent,
    DamageDetailsComponent,
    ActivityLogComponent,
    PageSkeletonLoaderComponent,
    VendorRecoverableCostComponent,
    ContainerMovesSearchComponent,
    LiabilityPartyVendorDetailsComponent,
    LiabilityPartyVendorLetterComponent,
    LiabilityLettersLogComponent,
    InvoiceSummaryComponent,
    InvoiceLetterComponent,
    InvoiceLettersLogComponent,
    IsAuthorizedForDirective,
  ],
  templateUrl: './vendor-recovery-orchestrator.component.html',
  styleUrl: './vendor-recovery-orchestrator.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VendorRecoveryOrchestratorComponent {
  stages: TemplateModel[] | undefined;
  currentStageId: number = 1;
  caseDetails!: RecoveryCase;
  apiVersion: string = '1.0';
  caseNumber: string = '';
  currentTabIndex: number = 0;
  tabItems?: TemplateModel[];
  nextStageId: number = 0;
  loadTab: boolean = true;
  closeCase: boolean = false;
  cancelReasons: string[] = CancelReasonsList;
  currentStageItem: TemplateModel[] = [];
  closeCaseForm: FormGroup = new FormGroup({
    closeReason: new FormControl('', { updateOn: 'change' }),
    comments: new FormControl('', { updateOn: 'change' }),
  });
  closedStatusId: number = 6;
  isSaveOperationInProgress: boolean = false;
  OTHERS: string = 'Others';
  closeReason: string = '';
  workOrders: WorkOrderAndLineItemsDto[] = [];
  finalRecoverableCostUsd = 0.0;
  finalRecoverableCostLocal = 0.0;
  containerMovesFromDate: string | undefined;
  containerMovesToDate: string | undefined;
  containerNumber: string | undefined;
  selectedFromDate: string | undefined;
  selectedToDate: string | undefined;
  disable: boolean = false;
  containerMovesItem!: TemplateModel;
  showContainerMoves: boolean = true;
  containerMovesDtoDetails: ContainerMoveDto | undefined;
  invalidDateSelected: boolean = false;
  liabilityEmailContent?: SendMailRequest;
  shouldShowIssueInvoiceScreen$: Observable<boolean> =
    this._sharedRecoveryCaseService.shouldShowIssueInvoiceScreen$;

  tabName$: Observable<string> =
    this._vendorRecoverySharedService.currentVendorRecoveryTabIndex$.pipe(
      map((index) => recoveryTab[index])
    );

  constructor(
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _loader: LoaderService,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _vendorRecoverySharedService: SharedVendorRecoveryCaseService,
    private _toaster: ToasterService,
    private _dcrpAuthorizationService: DcrpAuthorizationService,
    private _recoveryService: RecoveryCaseService
  ) {}

  disableForm$: Observable<boolean> =
    this._vendorRecoverySharedService.disableForm$;
  enableCloseBtn$: Observable<boolean> =
    this._vendorRecoverySharedService.enableCloseBtn$;
  enableNextBtn$: Observable<boolean> =
    this._vendorRecoverySharedService.enableNextBtn$;
  hideNextBtn$: Observable<boolean> =
    this._vendorRecoverySharedService.hideNextBtn$;

  @ViewChild('damageDetails', { static: false })
  damageDetailsComponent!: DamageDetailsComponent;

  @ViewChild('invoice', { static: false })
  invoiceLetterComponent!: InvoiceLetterComponent;

  @ViewChild('letterComponent', { static: false })
  letterComponent!: LiabilityPartyVendorLetterComponent;

  @ViewChild('party', { static: false })
  partyComponent!: LiabilityPartyVendorDetailsComponent;

  @ViewChild('recoverableCost', { static: false })
  recoverableCostComponent!: VendorRecoverableCostComponent;

  @ViewChild('workOrderListComponent', { static: false })
  workOrderListComponent!: WorkOrderListComponent;

  shouldShowLiabilityDetails$: Observable<boolean> =
    this._sharedRecoveryCaseService.shouldShowLiabilityDetails$;

  vendorRecoveryData$ = this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
    tap((recoveryData) => {
      //updating Current StageId on initial load only once.
      this.caseDetails = recoveryData!;
      this.containerNumber = recoveryData?.equipmentNumber;
      this.currentStageId = this.caseDetails.recoveryStatusId ?? 1;

      this._vendorRecoverySharedService.updateCurrentStageId(
        this.currentStageId as number
      );
      this.loadTabInitialData(this.currentStageId);
      if (!this.selectedFromDate || !this.selectedToDate)
        this.setDateRangeForContainerMoves();
    })
  );

  currentStageId$ = this._sharedRecoveryCaseService.currentStageId$.pipe(
    shareReplay(1),
    tap((newStageId) => {
      this.currentStageId = newStageId;
      this.loadTabInitialData(newStageId);
    })
  );

  isCountryS4HanaMigrated$: Observable<boolean> = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._sharedDataService.countries$,
  ]).pipe(
    map(
      ([recoveryData, countries]) =>
        !!countries.find(
          (country) =>
            country.code === recoveryData?.responsibleCountryCode &&
            country.isS4HanaMigrated
        )
    )
  );

  onNavigateBackToCaseList() {
    this._router.navigate(['vendor-recovery']);
  }

  setDateRangeForContainerMoves() {
    let dateToFetchMoves = new Date();
    let toDatePlusVal = 10;

    if (
      this.caseDetails?.workOrderDate &&
      this.caseDetails?.workOrderDate.toString() != '0001-01-01T00:00:00'
    ) {
      dateToFetchMoves = new Date(this.caseDetails.workOrderDate);
    } else if (
      this.caseDetails?.dateOfIncident &&
      this.caseDetails.dateOfIncident.toString() != '0001-01-01T00:00:00'
    ) {
      dateToFetchMoves = new Date(this.caseDetails.dateOfIncident);
    } else {
      toDatePlusVal = 0;
    }

    const toDate = new Date(dateToFetchMoves.getTime());
    const fromDate = new Date(dateToFetchMoves.getTime());

    toDate.setDate(toDate.getDate() + toDatePlusVal);
    fromDate.setDate(fromDate.getDate() - 30);

    const formatDateOptions = {
      timeZone: 'UTC',
    };

    this.selectedFromDate = fromDate.toLocaleDateString(
      'en-GB',
      formatDateOptions
    );
    this.selectedToDate = toDate.toLocaleDateString('en-GB', formatDateOptions);

    this.containerMovesFromDate = this.selectedFromDate;
    this.containerMovesToDate = this.selectedToDate;
  }

  OnDateSelectHandler(type: string, event: any) {
    if (type == 'from') {
      this.selectedFromDate = event.target.value;
    } else {
      this.selectedToDate = event.target.value;
    }
  }

  private isValidDateFormat(dateString: string): boolean {
    // Regular expression for dd/mm/yyyy format
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    // Check if the input string matches the regex
    const isValid = regex.test(dateString);

    return isValid;
  }

  loadTabInitialData(stageId: number): void {
    this.bindVendorRecoveryTemplate();
    this.currentTabIndex = 0;
    const caseStageTemplate = this.stages?.filter(
      (i) => i.id === stageId
    )[0] as TemplateModel;
    this.currentStageItem =
      caseStageTemplate.items?.filter((i) => i.name === 'tab')[0].items ?? [];
    if (this.currentStageItem)
      this.tabItems = this.currentStageItem[this.currentTabIndex].items;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == stageId)[0].sequence ?? 0;
    if (
      this.stages &&
      (this.stages[this.stages.length - 1].sequence ?? 0) > currentStageSequence
    )
      this.nextStageId =
        this.stages?.filter((i) => i.sequence == currentStageSequence + 1)[0]
          .id ?? 1;
  }

  async onNextClick() {
    const disableForm = await firstValueFrom(
      this._vendorRecoverySharedService.disableForm$
    );
    const shouldShowLiabilityDetails =
      (await firstValueFrom(this.shouldShowLiabilityDetails$)) ?? false;
    if (disableForm) {
      this.loadNextStage();
      return;
    }
    this.isSaveOperationInProgress = true;
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);

    const shouldShowIssueInvoiceScreen =
      (await firstValueFrom(this.shouldShowIssueInvoiceScreen$)) ?? false;

    const isCountryS4HanaMigrated =
      (await firstValueFrom(this.isCountryS4HanaMigrated$)) ?? false;

    // Adding this try catch to make sure we will always have the next button enabled if save operation fails.
    // TODO: The error handling needs to be re-looked for all the API calls.
    try {
      if (this.currentStageId === 1) {
        await this.stageOverviewSaveAndSubmit();
        await this.stageSubmit(RecoveryCaseStatus.Liability);
      } else if (this.currentStageId === 2 && shouldShowLiabilityDetails) {
        await this.stageLiabilitySave();
      } else if (this.currentStageId === 2 && !shouldShowLiabilityDetails) {
        await this.stageSubmit(RecoveryCaseStatus.Invoice);
      } else if (this.currentStageId === 3 && !isCountryS4HanaMigrated) {
        await this.stageSubmit(RecoveryCaseStatus.Complete);
      } else if (
        this.currentStageId === 3 &&
        shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        await this.createInvoice();
      } else if (
        this.currentStageId === 3 &&
        !shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        let latestInvoices = await firstValueFrom(
          this._sharedRecoveryCaseService.invoices$
        );
        latestInvoices =
          this._sharedDataService.sortArrayByCreatedTimestamp(
            latestInvoices,
            false
          ) ?? [];

        if (
          latestInvoices[0].invoiceStatusCode &&
          [
            InvoiceStatusEnum.InvoiceIssued,
            InvoiceStatusEnum.PaymentReceived,
          ].includes(latestInvoices[0].invoiceStatusCode)
        ) {
          await this.stageSubmit(RecoveryCaseStatus.Complete);
        } else {
          this._toaster.showToast({
            message:
              'You can only complete the stage if the invoice has been issued or the payment has been received.',
            type: 'warning',
          });
        }
      }
    } catch (error) {
      console.error('Error occurred while saving data');
    } finally {
      this.isSaveOperationInProgress = false;
      this._loader.hideLoader();
    }
  }

  async stageSubmit(caseStatus: RecoveryCaseStatus) {
    const workflowStageId = await firstValueFrom(
      this._vendorRecoverySharedService.workFlowStageId$
    );
    if (this.currentStageId == workflowStageId) {
      // TODO: Update recoveryStatusID and workflow Stage Id, to add workflowStageId
      let vendorData = (await firstValueFrom(
        this._vendorRecoverySharedService.vendorRecoveryCaseData$
      )) as VendorRecoveryCase;
      vendorData.caseStatus = caseStatus;
      const response = await firstValueFrom(
        this._recoveryService.recoveryCasesCaseIdPut(
          vendorData.id ?? 0,
          vendorData
        )
      );
      if (response.isSuccess) {
        this._sharedRecoveryCaseService.reloadRecoveryCaseData();
      }
    } else if (this.currentStageId < workflowStageId) {
      this.loadNextStage();
    }
  }

  async stageLiabilitySave() {
    const response = await this.partyComponent.saveLiabilityParties();
    if (response) {
      await this._sharedRecoveryCaseService.reloadLiabilityPartyDetails();
      await this.letterComponent.sendMail();
    }
  }

  async stageOverviewSaveAndSubmit() {
    await lastValueFrom(
      forkJoin({
        workOrderLineItem: this.workOrderListComponent.saveWorkOrderLineItem(),
        recoverableCostSave:
          this.recoverableCostComponent.saveRecoverableCostValue(),
        DamageDetailSave:
          this.damageDetailsComponent.saveDamageDetailsToServer(),
      })
    );
  }

  async createInvoice() {
    await this.invoiceLetterComponent.createInvoice();
  }

  /**
   * Assign this case to the current user if it is not already assigned.
   * @param vendorRecoveryCaseDto CustomerRecoveryCaseDto
   * @returns Promise
   */
  async assignCurrentCaseToTheCurrentUser(
    vendorRecoveryCaseDto: CustomerRecoveryCaseDto
  ) {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    if (
      vendorRecoveryCaseDto.assignedToUID === userId ||
      !vendorRecoveryCaseDto.recoveryCaseNumber ||
      !this.caseDetails
    )
      return;

    const cases = [];
    const groupedCases = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsListByGroupIdPost(
        [vendorRecoveryCaseDto.groupId ?? ''],
        this.apiVersion
      )
    );
    if (groupedCases && !!groupedCases.customerRecoveryDetails) {
      cases.push(
        ...groupedCases.customerRecoveryDetails.map(
          (recoveryCase: CustomerRecoveryCaseDto) =>
            recoveryCase.recoveryCaseNumber
        )
      );
    }
    await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsCaseAssignmentPost(
          {
            assignedToUid: userId,
            recoveryCaseNumbers: cases,
            updatedBy: userId,
            updatedDttm: new Date(),
          },
          this.apiVersion
        )
        .pipe(
          tap((response: CaseAssignmentStatusResponse) => {
            if (
              response &&
              response.status?.statusCode == 200 &&
              response.caseAssignmentStatus?.isUpdateSuccessful &&
              this.caseDetails
            ) {
              this.caseDetails.assignedTo = userId;
            }
          }),
          catchError(() => {
            //returning true as we need to proceed other execution flow.
            return of(true);
          })
        )
    );
  }

  onTabChange(event: any) {
    this.currentTabIndex = event.detail;
    this.tabItems = this.currentStageItem[event.detail].items;
  }

  onBackClick() {
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);
    this.loadTab = false;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == this.currentStageId)[0].sequence ?? 0;
    const prevStage = this.stages?.filter(
      (i) => i.sequence == currentStageSequence - 1
    )[0];

    if (prevStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      if (prevStage.id)
        this._vendorRecoverySharedService.updateCurrentStageId(prevStage.id);
    }
    this.loadTab = true;
    this._loader.hideLoader();
  }

  onLiabilityPartySelected(event: SendMailRequest | undefined) {
    this.liabilityEmailContent = event;
  }

  loadNextStage() {
    const newStage = this.stages?.filter(
      (i: any) => i.id == this.nextStageId
    )[0];
    if (newStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      this._vendorRecoverySharedService.updateCurrentStageId(this.nextStageId);
    }
  }

  bindVendorRecoveryTemplate() {
    const wfTemplate = (template as any).default;
    let locTem = JSON.parse(JSON.stringify(wfTemplate));
    this.stages = locTem.stages;
  }

  async closingCase() {
    // const { closeReason, comments } = this.closeCaseForm.value;
    // const closeRequest = {
    //   userName: this.userId,
    //   cancelationReason: closeReason,
    //   comments: '',
    //   //TODO
    //   // comment:
    //   //   closeReason != this.OTHERS
    //   //     ? this.caseDetails.comments ?? ''
    //   //     : comments ?? '',
    //   claimStatusId: this.closedStatusId,
    //   updatedDttm: new Date(),
    //   workflowStages: this._vendorRecoverySharedService.getWorkFlowStageById(
    //     this.caseDetails.recoveryStatusId ?? 1
    //   ),
    // };
    // this.claimStatusUpdate(
    //   closeRequest,
    //   true,
    //   this.caseDetails.caseStatus === 'New' || false
    // );
  }

  /**
   * Calculates recoverable cost based on selected line items
   * @param recoverableLineItems Work order line items selected for recovery
   */
  calculateFinalRecoverableCostUsd(
    recoverableLineItems: { [key: string]: unknown }[]
  ) {
    this.finalRecoverableCostUsd = 0.0;
    this.finalRecoverableCostLocal = 0.0;
    recoverableLineItems.forEach((row) => {
      this.finalRecoverableCostUsd += (
        row['repairTotalCostUSD'] as GridCellData
      ).value as number;
      this.finalRecoverableCostLocal += (
        row['repairTotalCostLocalCurrency'] as GridCellData
      ).value as number;
    });
    this.finalRecoverableCostUsd = this._sharedDataService.formatDecimal(
      this.finalRecoverableCostUsd
    );
    this.finalRecoverableCostLocal = this._sharedDataService.formatDecimal(
      this.finalRecoverableCostLocal
    );
  }

  onContainerMovesDataFetched(containerMovesDto: ContainerMoveDto) {
    this.containerMovesDtoDetails = containerMovesDto;
  }

  OnMovesRowSelectionChanged(
    event: [
      {
        [key: string]: GridCellData;
      },
    ]
  ) {}
}
