import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

import { WorkflowSteps } from '../../../common/models/workflowSteps';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
  tap,
} from 'rxjs';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
import { ToasterService } from '@maersk-global/angular-shared-library';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';

@Component({
  selector: 'stepper',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StepperComponent implements OnChanges {
  @Input({ required: true }) stages: TemplateModel[] | undefined;
  @Input() hideComment?: boolean | false;

  @ViewChild('commentPopOver', { static: false }) commentPopOver!: ElementRef;

  nextStageId: number = 0;
  workflowSteps?: WorkflowSteps[] = [];
  currentStageSequence: number = 0;
  workflowStageId: number = 1;
  currentStageId: number = 1;
  statusId: number = 1;
  commentForm!: FormGroup;
  recoveryData!: CustomerRecoveryCaseDto | undefined;
  private apiVersion: string = '1.0';
  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _toasterService: ToasterService,
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService
  ) {}

  ngOnChanges(): void {
    this.currentStageSequence =
      this.stages?.filter((o) => o.id == this.currentStageId)[0]?.sequence ?? 0;
    this.loadStepper();
  }

  recoveryData$ = this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
    tap((recoveryData) => {
      if (!recoveryData) return;
      this.commentForm = new FormGroup({
        comments: new FormControl(recoveryData?.comments ?? ''),
      });
    })
  );

  currentStageId$: Observable<number> = combineLatest([
    this._sharedRecoveryCaseService.currentStageId$,
    this._sharedRecoveryCaseService.workflowStageId$,
    this.recoveryData$,
  ]).pipe(
    tap(([currentStageId, workFlowStageID, recoveryData]) => {
      this.recoveryData = recoveryData;
      this.currentStageId = currentStageId;
      this.statusId = recoveryData?.recoveryStatusId ?? 1;
      this.workflowStageId = workFlowStageID;
      this.ngOnChanges();
    }),
    map(([currentStageId]) => currentStageId)
  );

  loadStepper() {
    if (!this.stages || this.stages.length == 0) return;
    const filteredStages = this.stages
      ?.filter((o) => !o.hidden)
      .sort((a, b) => ((a.sequence ?? 0) > (b.sequence ?? 0) ? 1 : -1));
    if (this.currentStageId <= this.workflowStageId) {
      this.workflowSteps = filteredStages?.map((o) => {
        if (o.sequence == undefined) return;
        return this.getWorkFlowStepsProperties(o);
      }) as WorkflowSteps[];
    } else {
      this.workflowSteps = filteredStages?.map((stage) => {
        if (stage.sequence == undefined) return;
        return {
          name: stage.label,
          state: 'pending',
          icon: '',
          className: 'pending',
        } as WorkflowSteps;
      }) as WorkflowSteps[];
    }
  }

  getWorkFlowStepsProperties(stage: TemplateModel) {
    let icon = '';
    let clsName = '';
    let state = 'pending';
    const stageSequence = stage.sequence! + 1;
    const name = stage.label;

    // check state of the stage
    if (stageSequence == this.currentStageId) {
      if (stageSequence < this.workflowStageId) {
        state = 'completed';
      } else if (stageSequence == this.workflowStageId) {
        state = 'current';
      }
    } else {
      if (stageSequence > this.workflowStageId) {
        state = 'pending';
      } else if (stageSequence < this.workflowStageId) {
        state = 'completed';
      }
    }

    if (stageSequence == this.workflowStageId) {
      icon = '';
      clsName = 'stage-current';
      state = 'current';
    }

    if (stageSequence == this.currentStageId) {
      icon = 'check';
      clsName = 'current';
    } else if (
      stageSequence < this.currentStageId &&
      stageSequence < this.workflowStageId
    ) {
      icon = 'check';
      clsName = 'completed';
    } else if (
      stageSequence > this.currentStageId &&
      stageSequence < this.workflowStageId
    ) {
      icon = 'check';
      clsName = 'completed';
    }

    return {
      name: name,
      state: state,
      icon: icon,
      className: clsName,
    } as WorkflowSteps;
  }

  showStage(sequence: number) {
    if (this.currentStageSequence == sequence) return;
    const workflowSequenceID =
      this.stages?.filter((o) => o.id == this.workflowStageId)[0]?.sequence ??
      0;
    if (sequence > workflowSequenceID) return;
    this.currentStageSequence = sequence;
    const stageID =
      this.stages?.filter((o) => o.sequence == sequence)[0]?.id ?? 0;
    const newStage = this.stages?.filter((i: any) => i.id == stageID)[0];
    if (newStage) {
      this._sharedRecoveryCaseService.updateCurrentStageId(stageID);
    }
  }

  async commentSave() {
    if (!this.recoveryData) return;
    this.recoveryData.comments = this.commentForm?.get('comments')?.value;
    this.recoveryData.updatedBy = sessionStorage.getItem('username') ?? '';
    if (this.recoveryData.recoveryStatusId === 1)
      this.recoveryData.workFlowVersion = 1;
    const caseRequest = {
      customerRecoveryCaseDto: this.recoveryData,
    } as CaseDetailsDto;
    const response = await firstValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsUpdatePost(caseRequest, this.apiVersion)
        .pipe(
          map((res) => {
            if (!res) return [];
            return res.data;
          }),
          catchError((error: any) => {
            return of(false);
          })
        )
    );
    if (!response) {
      this._toasterService.showToast({
        message: 'Error while saving comments',
        type: 'error',
      });
      return;
    }

    this._toasterService.showToast({
      message: 'Comments saved successfully',
      type: 'success',
    });
    this.commentPopOver.nativeElement.hide();
    this._sharedRecoveryCaseService.reloadRecoveryCaseData();
  }

  showCommentPopover() {
    this.commentForm.get('comments')?.setValue(this.recoveryData?.comments);
  }
}
